import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {deletePersonalData} from '../actions';
import {updatePersonalDataForm} from '../form/actions';
import {loadPassportData, LoadPassportDataRequest, LoadPassportDataResponse} from './actions';

const initialState = getInitialState<LoadPassportDataRequest, LoadPassportDataResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadPassportData.request, (state, {payload}) => handleRequest(state, payload, true))
  .handleAction(loadPassportData.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPassportData.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction([deletePersonalData.success, updatePersonalDataForm.success], () => initialState);
