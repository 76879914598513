import {reducer as filters} from './filters';
import {reducer as notifications} from './notifications';
import preferences from './preferences';
import {reducer as settings} from './settings';

export default {
  filters,
  notifications,
  preferences,
  settings,
};

export {
  getNotificationsPreferences,
  getNotificationsPreferencesError,
  getNotificationsPreferencesSavingError,
  isNotificationsPreferencesLoaded,
  isNotificationsPreferencesLoading,
  isNotificationsPreferencesSaving,
  loadNotificationsPreferences,
  saveNotificationsPreferences,
} from './preferences';

export {loadNotificationsSettings} from './settings';
