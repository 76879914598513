import {createRequestAction} from 'store/utils/requestActions';
import {CardEditorPreferences} from 'types/CardEditorPreferences';
import {EmptyObject} from 'types/EmptyObject';

export type LoadRequest = EmptyObject;

export type LoadResponse = CardEditorPreferences;

export const load = createRequestAction(
  '@cardEditorPreferences/LOAD_REQUEST',
  '@cardEditorPreferences/LOAD_SUCCESS',
  '@cardEditorPreferences/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();
