import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {
  discardRewardWheelAvailability,
  getRewardWheelAvailabilityAction,
  GetRewardWheelAvailabilityRequest,
  GetRewardWheelAvailabilityResponse,
} from './actions';

const initialState = getInitialState<
  GetRewardWheelAvailabilityRequest,
  GetRewardWheelAvailabilityResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(getRewardWheelAvailabilityAction.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(getRewardWheelAvailabilityAction.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(getRewardWheelAvailabilityAction.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(discardRewardWheelAvailability, () => initialState);
