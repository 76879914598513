import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';
import {ProductCollectionUserSettings} from 'types/ProductCollection/ProductCollectionUserSettings';

export type LoadProductCollectionListRequest = {
  pageToken?: string;
};

export type LoadProductCollectionListResponse = {
  userSettings: ProductCollectionUserSettings;
  items: ContentListItem[];
  nextPageToken?: string;
};

export const loadProductCollectionList = createRequestAction(
  '@collectionList/LOAD_REQUEST',
  '@collectionList/LOAD_RESPONSE',
  '@collectionList/LOAD_FAILURE',
)<LoadProductCollectionListRequest, LoadProductCollectionListResponse>();
