import {createRequestAction} from 'store/utils/requestActions';
import {PartName} from 'types/AddressMetainfo';

export type LoadRequest = {
  key: string;
};

export type LoadResponse = {
  items: {
    value: string;
    diff: {
      [key in PartName]: string;
    };
  }[];
};

export const load = createRequestAction(
  '@addresses/suggest/LOAD_REQUEST',
  '@addresses/suggest/LOAD_SUCCESS',
  '@addresses/suggest/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();
