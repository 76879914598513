import {createRequestAction} from 'store/utils/requestActions';
import {FavoriteTab} from 'types/FavoriteTab';

export type LoadFavoriteTabsResponse = {
  items: FavoriteTab[];
};

export const loadFavoriteTabs = createRequestAction(
  '@favoriteTabs/LOAD_REQUEST',
  '@favoriteTabs/LOAD_SUCCESS',
  '@favoriteTabs/LOAD_FAILURE',
)<undefined, LoadFavoriteTabsResponse>();
