import {createRequestAction} from 'store/utils/requestActions';
import {SocialPost} from 'types/SocialPost';

export type LoadStoreReviewsRequest = {
  filter?: string;
  pageToken?: string;
  storeId: string;
};
export type LoadStoreReviewsResponse = {
  items: SocialPost[];
  nextPageToken?: string;
  prevPageToken?: string;
  pageToken?: string;
};

export const loadStoreReviews = createRequestAction(
  '@storeReviews/LOAD_STORE_REVIEWS_REQUEST',
  '@storeReviews/LOAD_STORE_REVIEWS_SUCCESS',
  '@storeReviews/LOAD_STORE_REVIEWS_FAILURE',
)<LoadStoreReviewsRequest, LoadStoreReviewsResponse>();
