import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadProductCollectionLikedListRequest = {
  pageToken?: string;
};

export type LoadProductCollectionLikedListResponse = {
  items: ContentListItem[];
  nextPageToken?: string;
};

export const loadProductCollectionLikedList = createRequestAction(
  '@collectionLikedList/LOAD_REQUEST',
  '@collectionLikedList/LOAD_RESPONSE',
  '@collectionLikedList/LOAD_FAILURE',
)<LoadProductCollectionLikedListRequest, LoadProductCollectionLikedListResponse>();
