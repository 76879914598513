import {DeliveryMethods as DeliveryMethodsType} from 'types/Product';

import {ContentListBaseItem, contentListBaseItem} from './ContentListBaseItem';

type DeliveryMethods = {
  deliveryMethods: DeliveryMethodsType;
};

export type ContentListDeliveryMethods = ContentListBaseItem<DeliveryMethods>;

export function contentListDeliveryMethods(
  id: string,
  deliveryMethods: DeliveryMethods,
): ContentListDeliveryMethods {
  return contentListBaseItem(id, deliveryMethods);
}

export function isContentListDeliveryMethods(
  item: ContentListBaseItem,
): item is ContentListDeliveryMethods {
  return Boolean((item as ContentListDeliveryMethods).content.deliveryMethods);
}
