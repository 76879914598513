import {createRequestAction} from 'store/utils/requestActions';
import {SocialPostComment} from 'types/SocialPostComment';

export type LoadPostCommentsRequest = {
  postId: string;
  pageToken?: string;
};

export type LoadPostCommentsResponse = {
  items: SocialPostComment[];
  nextPageToken?: string;
  totalCount?: number;
};

export const loadPostComments = createRequestAction(
  '@socialComments/LOAD_REQUEST',
  '@socialComments/LOAD_SUCCESS',
  '@socialComments/LOAD_FAILURE',
)<LoadPostCommentsRequest, LoadPostCommentsResponse>();

export type LoadPostCommentRepliesRequest = {
  postId: string;
  commentId: string;
  pageToken?: string;
};

export type LoadPostCommentRepliesResponse = {
  items: SocialPostComment[];
  prevPageToken?: string;
  nextPageToken?: string;
  totalCount: number;
};

export const loadPostCommentReplies = createRequestAction(
  '@socialCommentReplies/LOAD_REQUEST',
  '@socialCommentReplies/LOAD_SUCCESS',
  '@socialCommentReplies/LOAD_FAILURE',
)<LoadPostCommentRepliesRequest, LoadPostCommentRepliesResponse>();

export type CreatePostCommentRequest = {
  postId: string;
  referencedAuthorId?: string;
  parentCommentId?: string;
  text: string;
  updatePermissions?: boolean;
};

export type CreatePostCommentResponse = SocialPostComment;

export const createPostComment = createRequestAction(
  '@socialCreateComment/LOAD_REQUEST',
  '@socialCreateComment/LOAD_SUCCESS',
  '@socialCreateComment/LOAD_FAILURE',
)<CreatePostCommentRequest, CreatePostCommentResponse>();

export type UpdatePostCommentRequest = {
  commentId: string;
  postId: string;
  referencedAuthorId?: string;
  text: string;
};

export type UpdatePostCommentResponse = SocialPostComment;

export const updatePostComment = createRequestAction(
  '@socialUpdateComment/LOAD_REQUEST',
  '@socialUpdateComment/LOAD_SUCCESS',
  '@socialUpdateComment/LOAD_FAILURE',
)<UpdatePostCommentRequest, UpdatePostCommentResponse>();

export type DeletePostCommentRequest = {
  postId: string;
  commentId: string;
};

export const deletePostComment = createRequestAction(
  '@socialDeleteComment/LOAD_REQUEST',
  '@socialDeleteComment/LOAD_SUCCESS',
  '@socialDeleteComment/LOAD_FAILURE',
)<DeletePostCommentRequest, unknown>();
