import dict, {getPromotion} from './dict';
import list, {getPromotionsListIds} from './list';

export default {
  dict,
  list,
};

export function getPromotionsList(globalState) {
  const ids = getPromotionsListIds(globalState);
  if (ids) {
    return ids.map((id) => getPromotion(globalState, id));
  }
  return null;
}

export {
  getPromotion,
  getPromotionError,
  isPromotionLoaded,
  isPromotionLoading,
  isPromotionActive,
  getPromotionExpirationMs,
  getPromotionStartMs,
  loadPromotion,
} from './dict';

export {
  getPromotionsListError,
  isPromotionsListLoaded,
  isPromotionsListLoading,
  loadPromotionsList,
} from './list';
