import {assignRemove, assignSet} from 'utils/object';
import {enhanceProducts} from 'store/utils/enhancers/enhanceProducts';

export const LOAD = 'relatedProducts/product/LOAD';
export const LOAD_SUCCESS = 'relatedProducts/product/LOAD_SUCCESS';
export const LOAD_FAIL = 'relatedProducts/product/LOAD_FAIL';

const initialState = {
  data: {},
  loading: {},
  error: {},
  loaded: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.productId),
        loading: assignSet(state.loading, action.productId, true),
        loaded: assignRemove(state.loaded, action.productId),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(state.data, action.productId, action.result),
        loading: assignRemove(state.loading, action.productId),
        loaded: assignSet(state.loaded, action.productId, action.result),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.productId, action.error),
        loading: assignRemove(state.loading, action.productId),
      };
    default:
      return state;
  }
}

export default reducer;

export function isRelatedProductsProductLoading(globalState, productId) {
  return !!globalState.relatedProducts.product.loading[productId];
}

export function isRelatedProductsProductLoaded(globalState, productId) {
  const state = globalState.relatedProducts.product;
  return state.loaded[productId];
}

export function getRelatedProductsProductError(globalState, productId) {
  return globalState.relatedProducts.product.error[productId] || null;
}

export function getRelatedProductsProduct(globalState, productId) {
  return isRelatedProductsProductLoaded(globalState, productId)
    ? globalState.relatedProducts.product.data[productId].items
    : null;
}

export function loadRelatedProductsProduct(productId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    productId,
    promise: (client) =>
      client.api
        .get(`/related/purchases/product/${productId}`)
        .then(({language, currency, body: {contexts, payload}}) => ({
          currency,
          items: enhanceProducts(payload.items, {language, currency, contexts}),
          language,
        })),
  };
}
