import {messages} from 'components/Hcaptcha/messages';
import {Preloader} from 'components/Preloader';
import {useCompanyName} from 'hooks/useCompanyName';
import {useEffectOnce} from 'hooks/useEffectOnce';
import {useLanguage} from 'hooks/useLanguage';
import {useSupportEmail} from 'hooks/useSupportEmail';
import React, {ReactElement, useCallback, useMemo, useRef, useState} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';

import styles from './index.scss';

export type HcaptchaExecuteProps = {
  container: HTMLElement | null;
  language: string;
  errorMessage: string;
  onLoadingChange: (isLoading: boolean) => void;
};

export function Hcaptcha({
  requestId,
  execute,
}: {
  requestId: string | undefined;
  execute: (props: HcaptchaExecuteProps) => Promise<unknown>;
}): ReactElement | null {
  const hcaptchaElRef = useRef<HTMLDivElement>(null);
  const language = useLanguage();
  const companyName = useCompanyName();
  const supportEmail = useSupportEmail();

  const intl = useIntl();
  const errorMessage = useMemo(
    () => intl.formatMessage(messages.error, {email: supportEmail}),
    [intl, supportEmail],
  );

  const [isLoading, setIsLoading] = useState(true);
  const [isShownMore, setIsShownMore] = useState(false);
  const handleWhyClick = useCallback(() => setIsShownMore((v) => !v), []);

  useEffectOnce(() => {
    execute({
      container: hcaptchaElRef.current,
      language,
      errorMessage,
      onLoadingChange: setIsLoading,
    });
  });

  return (
    <div className={styles.container}>
      <main className={styles.inner}>
        <div className={styles.captcha}>
          {isLoading && <Preloader />}
          <div ref={hcaptchaElRef} className={isLoading ? styles.hidden : ''} />
        </div>
        <div className={styles.content}>
          <h1 className={styles.title}>
            <FormattedMessage {...messages.title} />
          </h1>
          <p className={styles.text}>
            <FormattedMessage
              {...messages.mainText}
              values={{
                link: (
                  <button type="button" className={styles.link} onClick={handleWhyClick}>
                    <FormattedMessage {...messages.mainTextLink} />
                  </button>
                ),
              }}
            />
          </p>
          {isShownMore && (
            <FormattedMessage
              {...messages.accordionText}
              values={{
                email: (
                  <a className={styles.link} href={`mailto:${supportEmail}`}>
                    {supportEmail}
                  </a>
                ),
                requestId,
                // @ts-expect-error bad typings
                p: (data: string): ReactElement => <p className={styles.text}>{data}</p>,
                companyName,
              }}
            />
          )}
        </div>
      </main>
    </div>
  );
}
