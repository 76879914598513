import {createRequestAction} from 'store/utils/requestActions';
import {ContentListBannersList} from 'types/ContentList/ContentListBannersList';

type LoadRequest = {
  promotionId: string;
};

export type LoadResponse = {
  banner: ContentListBannersList;
  currency: string;
  language: string;
};

export const load = createRequestAction(
  '@promotionBanner/LOAD_REQUEST',
  '@promotionBanner/LOAD_SUCCESS',
  '@promotionBanner/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();
