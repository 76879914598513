import {Button} from 'components/UIKit/Button';
import {PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React from 'react';
import type {ContentListScreenFooter as ContentListScreenFooterType} from 'types/ContentListScreen';
import {convertBackendColorToCSSValue, convertBackendGradientToCSSValue} from 'utils/styles/color';

type Props = {
  footer: ContentListScreenFooterType;
  onClose: PopupViewProps['onClose'];
};

export const ContentListScreenFooter: React.FC<Props> = ({footer, onClose}) => {
  const footerButton = footer.button;
  return (
    <Button
      tag="button"
      color={{
        color: convertBackendColorToCSSValue(footerButton.textColor),
        background: convertBackendGradientToCSSValue(footerButton.backgroundGradient),
      }}
      onClick={!footerButton.url ? onClose : undefined}
      fullWidth
    >
      {footerButton.text}
    </Button>
  );
};
