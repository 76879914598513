import {produce} from 'immer';
import {
  addProductToCollection,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {enhanceProducts} from 'store/utils/enhancers/enhanceProducts';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

import {loadSimilars, LoadSimilarsRequest, LoadSimilarsResponse} from './actions';

const initialState = getInitialState<LoadSimilarsRequest, LoadSimilarsResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadSimilars.request, (state, {payload}) =>
    handleRequest(state, payload, Boolean(payload.options?.pageToken)),
  )
  .handleAction(loadSimilars.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(loadSimilars.success, (state, {meta, payload}) => {
    return handleSuccess(state, meta, {
      ...payload,
      items: [
        ...(state.data?.items || []),
        ...enhanceProducts(payload.items, {
          contexts: payload.contexts,
          currency: payload.currency,
          language: payload.language,
        }),
      ],
    });
  })
  .handleAction(
    [addProductToCollection.success, removeProductFromCollection.success],
    (state, action) =>
      produce(state, (draft) => {
        const product = draft.data?.items.find((item) => item.id === action.meta.itemKey.productId);

        if (product) {
          product.favorite = isActionOf(addProductToCollection.success, action);
        }
      }),
  );
