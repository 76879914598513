import {createRequestAction} from 'store/utils/requestActions';
import {Brand} from 'types/Brand';

export type LoadResponse = Brand;

export type LoadRequest = {
  id: string;
};

export const load = createRequestAction(
  '@brand/LOAD_REQUEST',
  '@brand/LOAD_SUCCESS',
  '@brand/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();
