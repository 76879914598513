import {produce} from 'immer';
import {ContentListBannersList} from 'types/ContentList/ContentListBannersList';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';

import {load} from './actions';

type State = {
  data: {
    [id: string]: {
      banner: ContentListBannersList;
      currency: string;
      language: string;
    };
  };
  error: {[id: string]: Error};
  loading: {[id: string]: boolean};
  loaded: {[id: string]: boolean};
};

const initialState: State = {
  data: {},
  error: {},
  loaded: {},
  loading: {},
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload: {promotionId}}) =>
    produce(state, (draft) => {
      draft.loading[promotionId] = true;
      delete draft.error[promotionId];
    }),
  )
  .handleAction(load.success, (state, {meta: {promotionId}, payload}) =>
    produce(state, (draft) => {
      draft.loading[promotionId] = false;
      draft.data[promotionId] = payload;
    }),
  )
  .handleAction(load.failure, (state, {meta: {promotionId}, payload}) =>
    produce(state, (draft) => {
      draft.loading[promotionId] = false;
      draft.error[promotionId] = payload;
    }),
  );
