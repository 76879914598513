import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {IdlerState} from 'types/Idler';
import {createAction} from 'typesafe-actions';

export const closeIdler = createAction('@idler/CLOSE')();

export const fetchIdlerStateAction = createRequestAction(
  '@idler/FETCH_REQUEST',
  '@idler/FETCH_SUCCESS',
  '@idler/FETCH_FAILURE',
)<EmptyObject, IdlerState>();

export const buyShipperAction = createRequestAction(
  '@idler/BUY_SHIPPER_REQUEST',
  '@idler/BUY_SHIPPER_SUCCESS',
  '@idler/BUY_SHIPPER_FAILURE',
)<EmptyObject, IdlerState>();

export const upgradeShipperAction = createRequestAction(
  '@idler/UPGRADE_SHIPPER_REQUEST',
  '@idler/UPGRADE_SHIPPER_SUCCESS',
  '@idler/UPGRADE_SHIPPER_FAILURE',
)<EmptyObject, IdlerState>();

export const buyPerkAction = createRequestAction(
  '@idler/BUY_PERK_REQUEST',
  '@idler/BUY_PERK_SUCCESS',
  '@idler/BUY_PERK_FAILURE',
)<EmptyObject, IdlerState>();

export const recalculatePackages = createAction('@idler/RECALCULATE')();

export const toggleMute = createAction('@idler/TOGGLE_MUTE')();
