import {DEFAULT_APPEARANCE} from 'helpers/contentList';
import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadFavoriteShopsListRequest = {
  appearance: typeof DEFAULT_APPEARANCE;
  pageToken?: string;
  count?: number;
};

export type LoadFavoriteShopsListResponse = {
  currency: string;
  language: string;
  items: ContentListItem[];
  nextPageToken: string;
};

export const loadFavoriteShopsList = createRequestAction(
  '@favoriteShopsList/LOAD_REQUEST',
  '@favoriteShopsList/LOAD_SUCCESS',
  '@favoriteShopsList/LOAD_FAILURE',
)<{pageToken: string | null; count: number}, LoadFavoriteShopsListResponse>();
