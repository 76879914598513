import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';
import {combineReducers} from 'utils/combineReducers';

import {
  loadPayhubProfiling,
  loadProfiling,
  LoadProfilingRequest,
  LoadProfilingResponse,
} from './actions';

const initialState = getInitialState<LoadProfilingRequest, LoadProfilingResponse>();

const profiling = createReducer(initialState)
  .handleAction(loadProfiling.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadProfiling.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadProfiling.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );

const payhubProfiling = createReducer(initialState)
  .handleAction(loadPayhubProfiling.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadPayhubProfiling.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPayhubProfiling.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );

export const reducer = combineReducers({profiling, payhubProfiling});
