import {combineReducers} from 'utils/combineReducers';

import {reducer as comments} from './comments/reducer';
import {reducer as commentsMetadata} from './commentsMetadata/reducer';
import {reducer as feed} from './feed/reducer';
import {reducer as posts} from './posts/reducer';
import {reducer as products} from './products/reducer';
import {reducer as similarPosts} from './similarPosts/reducer';
import {reducer as userFollowers} from './userFollowers/reducer';
import {reducer as userFollowing} from './userFollowing/reducer';
import {reducer as userPosts} from './userPosts/reducer';
import {reducer as userProductCollections} from './userProductCollections/reducer';
import {reducer as user} from './users/reducer';

export const reducer = combineReducers({
  comments,
  commentsMetadata,
  posts,
  products,
  feed,
  user,
  userPosts,
  userProductCollections,
  similarPosts,
  userFollowers,
  userFollowing,
});
