import {combineReducers} from 'utils/combineReducers';

import {reducer as activationReducer} from './activation/reducer';
import {reducer as availabilityReducer} from './availability/reducer';
import {reducer as wheelReducer} from './wheel/reducer';

export const reducer = combineReducers({
  activation: activationReducer,
  availability: availabilityReducer,
  wheel: wheelReducer,
});
