import {createRequestAction} from 'store/utils/requestActions';
import {SocialPost} from 'types/SocialPost';

export type LoadSimilarPostsRequest = {
  pageToken?: string;
  postId: string;
};

export type LoadSimilarPostsResponse = {
  items: SocialPost[];
  nextPageToken?: string;
};

export const loadSimilarPosts = createRequestAction(
  '@socialSimilarPosts/LOAD_REQUEST',
  '@socialSimilarPosts/LOAD_SUCCESS',
  '@socialSimilarPosts/LOAD_FAILURE',
)<LoadSimilarPostsRequest, LoadSimilarPostsResponse>();
