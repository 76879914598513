import {discardableByUser} from 'helpers/discardable';
import {ADDRESSES_ADD_SUCCESS, ADDRESSES_UPDATE_SUCCESS} from './externalActionTypes';
import {LOAD_SUCCESS as LOAD_SELECTED_SUCCESS, SELECT_SUCCESS} from './SelectedActionType';
import {LOAD_SUCCESS, REMOVE_SUCCESS} from './ListActionType';

const initialState = {
  data: {},
};

function listToDictById(list) {
  if (!list) {
    return {};
  }
  return list.reduce((next, item) => {
    next[item.id] = item;
    return next;
  }, {});
}

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_SELECTED_SUCCESS:
    case SELECT_SUCCESS:
      if (!action.result) {
        return state;
      }
      return {
        ...state,
        data: {
          ...state.data,
          [action.result.id]: action.result,
        },
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...listToDictById(action.result.items),
        },
      };
    case REMOVE_SUCCESS: {
      const data = {...state.data};
      delete data[action.id];
      return {
        ...state,
        data,
      };
    }
    case ADDRESSES_ADD_SUCCESS:
    case ADDRESSES_UPDATE_SUCCESS:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
});

export function getCardById(globalState, id) {
  return globalState.cards.dict.data[id];
}
