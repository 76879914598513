import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {loadSimilarPosts, LoadSimilarPostsRequest, LoadSimilarPostsResponse} from './actions';

const initialState = getInitialState<LoadSimilarPostsRequest, LoadSimilarPostsResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadSimilarPosts.request, (state, {payload, payload: {pageToken}}) =>
    handleRequest(state, payload, Boolean(pageToken)),
  )
  .handleAction(loadSimilarPosts.success, (state, {payload, meta, meta: {pageToken}}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: [...(pageToken ? state.data?.items || [] : []), ...payload.items],
    }),
  )
  .handleAction(loadSimilarPosts.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  );
