import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {load, LoadResponse} from './actions';

const initialState = getInitialState<{pageToken: string | null}, LoadResponse>();

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(load.success, (state, {meta, payload}) => handleSuccess(state, meta, payload))
  .handleAction(load.failure, (state, {meta, payload}) => handleFailure(state, meta, payload));
