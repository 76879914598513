import {getCurrentHub} from '@sentry/core';
import {Transaction} from '@sentry/types';
import {Response} from 'express';

export function getSentryTransaction(res?: Response): Transaction | undefined {
  if (__SERVER__) {
    // eslint-disable-next-line no-underscore-dangle
    return (res as {__sentry_transaction?: Transaction})?.__sentry_transaction;
  }

  return getCurrentHub().getScope()?.getTransaction();
}
