import {reducer as address, resetAddress} from './address';
import {reducer as common, resetCommon} from './common';
import {reducer as form, resetForm} from './form';
import {reducer as info, resetInfo} from './info';
import {reducer as locations, resetLocations} from './locations';

export const reducer = {
  address,
  common,
  form,
  info,
  locations,
};

export function resetDeliveryPoints() {
  return ({dispatch}) => {
    dispatch(resetAddress());
    dispatch(resetCommon());
    dispatch(resetForm());
    dispatch(resetInfo());
    dispatch(resetLocations());
  };
}
