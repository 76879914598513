import {reportCrash} from 'client/reportError';
import {ErrorBoundary} from 'components/ErrorBoundary';
import {DeepLinksManager} from 'containers/DeepLinksManager';
import {ApiClient} from 'helpers/ApiClient';
import {History} from 'history';
import {useForceUpdate} from 'hooks/useForceUpdate';
import {AppHistoryInfoProvider} from 'providers/AppHistoryInfo';
import React, {ReactNode, useCallback, useEffect} from 'react';
import {FilledContext, HelmetProvider} from 'react-helmet-async';
import {Provider as ReduxProvider} from 'react-redux';
import {LocaleData} from 'types/LocaleData';
import {Store} from 'typesafe-actions';

import {A11yContextProvider} from './A11yContext';
import {AccessibleFocusOutline} from './AccessibleFocusOutline';
import {AnalyticsContext} from './AnalyticsContext';
import {ApiClientContext} from './ApiClientContext';
import {AppWebViewContext} from './AppWebViewContext';
import {BotContext} from './BotContext';
import {ContextSeedContext} from './ContextSeedContext';
import {CurrencyContext} from './CurrencyContext';
import {DeviceVarsContext} from './DeviceVarsContext';
import {IntlProvider} from './IntlProvider';
import {LanguageContext} from './LanguageContext';
import {LocaleDataContext} from './LocaleDataContext';
import {PopupProvider} from './PopupProvider';
import {RouterProvider, StaticContext} from './RouterProvider';
import {SnowProvider} from './SnowContext';
import {ToastProvider} from './Toast';
import {UserAdulthoodProvider} from './UserAdulthoodProvider';
import {UserAgentContext} from './UserAgentContext';

type Props = {
  client: ApiClient;
  helmet?: FilledContext;
  context?: StaticContext;
  store: Store;
  children: ReactNode;
  localeData: LocaleData;
  history?: History;
};

export const Providers = ({
  client,
  context,
  helmet,
  store,
  children,
  localeData,
  history,
}: Props): JSX.Element => {
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    client.device.on('configChange', forceUpdate);

    return () => {
      client.device.off('configChange', forceUpdate);
    };
  }, [client.device, forceUpdate]);

  const handleError = useCallback((error: unknown) => reportCrash(client, error), [client]);

  return (
    <HelmetProvider context={helmet}>
      <ReduxProvider store={store} key="provider">
        <ApiClientContext.Provider value={client}>
          <UserAgentContext.Provider value={client.device.getUserAgent().getResult()}>
            <AnalyticsContext.Provider value={client.analytics}>
              <AppWebViewContext.Provider value={client.device.isAppWebView()}>
                <DeviceVarsContext.Provider value={client.device.getDeviceVars()}>
                  <BotContext.Provider value={client.device.isBot()}>
                    <ContextSeedContext.Provider value={client.device.getDeviceHash()}>
                      <LanguageContext.Provider value={client.device.getLanguage()}>
                        <CurrencyContext.Provider value={client.device.getCurrency()}>
                          <IntlProvider messages={localeData.messages}>
                            <ErrorBoundary onError={handleError}>
                              <UserAdulthoodProvider>
                                <LocaleDataContext.Provider value={localeData}>
                                  <RouterProvider
                                    req={client.req || undefined}
                                    context={context}
                                    history={history}
                                  >
                                    <AccessibleFocusOutline>
                                      <A11yContextProvider>
                                        <ToastProvider>
                                          <AppHistoryInfoProvider>
                                            <PopupProvider DeepLinksManager={DeepLinksManager}>
                                              <SnowProvider>{children}</SnowProvider>
                                            </PopupProvider>
                                          </AppHistoryInfoProvider>
                                        </ToastProvider>
                                      </A11yContextProvider>
                                    </AccessibleFocusOutline>
                                  </RouterProvider>
                                </LocaleDataContext.Provider>
                              </UserAdulthoodProvider>
                            </ErrorBoundary>
                          </IntlProvider>
                        </CurrencyContext.Provider>
                      </LanguageContext.Provider>
                    </ContextSeedContext.Provider>
                  </BotContext.Provider>
                </DeviceVarsContext.Provider>
              </AppWebViewContext.Provider>
            </AnalyticsContext.Provider>
          </UserAgentContext.Provider>
        </ApiClientContext.Provider>
      </ReduxProvider>
    </HelmetProvider>
  );
};
