/* eslint-disable react/destructuring-assignment */

import {RouteContext} from 'providers/RouteContext';
import React, {useCallback} from 'react';
import {Route, RouteComponentProps} from 'react-router-dom';
import {Route as RouteType} from 'routes/types';

import {Fetcher} from './Fetcher';

export const AsyncRoute = (route: RouteType): JSX.Element => {
  const render = useCallback(
    (props: RouteComponentProps) => {
      return (
        <RouteContext.Provider value={route}>
          <Fetcher route={route} {...props} />
        </RouteContext.Provider>
      );
    },
    [route],
  );

  const props = {
    strict: route.strict,
    exact: route.exact,
    sensitive: route.sensitive,
    path: route.path,
  };

  return <Route {...props} render={render} />;
};
