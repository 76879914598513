import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {applyOffers, ApplyOffersRequest, ApplyOffersResponse} from './actions';

const initialState = getInitialState<ApplyOffersRequest, ApplyOffersResponse>();

export const reducer = createReducer(initialState)
  .handleAction(applyOffers.request, (state, {payload}) => handleRequest(state, payload, true))
  .handleAction(applyOffers.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, {
      appliedOffers: [...(state.data?.appliedOffers ?? []), ...payload.appliedOffers],
    }),
  )
  .handleAction(applyOffers.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  );
