import {createRequestAction} from 'store/utils/requestActions';

export type LoadPostCommentMetadataRequest = {
  postId: string;
};

export type LoadPostCommentMetadataResponse = {
  commentPermission: string;
  commentPermissionText: string;
  replyPermission: string;
  replyPermissionText: string;
  commentMinLength: number;
  commentMaxLength: number;
  replyMinLength: number;
  replyMaxLength: number;
  emptyListText: string;
};

export const loadPostCommentsMetadata = createRequestAction(
  '@socialCommentMetadata/LOAD_REQUEST',
  '@socialCommentMetadata/LOAD_SUCCESS',
  '@socialCommentMetadata/LOAD_FAILURE',
)<LoadPostCommentMetadataRequest, LoadPostCommentMetadataResponse>();
