import {createRequestAction} from 'store/utils/requestActions';
import {TrendingLinkLight} from 'types/TrendingLink';
import {SearchRequest} from 'utils/search/types';

export type LoadRequest = {
  slug: string;
  searchRequest: SearchRequest;
};

export type LoadResponse = {
  header: {
    description: string;
  };
  items: TrendingLinkLight[];
};

export const loadTrendSimilar = createRequestAction(
  '@trendSimilar/LOAD_REQUEST',
  '@trendSimilar/LOAD_SUCCESS',
  '@trendSimilar/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();
