import {produce} from 'immer';
import {CookiesSettings} from 'types/CookiesSettings';
import {createReducer} from 'typesafe-actions';

import {set, update} from './actions';

const initialState: {
  cookiesSettings: CookiesSettings;
  updateLoading: boolean;
  updateError: boolean;
} = {
  cookiesSettings: [],
  updateLoading: false,
  updateError: false,
};

export const reducer = createReducer(initialState)
  .handleAction(set, (state, {payload}) =>
    produce(state, (draft) => {
      draft.cookiesSettings = payload;
    }),
  )
  .handleAction(update.request, (state) =>
    produce(state, (draft) => {
      draft.updateLoading = true;
      draft.updateError = false;
    }),
  )
  .handleAction(update.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.updateLoading = false;
      draft.updateError = false;
      draft.cookiesSettings = payload;
    }),
  )
  .handleAction(update.failure, (state) =>
    produce(state, (draft) => {
      draft.updateLoading = false;
      draft.updateError = true;
    }),
  );
