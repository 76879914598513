import {createRequestAction} from 'store/utils/requestActions';
import {PaymentProfiling} from 'types/PaymentProfiling';

export type LoadProfilingRequest = {
  checkoutSessionId: string;
};

export type LoadProfilingResponse = PaymentProfiling;

export const loadProfiling = createRequestAction(
  '@payments/LOAD_PROFILING_CONFIG',
  '@payments/LOAD_PROFILING_CONFIG_SUCCESS',
  '@payments/LOAD_PROFILING_CONFIG_FAILURE',
)<LoadProfilingRequest, LoadProfilingResponse>();

export const loadPayhubProfiling = createRequestAction(
  '@payments/LOAD_PAYHUB_PROFILING_CONFIG',
  '@payments/LOADPAYHUB__PROFILING_CONFIG_SUCCESS',
  '@payments/LOADPAYHUB__PROFILING_CONFIG_FAILURE',
)<LoadProfilingRequest, LoadProfilingResponse>();
