import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {Error} from 'types/Error';
import {StuckRefunds} from 'types/StuckRefunds';
import {createAction} from 'typesafe-actions';

export type LoadRequest = EmptyObject;

export type LoadResponse = StuckRefunds;

export const load = createRequestAction(
  '@refundToCard/LOAD_REQUEST',
  '@refundToCard/LOAD_SUCCESS',
  '@refundToCard/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();

export const setError = createAction('@refundToCard/SET_ERROR')<Error | null>();
