import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {
  loadProductBreadcrumbs,
  LoadProductBreadcrumbsRequest,
  LoadProductBreadcrumbsResponse,
} from './actions';

const initialState = getInitialState<
  LoadProductBreadcrumbsRequest,
  LoadProductBreadcrumbsResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadProductBreadcrumbs.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadProductBreadcrumbs.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadProductBreadcrumbs.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );
