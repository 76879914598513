import {createAsyncActionNames, actionNameCreator} from 'store/utils';
import {isArrayNotEmpty} from 'utils/array';

import {guid} from 'utils/guid';
import {getCheckoutGroupId} from './common';

const actionsNs = actionNameCreator('deliveryPoints/locations');
const [LOAD, LOAD_SUCCESS, LOAD_FAIL] = createAsyncActionNames(actionsNs('LOAD'));
const RESET = actionsNs('RESET');

const initialState = {
  center: null,
  items: null,
  itemsIndex: {},
  loading: false,
  loadingError: null,
};

function makeItemsIndex(items) {
  if (isArrayNotEmpty(items)) {
    return items.reduce((itemsIndex, item) => {
      itemsIndex[item.id] = item; // eslint-disable-line no-param-reassign
      return itemsIndex;
    }, {});
  }

  return {};
}

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        center: action.center,
        requestId: action.requestId,
        loading: true,
        loadingError: null,
      };

    case LOAD_SUCCESS: {
      if (action.requestId !== state.requestId) {
        return state;
      }
      const {items} = action.result;
      return {
        ...state,
        items,
        itemsIndex: makeItemsIndex(items),
        loading: false,
      };
    }

    case LOAD_FAIL:
      if (action.requestId !== state.requestId) {
        return state;
      }
      return {
        ...state,
        loading: false,
        loadingError: action.error,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

const getLocationsState = (globalStore) => globalStore.deliveryPoints.locations;

export const getLocations = (globalStore) => getLocationsState(globalStore).items;

export const getLocationById = (id, globalStore) =>
  getLocationsState(globalStore).itemsIndex[id] || null;

export const getLocationByPointId = (id, globalStore) => {
  return Object.values(getLocationsState(globalStore).items).find((location) =>
    location?.points.some((point) => point.id === id),
  );
};

export const getLocationsCenter = (globalStore) => getLocationsState(globalStore).center;

export function loadLocations(center) {
  return {
    center,
    requestId: guid(),
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      const query = {
        ...center,
        checkoutGroupId: getCheckoutGroupId(getState()),
      };

      return client.api
        .get('/deliveryPoints/locations', {query})
        .then(({body: {payload}}) => payload);
    },
  };
}

export function resetLocations() {
  return {
    type: RESET,
  };
}
