import {requestActionCreator} from 'store/utils/requestActions';
import {IdlerState} from 'types/Idler';
import {FuncAction} from 'typesafe-actions';

import {
  buyPerkAction,
  buyShipperAction,
  fetchIdlerStateAction,
  upgradeShipperAction,
} from './actions';

type ResponseBody = {
  payload: IdlerState;
};

const DELAY_MS = 500;

function resolveWithDelay<T>(promise: Promise<T>, delay: number): Promise<T> {
  return new Promise((resolve) => setTimeout(() => resolve(promise), delay));
}

export const fetchIdlerState = (): FuncAction =>
  requestActionCreator(fetchIdlerStateAction, {}, (store, client) =>
    client.api.get<ResponseBody>(`/games/idle`).then(({body: {payload}}) => payload),
  );

export const buyShipper = (shipperId: string): FuncAction =>
  requestActionCreator(buyShipperAction, {}, (store, client) => {
    return resolveWithDelay(
      client.api
        .post<ResponseBody>(`/games/idle/buy/${shipperId}`)
        .then(({body: {payload}}) => payload),
      DELAY_MS,
    );
  });

export const upgradeShipper = (shipperId: string): FuncAction =>
  requestActionCreator(upgradeShipperAction, {}, (store, client) => {
    return resolveWithDelay(
      client.api
        .post<ResponseBody>(`/games/idle/upgrade/${shipperId}`)
        .then(({body: {payload}}) => payload),
      DELAY_MS,
    );
  });

export const buyPerk = (perkId: string): FuncAction =>
  requestActionCreator(buyPerkAction, {}, (store, client) => {
    return resolveWithDelay(
      client.api
        .post<ResponseBody>(`/games/idle/perk/${perkId}`)
        .then(({body: {payload}}) => payload),
      DELAY_MS,
    );
  });

export {getIdlerIsMute, getIdlerIsShown, getIdlerState, getIdlerLoading} from './selectors';
export {closeIdler, recalculatePackages, toggleMute} from './actions';
