import {OfferBannerButton, OfferBannerState} from 'types/Banner';
import {Color} from 'types/Color';
import {LinearGradient} from 'types/Gradient';
import {OfferDescription} from 'types/Offers';

type ProductPresaleButtonFavorites = {
  favorite: boolean;
  textColor?: Color;
  textColorFavorite?: Color;
  backgroundGradient?: LinearGradient;
  backgroundGradientFavorite?: LinearGradient;
};

type ProductPresaleButtonBuy = {
  text: string;
  textInCart: string;
  inCart: boolean;
  textColor?: Color;
  textColorInCart?: Color;
  backgroundGradient?: LinearGradient;
  backgroundGradientInCart?: LinearGradient;
};

type ProductPresaleButtonOffer = {
  button: OfferBannerButton;
  offerDescription: OfferDescription;
  state: OfferBannerState;
};

export enum ButtonType {
  BUY = 'buy',
  FAVORITES = 'favorites',
  APPLY_OFFER = 'applyOffer',
}

export type ProductPresaleButtonWithBuy = {
  buy: ProductPresaleButtonBuy;
};

export type ProductPresaleButtonWithFavorites = {
  favorites: ProductPresaleButtonFavorites;
};

export type ProductPresaleButtonWithOffer = {
  applyOffer: ProductPresaleButtonOffer;
};

export type ProductPresaleButton =
  | ProductPresaleButtonWithBuy
  | ProductPresaleButtonWithFavorites
  | ProductPresaleButtonWithOffer;

export function isBuyButton(button: ProductPresaleButton): button is ProductPresaleButtonWithBuy {
  return (button as ProductPresaleButtonWithBuy).buy !== undefined;
}

export function isFavoritesButton(
  button: ProductPresaleButton,
): button is ProductPresaleButtonWithFavorites {
  return (button as ProductPresaleButtonWithFavorites).favorites !== undefined;
}

export function isOfferButton(
  button: ProductPresaleButton,
): button is ProductPresaleButtonWithOffer {
  return 'applyOffer' in button;
}
