export type FavoriteTab = {
  id: string;
  itemCount: {
    value: number;
    text: string;
  };
  name: string;
  payload: {
    webDeeplink: string;
  };
  type: string;
};

// Checking by id or deeplink is a temporary solution.
const PRODUCTS_TAB_ID = '0-0-14-0-12';
const PRODUCTS_TAB_DEEPLINK = 'joom://products/favorites';

export function isProductsTab(tab: FavoriteTab): boolean {
  return tab.id === PRODUCTS_TAB_ID || tab.payload.webDeeplink === PRODUCTS_TAB_DEEPLINK;
}

const COLLECTIONS_TAB_ID = '0-0-14-0-26';
const COLLECTIONS_TAB_DEEPLINK = 'joom://products/collections';

export function isCollectionsTab(tab: FavoriteTab): boolean {
  return tab.id === COLLECTIONS_TAB_ID || tab.payload.webDeeplink === COLLECTIONS_TAB_DEEPLINK;
}

const STORES_TAB_ID = '0-0-14-0-13';
const STORES_TAB_DEEPLINK = 'joom://stores/favorites';

export function isStoresTab(tab: FavoriteTab): boolean {
  return tab.id === STORES_TAB_ID || tab.payload.webDeeplink === STORES_TAB_DEEPLINK;
}
