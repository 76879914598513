import {handleClientErrorResponse} from 'store/middleware/clientResponse';
import {sendLegalityConsent} from 'store/modules/legality';

export default function clientMiddleware(client) {
  return (store) => (next) => (action) => {
    const {dispatch, getState} = store;

    if (typeof action === 'function') {
      return action(store, client);
    }

    if (typeof action.result === 'function') {
      return next({
        ...action,
        result: action.result(client, dispatch, getState),
      });
    }

    const {promise, consentPromise, types, ...rest} = action;
    if (!promise) {
      return next(action);
    }

    const [REQUEST, SUCCESS, FAILURE] = types;
    next({...rest, type: REQUEST});

    const handleSuccess = (result) => {
      next({...rest, result, type: SUCCESS});
      return result;
    };

    const handleFailure = (error) => {
      const handler = () => {
        next({...rest, error, type: FAILURE});
        throw error;
      };
      const errorPromise = handleClientErrorResponse(dispatch, getState, error, rest);
      return errorPromise instanceof Promise ? errorPromise.then(handler, handler) : handler();
    };

    const actionPromise = promise(client, dispatch, getState).then(handleSuccess, (result) => {
      const {body} = result;

      if (body && body.type === 'legality.need_consent') {
        return client.device.transports.hcaptcha.verifyProtection(() =>
          sendLegalityConsent(body.payload.id, '', true, true)
            .promise(client)
            .then(
              () =>
                (consentPromise || promise)(client, dispatch, getState).then(
                  handleSuccess,
                  handleFailure,
                ),
              handleFailure,
            ),
        );
      }

      return handleFailure(result);
    });

    actionPromise.catch((err) => {
      client.device.log.getLogger('ClientMiddleware').log(err);
    });

    return actionPromise;
  };
}
