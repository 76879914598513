import {START_PAGE_TOKEN} from 'store/consts';
import {enhanceContentListItems} from 'store/enhancer/contentList';
import {showPopup} from 'store/modules/popup/actions';
import {requestActionCreator} from 'store/utils/requestActions';
import {ContextList} from 'types/Context';
import {OpenPayload} from 'types/OpenPayload';
import {PopupResponse} from 'types/Popup';
import {FuncAction} from 'typesafe-actions';

import type {
  LoadStorePageRequest,
  LoadStorePageResponse,
  LoadStorePageResponseRaw,
  UpdateStorePayload,
} from './actions';
import {
  addStoreToFavorites as addStoreToFavoritesActions,
  loadStore as loadStorePageActions,
  removeStoreFromFavorites as removeStoreFromFavoritesActions,
  updateStore as updateStoreAction,
} from './actions';

type LoadStorePageResponseBody = {
  payload: LoadStorePageResponse;
  contexts: ContextList[];
};

type LoadStorePageResponseRawBody = {
  payload: LoadStorePageResponseRaw;
  contexts: ContextList[];
};

type AddStoreToFavoritesResponseBody = {
  payload: {
    store: UpdateStorePayload;
    popup?: PopupResponse;
  };
};

type RemoveStoreFromFavoritesResponseBody = {
  payload: Omit<AddStoreToFavoritesResponseBody['payload'], 'popup'>;
};

export const loadStorePage = (
  storeId: string,
  openPayload: OpenPayload | undefined,
  pageToken: string | undefined,
): FuncAction =>
  requestActionCreator(loadStorePageActions, {pageToken}, (store, client) => {
    const body: LoadStorePageRequest = {openPayload};
    if (pageToken && pageToken !== START_PAGE_TOKEN) {
      body.pageToken = pageToken;
    }

    return client.api
      .post<LoadStorePageResponseBody>(`/stores/${storeId}/page/get`, {body})
      .then(({currency, language, body: {contexts, payload}}) => ({
        ...payload,
        currency,
        language,
        items: enhanceContentListItems(payload.items, language, currency, pageToken, contexts),
      }));
  });

export const addStoreToFavorites = (
  storeId: string,
  payload: {requestDiscount: boolean; place: string} | Record<string, never> = {},
): FuncAction =>
  requestActionCreator(addStoreToFavoritesActions, {storeId}, (store, client) => {
    return client.api
      .post<AddStoreToFavoritesResponseBody>(`/favorites/stores/${storeId}/add`, {
        body: {extraData: payload},
      })
      .then(
        ({
          body: {
            payload: {store: shop, popup},
          },
        }) => {
          store.dispatch(updateStoreAction(shop));
          if (popup) {
            store.dispatch(showPopup(popup));
          }
        },
      );
  });

export const removeStoreFromFavorites = (storeId: string): FuncAction =>
  requestActionCreator(removeStoreFromFavoritesActions, {storeId}, (store, client) => {
    return client.api
      .post<RemoveStoreFromFavoritesResponseBody>(`/favorites/stores/${storeId}/remove`, {body: {}})
      .then(
        ({
          body: {
            payload: {store: shop},
          },
        }) => {
          store.dispatch(updateStoreAction(shop));
        },
      );
  });

export {
  LoadStorePageRequest,
  LoadStorePageResponse,
  LoadStorePageResponseBody,
  LoadStorePageResponseRawBody,
};
