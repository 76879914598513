import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadProductCollectionDiscoveryRequest = {
  pageToken?: string;
  query?: string;
};

export type LoadProductCollectionDiscoveryResponse = {
  header?: unknown;
  items: ContentListItem[];
  nextPageToken?: string;
};

export const loadProductCollectionDiscovery = createRequestAction(
  '@collectionDiscovery/LOAD_REQUEST',
  '@collectionDiscovery/LOAD_RESPONSE',
  '@collectionDiscovery/LOAD_FAILURE',
)<LoadProductCollectionDiscoveryRequest, LoadProductCollectionDiscoveryResponse>();
