import {produce} from 'immer';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';
import {assignRemove, assignSet} from 'utils/object';

import {load} from './actions';

type State = {
  data: Partial<Record<string, boolean>>;
  error: Partial<Record<string, Error>>;
  loaded: Partial<Record<string, boolean>>;
  loading: Partial<Record<string, boolean>>;
};

const initialState: State = {
  data: {},
  error: {},
  loaded: {},
  loading: {},
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload}) =>
    produce(state, (draft) => {
      draft.error = assignRemove(state.error, payload.key);
      draft.loading = assignSet(state.loading, payload.key, true);
    }),
  )
  .handleAction(load.success, (state, {payload, meta}) =>
    produce(state, (draft) => {
      draft.data = assignSet(state.data, meta.key, payload);
      draft.loaded = assignSet(state.loaded, meta.key, true);
      draft.loading = assignRemove(state.loading, meta.key);
    }),
  )
  .handleAction(load.failure, (state, {meta, payload}) =>
    produce(state, (draft) => {
      draft.error = assignSet(state.error, meta.key, payload);
      draft.loaded = assignRemove(state.loaded, meta.key);
      draft.loading = assignRemove(state.loading, meta.key);
    }),
  );
