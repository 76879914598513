import {combineReducers} from 'utils/combineReducers';

import {reducer as byCategory} from './byCategory';
import {reducer as bySlug} from './bySlug';

export const reducer = combineReducers({
  byCategory,
  bySlug,
});

export {
  isTrendingLinksByCategoryLoaded,
  getTrendingLinksByCategory,
  isTrendingLinksByCategoryLoading,
  getTrendingLinksByCategoryError,
  loadTrendingLinksByCategory,
} from './byCategory';

export {
  isTrendingLinkBySlugLoaded,
  getTrendingLinkBySlug,
  isTrendingLinkBySlugLoading,
  getTrendingLinkBySlugError,
  loadTrendingLinkBySlug,
} from './bySlug';
