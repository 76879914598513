import {createRequestAction} from 'store/utils/requestActions';

export const likePost = createRequestAction(
  '@likeSocialPost/LOAD_REQUEST',
  '@likeSocialPost/LOAD_SUCCESS',
  '@likeSocialPost/LOAD_FAILURE',
)<{postId: string}, unknown>();

export const unlikePost = createRequestAction(
  '@unlikeSocialPost/LOAD_REQUEST',
  '@unlikeSocialPost/LOAD_SUCCESS',
  '@unlikeSocialPost/LOAD_FAILURE',
)<{postId: string}, unknown>();
