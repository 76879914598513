import {produce} from 'immer';
import {CategoryPromoLink} from 'types/CategoryPromoLink';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';

import {load} from './actions';

export type State = {
  data?: Array<CategoryPromoLink>;
  language?: string;
  loaded: boolean;
  loading: boolean;
  loadingError?: Error;
};

const initialState: State = {
  loaded: false,
  loading: false,
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state) =>
    produce(state, (draft) => {
      draft.data = undefined;
      draft.language = undefined;
      draft.loaded = false;
      draft.loading = true;
      draft.loadingError = undefined;
    }),
  )
  .handleAction(load.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.data = payload.links;
      draft.language = payload.language;
      draft.loaded = true;
      draft.loading = false;
    }),
  )
  .handleAction(load.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loadingError = payload;
    }),
  );
