import {produce} from 'immer';
import {likePost, unlikePost} from 'store/modules/social/postLikes/actions';
import {followSocialUser, unfollowSocialUser} from 'store/modules/social/subscribe/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

import {clearPost, loadPost, LoadPostRequest, LoadPostResponse} from './actions';

const initialState = getInitialState<LoadPostRequest, LoadPostResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadPost.request, (state, {payload}) => handleRequest(state, payload, true))
  .handleAction(loadPost.success, (state, {payload, meta}) => handleSuccess(state, meta, payload))
  .handleAction(loadPost.failure, (state, {payload, meta}) => handleFailure(state, meta, payload))
  .handleAction(clearPost, () => initialState)
  .handleAction([likePost.request, unlikePost.failure], (state) => {
    return produce(state, (post) => {
      if (post?.data) {
        post.data.likesCount = (post.data.likesCount || 0) + 1;
        post.data.isLiked = true;
      }
    });
  })
  .handleAction([unlikePost.request, likePost.failure], (state) => {
    return produce(state, (post) => {
      if (post?.data) {
        post.data.likesCount = (post.data.likesCount || 1) - 1;
        post.data.isLiked = false;
      }
    });
  })
  .handleAction([followSocialUser.success, unfollowSocialUser.success], (state, action) =>
    produce(state, (draft) => {
      const {userId} = action.meta;
      const isFollowing = isActionOf(followSocialUser.success, action);

      if (draft?.data?.author.id === userId) {
        draft.data.author.isFollowing = isFollowing;
      }
    }),
  );
