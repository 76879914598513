import {discardableByUser} from 'helpers/discardable';
import {getCardById} from './dict';
import {ADDRESSES_ADD_SUCCESS, ADDRESSES_UPDATE_SUCCESS} from './externalActionTypes';
import {LOAD, LOAD_SUCCESS, LOAD_FAIL, REMOVE, REMOVE_SUCCESS, REMOVE_FAIL} from './ListActionType';

const initialState = {
  items: [],
  nextPageToken: null,
  error: null,
  removeError: null,
  loaded: false,
  loading: false,
};

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
    case REMOVE:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result.items.reduce((next, item) => {
          next[item.id] = item;
          return next;
        }, {}),
        items: action.result.items.map((item) => item.id),
        nextPageToken: action.result.nextPageToken,
        error: null,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case REMOVE_SUCCESS:
      return {
        ...state,
        loading: false,
        items: state.items.filter((id) => id !== action.id),
      };
    case REMOVE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case ADDRESSES_ADD_SUCCESS:
    case ADDRESSES_UPDATE_SUCCESS:
      return {
        ...state,
        loaded: false,
      };
    default:
      return state;
  }
});

export function isCardsListLoaded(globalState) {
  return !!globalState.cards.list.loaded;
}

export function isCardsListLoading(globalState) {
  return !!globalState.cards.list.loading;
}

export function getCardsList(globalState) {
  const {items} = globalState.cards.list;
  if (isCardsListLoaded(globalState) && items) {
    return items.map((id) => getCardById(globalState, id));
  }
  return null;
}

export function getCardsListError(globalState) {
  return globalState.cards.list.error;
}

export function loadCardsList() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.api.get('/cards').then(({body: {payload}}) => payload),
  };
}

export function removeCard(id) {
  return {
    types: [REMOVE, REMOVE_SUCCESS, REMOVE_FAIL],
    id,
    promise: (client) => client.api.delete(`/cards/${id}`).then(({body: {payload}}) => payload),
  };
}
