import config from 'config';
import {useCompanyName} from 'hooks/useCompanyName';
import {useLocale} from 'hooks/useLocale';
import React, {ReactElement} from 'react';
import {Helmet} from 'react-helmet-async';
import {rootLocator} from 'utils/rootLocator';

type Props = {
  children: ReactElement;
};

export function App({children}: Props): JSX.Element {
  const locale = useLocale();
  const companyName = useCompanyName();
  const meta = [{property: 'og:locale', content: locale}];
  if (config.releaseStage !== 'prod' && __CLIENT__) {
    meta.push({property: 'joom-app-loaded', content: 'yes'});
  }

  return (
    <div className={locale.toLowerCase()} {...rootLocator()}>
      <Helmet titleTemplate={`%s — ${companyName}`} meta={meta} htmlAttributes={{lang: locale}} />
      {children}
    </div>
  );
}
