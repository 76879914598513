import {PageError} from 'components/PageError';
import React, {Component, ErrorInfo, ReactNode} from 'react';
import type {Error} from 'types/Error';
import {isApiError, isRuntimeError} from 'types/Error';

type Props = Readonly<{children?: ReactNode; onError?: (error?: unknown) => void}>;
type State = Readonly<{error?: Error}>;

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.onError = props.onError;
  }

  static getDerivedStateFromError(error: Error): State {
    if (isApiError(error) || isRuntimeError(error)) {
      return {error};
    }

    return {error: new Error('Unknown boundary error')};
  }

  override componentDidCatch(error: Error, {componentStack}: ErrorInfo): void {
    if (error) {
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = componentStack || undefined;

      // Using sentry `LinkedErrors` integration to link the errors together to save stack trace.
      (error as Error & {cause: Error}).cause = errorBoundaryError;

      this.onError?.(error);
    }
  }

  onError: Props['onError'];

  override render(): ReactNode {
    const {children} = this.props;
    const {error} = this.state || {};

    if (error) {
      return <PageError error={error} />;
    }

    return children;
  }
}
