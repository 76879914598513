import {AnyAction} from 'redux';
import {
  addProductToCollection,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {isClientProduct} from 'types/ProductNext';
import {isActionOf, Reducer} from 'typesafe-actions';
import {assignSetByPath, getValueByPath, ValueByPath} from 'utils/object';

function getFavoriteDependedProduct(item: unknown, path: string[] | undefined): unknown {
  return path ? getValueByPath(item, ...path) : item;
}

function isFavoriteDependedProduct(item: unknown, path: string[] | undefined, id: string): boolean {
  const product = getFavoriteDependedProduct(item, path) as {id: string} | undefined;
  return !!product && product.id === id;
}

function patchFavoriteDependedState<State extends Record<string, unknown> | undefined>(
  state: State,
  path: string[],
  subPath: string[] | undefined,
  productId: string,
  params: Record<string, unknown>,
): State {
  const queryResult = getValueByPath(state, ...path) as unknown;
  if (Array.isArray(queryResult)) {
    if (queryResult.find((item) => isFavoriteDependedProduct(item, subPath, productId))) {
      return assignSetByPath(
        state,
        path,
        queryResult.map((item) => {
          const product = getFavoriteDependedProduct(item, subPath) as {id: string} | undefined;
          if (!product || product.id !== productId) {
            return item;
          }
          const newProduct = {...product, ...params};
          return subPath
            ? assignSetByPath(item, subPath, newProduct as ValueByPath<State, string[]>)
            : newProduct;
        }) as ValueByPath<State, string[]>,
      );
    }
  } else if (queryResult && Object(queryResult)[productId]) {
    const newPath = [...path, productId];
    return assignSetByPath(state, newPath, {...Object(queryResult)[productId], ...params});
  } else if (queryResult && isClientProduct(queryResult)) {
    const newPath = [...path, 'header'];
    const newHeader = {...queryResult.header, ...params};
    return assignSetByPath(state, newPath, newHeader as ValueByPath<State, string[]>);
  }
  return state;
}

export const favoriteProductsDepended =
  <State extends Record<string, unknown>>(
    reducer: Reducer<State, AnyAction>,
    path: string[],
    subPath?: string[],
  ): Reducer<State, AnyAction> =>
  (state, action) => {
    if (
      isActionOf(addProductToCollection.success, action) ||
      isActionOf(removeProductFromCollection.success, action)
    ) {
      const {productId, isFavorite, flow} = action.payload.collectionsUpdatedProductInfo;
      return reducer(
        patchFavoriteDependedState(state, path, subPath, productId, {
          favorite: isFavorite,
          productCollectionFlow: flow,
        }),
        action,
      );
    }
    return reducer(state, action);
  };
