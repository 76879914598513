import {createRequestAction} from 'store/utils/requestActions';

type PremiumSeoPage = {
  id: string;
  title: string;
};

type PremiumSeoList = {
  title: string;
  items: PremiumSeoPage[];
};

export type LoadPremiumSeoRequest = Record<string, never>;

export type LoadPremiumSeoListResponse = {
  items: PremiumSeoList[];
};

export const loadPremiumSeoList = createRequestAction(
  '@premiumSeoList/LOAD_REQUEST',
  '@premiumSeoList/LOAD_SUCCESS',
  '@premiumSeoList/LOAD_FAILURE',
)<LoadPremiumSeoRequest, LoadPremiumSeoListResponse>();
