import {reportCrash} from 'client/reportError';
import ErrorMessage from 'components/ErrorMessage';
import {GlobalLoader} from 'components/GlobalLoader';
import {Hcaptcha, HcaptchaExecuteProps} from 'components/Hcaptcha';
import {PropsHolder} from 'components/PropsHolder';
import {ApiClient} from 'helpers/ApiClient';
import {hcaptchaExecute} from 'helpers/ApiClient/hcaptcha';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {progressDone, progressStart} from 'helpers/nprogress';
import {getSentryTransaction} from 'helpers/sentry/getSentryTransaction';
import {setPageSentryTag} from 'helpers/sentry/setTags';
import {useStore} from 'hooks/redux';
import {useApiClient} from 'hooks/useApiClient';
import {useLogger} from 'hooks/useLogger';
import {useMountedRef} from 'hooks/useMounted';
import React, {ReactElement, useCallback, useContext, useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router-dom';
import {InitialDataContext} from 'routes/InitialDataContext';
import {DefaultParams, PoorRoute, Route as RouteType, RouteParams} from 'routes/types';
import {Error} from 'types/Error';
import {HcaptchaRequiredError} from 'types/HcaptchaError';
import {Store} from 'typesafe-actions';
import {isStoreWithTransactions} from 'utils/redux';

type Props = RouteComponentProps & {
  route: RouteType;
};

type AsyncState = {
  props: Record<string, unknown> | undefined;
  routeProps: Props;
  error: Error | undefined;
  transition: boolean;
};

function sendPreformanceScreenEvent<T extends DefaultParams>(
  client: ApiClient,
  route: PoorRoute<string, T>,
  startTime: number,
) {
  const now = Date.now();
  const duration = now > startTime ? now - startTime : 1;

  client.performance.traceRecord({
    name: 'performanceScreen',
    startTime,
    duration,
    options: {
      attributes: {
        viewType: route.name,
      },
    },
  });

  client.analytics.sendEvent({
    type: 'performanceScreen',
    payload: {
      cache: 'miss',
      viewType: route.name,
      sinceNavigateMs: duration,
    },
  });
}

export function Fetcher(props: Props): JSX.Element | null {
  const initialData = useContext(InitialDataContext);
  const client = useApiClient();
  const store = useStore();
  const mountedRef = useMountedRef(true);
  const logger = useLogger('routes/AsyncRoute/Fetcher');
  const {history, location, route, match} = props;
  const [initialLocation] = useState(location);
  const [asyncState, setAsyncState] = useState<AsyncState>({
    props: initialData?.props,
    routeProps: props,
    error: undefined,
    transition: false,
  });

  const fetch = useCallback(
    (waitForAllPromises?: boolean): Promise<unknown> => {
      if (route.async) {
        progressStart();
        if (!asyncState.transition) {
          setAsyncState((state) => ({
            ...state,
            transition: true,
          }));
        }
        const startTime = Date.now();
        const async = (originalStore: Store) =>
          route.async!({
            client,
            store: originalStore,
            match: match as RouteComponentProps<RouteParams<typeof route>>['match'],
            route,
            history,
            location,
            initial: false,
            executeDelayed: true,
          })
            .then(({allPromise, redirect, ...data}) => {
              if (redirect) {
                history.replace(redirect.link, redirect.historyState);
                return undefined;
              }

              if (mountedRef.current && history.location === location) {
                setAsyncState({
                  props: data || {},
                  routeProps: props,
                  error: undefined,
                  transition: false,
                });

                if (allPromise) {
                  allPromise.finally(() => {
                    progressDone();
                  });
                } else {
                  progressDone();
                }

                sendPreformanceScreenEvent(client, route, startTime);

                if (waitForAllPromises) {
                  return allPromise;
                }
              }
              return undefined;
            })
            .catch((error) => {
              progressDone();

              if (mountedRef.current && history.location === location) {
                reportCrash(client, error);
                logger.error({error: ecsError(error)});
                setAsyncState({
                  props: {},
                  routeProps: props,
                  error,
                  transition: false,
                });
              }
            });

        return isStoreWithTransactions(store) ? store.transaction(async) : async(store);
      }

      setAsyncState({
        props: {},
        routeProps: props,
        error: undefined,
        transition: false,
      });
      return Promise.resolve();
    },
    [
      route,
      props,
      asyncState.transition,
      store,
      client,
      match,
      history,
      location,
      mountedRef,
      logger,
    ],
  );

  useEffect(() => {
    if (__CLIENT__ && initialLocation !== location) {
      fetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.key]);

  useEffect(() => {
    getSentryTransaction()?.setName(route?.name);
    setPageSentryTag(route);
  }, [route]);

  {
    const {hcaptcha} = client.device.transports;

    const [hcaptchaRequiredError, setHcaptchaRequiredError] = useState(
      hcaptcha.hcaptchaRequiredError,
    );

    useEffect(() => {
      function handleHcaptchaRequiredError(error: HcaptchaRequiredError) {
        setHcaptchaRequiredError(error);
      }
      hcaptcha.events.on('hcaptchaRequiredError', handleHcaptchaRequiredError);

      return function cleanUp() {
        hcaptcha.events.off('hcaptchaRequiredError', handleHcaptchaRequiredError);
      };
    }, [hcaptcha.events]);

    const {siteKey = '', scriptUrl = '', requestId} = hcaptchaRequiredError || {};
    const execute = useCallback(
      (props: HcaptchaExecuteProps) =>
        hcaptchaExecute({
          ...props,
          verify: hcaptcha.verify,
          retry: hcaptcha.hcaptchaRetryRequest,
          siteKey,
          scriptUrl,
        }),
      [hcaptcha.verify, hcaptcha.hcaptchaRetryRequest, siteKey, scriptUrl],
    );

    if (hcaptchaRequiredError) {
      return <Hcaptcha requestId={requestId} execute={execute} />;
    }
  }

  if (!asyncState.transition && asyncState.error) {
    return <ErrorMessage error={asyncState.error} internal />;
  }

  if (!asyncState.props) {
    return <GlobalLoader />;
  }

  return (
    <PropsHolder
      skipRenderWhileHolding
      hold={asyncState.transition}
      {...asyncState.routeProps}
      {...asyncState.props}
      reload={fetch}
    >
      {(mergedProps): ReactElement =>
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        mergedProps.route.render({...mergedProps, transition: asyncState.transition} as any)
      }
    </PropsHolder>
  );
}
