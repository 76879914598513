import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';

import {type LegalityResponse, LegalityDocResponse} from './index';

type Response = LegalityResponse;

const loadTosDocumentsAction = createRequestAction(
  '@legalityDocumentsTos/LOAD',
  '@legalityDocumentsTos/LOAD_SUCCESS',
  '@legalityDocumentsTos/LOAD_FAILURE',
)<EmptyObject, Response>();

const loadPrivacyDocumentsAction = createRequestAction(
  '@legalityDocumentsPrivacy/LOAD',
  '@legalityDocumentsPrivacy/LOAD_SUCCESS',
  '@legalityDocumentsPrivacy/LOAD_FAILURE',
)<EmptyObject, Response>();

const loadLegalityDocumentAction = createRequestAction(
  '@legalityDocuments/LOAD',
  '@legalityDocuments/LOAD_SUCCESS',
  '@legalityDocuments/LOAD_FAILURE',
)<{id: string}, LegalityDocResponse>();

export {loadTosDocumentsAction, loadPrivacyDocumentsAction, loadLegalityDocumentAction};
