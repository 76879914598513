import {combineReducers} from 'utils/combineReducers';
import {enableMapSet, setAutoFreeze} from 'immer';
import auth from './auth';
import {best} from './best';
import cards from './cards';
import categoryViews from './categoryViews';
import closeMemo from './closeMemo';
import couponViews from './couponViews';
import currencies from './currencies';
import {reducer as deliveryPoints} from './deliveryPoints';
import devices from './devices';
import email from './email';
import {reducer as favoriteCategoryPromo} from './favoriteCategoryPromo';
import games from './games';
import invites from './invites';
import legality from './legality';
import {reducer as miniCart} from './miniCart';
import notifications from './notifications';
import paymentProviders from './paymentProviders';
import points from './points';
import {reducer as popup} from './popup/reducer';
import preferences from './preferences';
import productGroups from './productGroups';
import promotions from './promotions';
import relatedProducts from './relatedProducts';
import search from './search';
import tracking from './tracking';
import userPreferences from './userPreferences';
import variantPrices from './variantPrices';
import adult from './adult';
import parcels from './parcels';
import parcel from './parcel';
import parcelTracking from './parcelTracking';
import {parcelWarrantyTexts} from './parcelWarrantyTexts';
import {map} from '../rootReducer';

setAutoFreeze(__DEVELOPMENT__);
enableMapSet();

export default combineReducers({
  adult,
  auth,
  best,
  cards: combineReducers(cards),
  categoryViews,
  closeMemo,
  couponViews,
  currencies,
  deliveryPoints: combineReducers(deliveryPoints),
  devices,
  email: combineReducers(email),
  favoriteCategoryPromo,
  games: combineReducers(games),
  invites,
  legality,
  miniCart,
  notifications: combineReducers(notifications),
  parcel,
  parcelTracking,
  parcelWarrantyTexts,
  parcels,
  paymentProviders,
  points: combineReducers(points),
  popup,
  preferences,
  productGroups: combineReducers(productGroups),
  promotions: combineReducers(promotions),
  relatedProducts: combineReducers(relatedProducts),
  search: combineReducers(search),
  tracking,
  userPreferences,
  variantPrices,
  ...map,
});
