import {produce} from 'immer';
import {AddressMetainfo} from 'types/AddressMetainfo';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';
import {assignRemove, assignSet} from 'utils/object';

import {countryLoad, load} from './actions';

type State = {
  countryToId: Partial<Record<string, string>>;
  data: Partial<Record<string, AddressMetainfo>>;
  error: Partial<Record<string, Error>>;
  loaded: Partial<Record<string, boolean>>;
  loading: Partial<Record<string, boolean>>;
};

const initialState: State = {
  countryToId: {},
  data: {},
  error: {},
  loaded: {},
  loading: {},
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, action) =>
    produce(state, (draft) => {
      Object.assign(draft, {
        error: assignRemove(state.error, action.payload.metainfoId),
        loaded: assignRemove(state.loaded, action.payload.metainfoId),
        loading: assignSet(state.loading, action.payload.metainfoId, true),
      });
    }),
  )
  .handleAction(load.success, (state, action) =>
    produce(state, (draft) => {
      Object.assign(draft, {
        data: assignSet(state.data, action.payload.id, action.payload),
        loaded: assignSet(state.loaded, action.payload.id, true),
        loading: assignRemove(state.loading, action.payload.id),
      });
    }),
  )
  .handleAction(load.failure, (state, action) =>
    produce(state, (draft) => {
      Object.assign(draft, {
        error: assignSet(state.error, action.meta.metainfoId, action),
        loaded: assignRemove(state.loaded, action.meta.metainfoId),
        loading: assignRemove(state.loading, action.meta.metainfoId),
      });
    }),
  )
  .handleAction(countryLoad.request, (state, action) =>
    produce(state, (draft) => {
      Object.assign(draft, {
        error: assignRemove(state.error, action.payload.tempId),
        loaded: assignRemove(state.loaded, action.payload.tempId),
        loading: assignSet(state.loading, action.payload.tempId, true),
      });
    }),
  )
  .handleAction(countryLoad.success, (state, action) =>
    produce(state, (draft) => {
      Object.assign(draft, {
        countryToId: assignSet(state.countryToId, action.meta.country, action.payload.id),
        data: assignSet(state.data, action.payload.id, action.payload),
        loaded: assignSet(state.loaded, action.payload.id, true),
        loading: assignRemove(state.loading, action.meta.tempId),
      });
    }),
  )
  .handleAction(countryLoad.failure, (state, action) =>
    produce(state, (draft) => {
      Object.assign(draft, {
        error: assignSet(state.error, action.meta.tempId, action),
        loaded: assignRemove(state.loaded, action.meta.tempId),
        loading: assignRemove(state.loading, action.meta.tempId),
      });
    }),
  );
