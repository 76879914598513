import {createRequestAction} from 'store/utils/requestActions';
import {SocialUser} from 'types/SocialUser';
import {createAction} from 'typesafe-actions';

export type LoadSocialUserFollowingRequest = {
  userId: string;
  pageToken?: string;
};

export type LoadSocialUserFollowingResponse = {
  items: SocialUser[];
  nextPageToken?: string;
};

export const loadSocialUserFollowing = createRequestAction(
  '@socialUserFollowing/LOAD_REQUEST',
  '@socialUserFollowing/LOAD_SUCCESS',
  '@socialUserFollowing/LOAD_FAILURE',
)<LoadSocialUserFollowingRequest, LoadSocialUserFollowingResponse>();

export const clearSocialUserFollowing = createAction(
  '@socialUserFollowing/CLEAR',
)<LoadSocialUserFollowingRequest>();
