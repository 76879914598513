import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {deletePersonalData} from '../actions';
import {
  loadPersonalDataExists,
  LoadPersonalDataExistsRequest,
  LoadPersonalDataExistsResponse,
} from './actions';

const initialState = getInitialState<
  LoadPersonalDataExistsRequest,
  LoadPersonalDataExistsResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadPersonalDataExists.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(loadPersonalDataExists.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPersonalDataExists.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(deletePersonalData.success, () => initialState);
