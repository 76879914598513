import {createRequestAction} from 'store/utils/requestActions';
import {SocialPost} from 'types/SocialPost';
import {createAction} from 'typesafe-actions';

export type LoadSocialFeedRequest = {
  filterId: string | undefined;
  pageToken: string | undefined;
};

export type LoadSocialFeedResponse = {
  items?: SocialPost[];
  categories?: {id: string; name: string}[];
  nextPageToken?: string;
};

export const loadSocialFeed = createRequestAction(
  '@socialFeed/LOAD_REQUEST',
  '@socialFeed/LOAD_SUCCESS',
  '@socialFeed/LOAD_FAILURE',
)<LoadSocialFeedRequest, LoadSocialFeedResponse>();

export const clearSocialFeed = createAction('@socialFeed/CLEAR')<never>();
