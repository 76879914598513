import {produce} from 'immer';
import {
  addCollectionToFavorites,
  addProductToCollection,
  createProductCollection,
  deleteProductCollection,
  editProductsInCollections,
  removeCollectionFromFavorites,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {addStoreToFavorites, removeStoreFromFavorites} from 'store/modules/store/actions';
import {Error} from 'types/Error';
import {FavoriteTab, isCollectionsTab, isProductsTab, isStoresTab} from 'types/FavoriteTab';
import {createReducer, isActionOf} from 'typesafe-actions';

import {loadFavoriteTabs} from './actions';
import {assignFavoriteTabCounterOffset} from './utils';

type State = {
  error: Error | null;
  loading: boolean;
  tabs: FavoriteTab[];
  loaded: boolean;
};

const initialState: State = {
  error: null,
  loaded: false,
  loading: false,
  tabs: [],
};

export const reducer = createReducer(initialState)
  .handleAction(loadFavoriteTabs.request, (state) => ({
    ...state,
    error: null,
    loaded: state.loaded || false,
    loading: true,
  }))
  .handleAction(loadFavoriteTabs.success, (state, {payload}) => ({
    ...state,
    loaded: true,
    loading: false,
    tabs: payload.items,
  }))
  .handleAction(loadFavoriteTabs.failure, (state, {payload}) => ({
    ...state,
    error: payload,
    loading: false,
  }))
  .handleAction(
    [addProductToCollection.success, removeProductFromCollection.success],
    (state, action) =>
      produce(state, (draft) => {
        draft.tabs.forEach((tab) => {
          if (isProductsTab(tab)) {
            const offset = isActionOf(addProductToCollection.success, action) ? 1 : -1;

            assignFavoriteTabCounterOffset(tab, offset);
          }
        });
      }),
  )
  .handleAction(editProductsInCollections.success, (state, {meta}) =>
    produce(state, (draft) => {
      const processed = new Set();

      meta.updates.forEach(({isRemoving, itemKey}) => {
        if (!processed.has(itemKey.productId)) {
          processed.add(itemKey.productId);
          draft.tabs.forEach((tab) => {
            if (isProductsTab(tab)) {
              const offset = isRemoving ? -1 : 1;

              assignFavoriteTabCounterOffset(tab, offset);
            }
          });
        }
      });
    }),
  )
  .handleAction(
    [createProductCollection.success, deleteProductCollection.success],
    (state, action) =>
      produce(state, (draft) => {
        draft.tabs.forEach((tab) => {
          if (isCollectionsTab(tab)) {
            const offset = isActionOf(createProductCollection.success, action) ? 1 : -1;

            assignFavoriteTabCounterOffset(tab, offset);
          }
        });
      }),
  )
  .handleAction(
    [addCollectionToFavorites.success, removeCollectionFromFavorites.success],
    (state, action) =>
      produce(state, (draft) => {
        draft.tabs.forEach((tab) => {
          if (isCollectionsTab(tab)) {
            const offset = isActionOf(addCollectionToFavorites.success, action) ? 1 : -1;

            assignFavoriteTabCounterOffset(tab, offset);
          }
        });
      }),
  )
  .handleAction([addStoreToFavorites.success, removeStoreFromFavorites.success], (state, action) =>
    produce(state, (draft) => {
      draft.tabs.forEach((tab) => {
        if (isStoresTab(tab)) {
          const offset = isActionOf(addStoreToFavorites.success, action) ? 1 : -1;

          assignFavoriteTabCounterOffset(tab, offset);
        }
      });
    }),
  );
