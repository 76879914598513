import {produce} from 'immer';
import {createReducer} from 'typesafe-actions';

import {clear} from './actions';

const initialState: {
  loading: boolean;
  error: boolean;
} = {
  loading: false,
  error: false,
};

export const reducer = createReducer(initialState)
  .handleAction(clear.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.error = false;
    }),
  )
  .handleAction(clear.success, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  )
  .handleAction(clear.failure, (state) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = true;
    }),
  );
