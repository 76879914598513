import debounce from 'utils/debounce';
import {isPassiveSupported} from 'utils/dom';

let lastInnerHeight = 0;

export function updateViewportHeight(): void {
  const {innerHeight} = window;
  if (lastInnerHeight !== innerHeight) {
    lastInnerHeight = innerHeight;
    const vh = innerHeight * 0.01;
    // we also set --vh by inline script for proper ssr
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }
}

export function trackViewportHeight(delay = 50): void {
  try {
    updateViewportHeight();
    window.addEventListener(
      'resize',
      debounce(updateViewportHeight, delay),
      isPassiveSupported() ? {passive: true} : false,
    );
  } catch (ex) {
    // do nothing
  }
}
