import {produce} from 'immer';
import {likePost, unlikePost} from 'store/modules/social/postLikes/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

import {
  clearSocialUserPosts,
  loadSocialUserPosts,
  LoadSocialUserPostsRequest,
  LoadSocialUserPostsResponse,
} from './actions';

const initialState = getInitialState<LoadSocialUserPostsRequest, LoadSocialUserPostsResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadSocialUserPosts.request, (state, {payload}) =>
    handleRequest(state, payload, Boolean(payload.pageToken)),
  )
  .handleAction(loadSocialUserPosts.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: [...(state.data?.items || []), ...payload.items],
    }),
  )
  .handleAction(loadSocialUserPosts.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(clearSocialUserPosts, () => initialState)
  .handleAction([likePost.request, unlikePost.failure], (state, action) =>
    produce(state, (draft) => {
      const {postId} = isActionOf(likePost.request, action) ? action.payload : action.meta;

      draft?.data?.items?.forEach((item) => {
        if (item.id === postId) {
          item.likesCount = (item.likesCount || 0) + 1;
          item.isLiked = true;
        }
      });
    }),
  )
  .handleAction([unlikePost.request, likePost.failure], (state, action) =>
    produce(state, (draft) => {
      const {postId} = isActionOf(unlikePost.request, action) ? action.payload : action.meta;

      draft?.data?.items?.forEach((item) => {
        if (item.id === postId) {
          item.likesCount = (item.likesCount || 1) - 1;
          item.isLiked = false;
        }
      });
    }),
  );
