import {discardableByUser} from 'helpers/discardable';
import {getCardById} from './dict';
import {ADDRESSES_ADD_SUCCESS, ADDRESSES_UPDATE_SUCCESS} from './externalActionTypes';
import {
  LOAD,
  LOAD_SUCCESS,
  LOAD_FAIL,
  SELECT,
  SELECT_SUCCESS,
  SELECT_FAIL,
} from './SelectedActionType';

const initialState = {
  id: null,
  error: null,
  loaded: false,
  loading: false,
};

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
    case SELECT:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOAD_SUCCESS:
    case SELECT_SUCCESS:
      return {
        ...state,
        id: action.result ? action.result.id : null,
        error: null,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
    case SELECT_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    case ADDRESSES_ADD_SUCCESS:
    case ADDRESSES_UPDATE_SUCCESS:
      return {
        ...state,
        loaded: false,
      };
    default:
      return state;
  }
});

export function isSelectedCardLoaded(globalState) {
  return !!globalState.cards.selected.loaded;
}

export function isSelectedCardLoading(globalState) {
  return !!globalState.cards.selected.loading;
}

export function getSelectedCardId(globalState) {
  return isSelectedCardLoaded(globalState) ? globalState.cards.selected.id : null;
}

export function getSelectedCard(globalState) {
  const selectedCardId = getSelectedCardId(globalState);
  return selectedCardId ? getCardById(globalState, selectedCardId) : null;
}

export function getSelectedCardError(globalState) {
  return globalState.cards.selected.error;
}

export function loadSelectedCard() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api.get('/cards/selected').then(({language, body: {payload}}) => payload),
  };
}

export function selectCard(card) {
  return {
    types: [SELECT, SELECT_SUCCESS, SELECT_FAIL],
    card,
    promise: (client) =>
      client.api.post(`/cards/${card.id}/select`).then(({language, body: {payload}}) => card),
  };
}
