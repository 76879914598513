import {createRequestAction} from 'store/utils/requestActions';
import {AddressMetainfo} from 'types/AddressMetainfo';

export type LoadRequest = {metainfoId: string};
export type CountryLoadRequest = {
  country: string;
  origin?: {create: {}} | {edit: {}};
  tempId: string;
};

export type LoadResponse = AddressMetainfo;

export const load = createRequestAction(
  '@addresses/metainfo/LOAD_REQUEST',
  '@addresses/metainfo/LOAD_SUCCESS',
  '@addresses/metainfo/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();

export const countryLoad = createRequestAction(
  '@addresses/metainfo/COUNTRY_LOAD_REQUEST',
  '@addresses/metainfo/COUNTRY_LOAD_SUCCESS',
  '@addresses/metainfo/COUNTRY_LOAD_FAILURE',
)<CountryLoadRequest, LoadResponse>();
