import {
  CategoryRedirectRequest,
  CategoryRedirectResponse,
  loadCategoryRedirect,
} from 'store/modules/categoryRedirect/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

const initialState = getInitialState<CategoryRedirectRequest, CategoryRedirectResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadCategoryRedirect.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadCategoryRedirect.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadCategoryRedirect.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  );
