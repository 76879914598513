import {RootState} from 'store/rootReducer';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {Error} from 'types/Error';
import {createReducer, FuncAction} from 'typesafe-actions';

export const LOAD_TOKEN = 'orderGroups/LOAD_TOKEN';
export const LOAD_TOKEN_SUCCESS = 'orderGroups/LOAD_TOKEN_SUCCESS';
export const LOAD_TOKEN_FAIL = 'orderGroups/LOAD_TOKEN_FAIL';

type TokenResponse = {
  token: string;
};

const loadAction = createRequestAction(LOAD_TOKEN, LOAD_TOKEN_SUCCESS, LOAD_TOKEN_FAIL)<
  {id: string},
  TokenResponse
>();

export const actions = {loadAction};

type State = {
  error: Error | null;
  loaded: boolean;
  loading: boolean;
  orderGroupId: string | null;
  token: string | null;
};

const initialState: State = {
  error: null,
  loaded: false,
  loading: false,
  orderGroupId: null,
  token: null,
};

export const reducer = createReducer<State>(initialState)
  .handleAction(loadAction.request, (_, {payload}) => {
    return {
      ...initialState,
      orderGroupId: payload.id,
    };
  })
  .handleAction(loadAction.success, (state, {payload, meta}) => {
    return {
      ...state,
      loading: false,
      loaded: true,
      orderGroupId: meta.id,
      token: payload.token,
    };
  })
  .handleAction(loadAction.failure, (state, {payload}) => {
    return {
      ...state,
      error: payload,
    };
  });

export function loadOrderGroupToken(id: string): FuncAction<Promise<TokenResponse>> {
  return requestActionCreator(loadAction, {id}, (_, client) => {
    return client.api
      .get<ClientBackendResponse<TokenResponse>>(`/orderGroups/${id}/token`)
      .then(({body: {payload}}) => payload);
  });
}

function getField<K extends keyof State>(
  globalState: RootState,
  id: string,
  field: K,
  defaultValue?: State[K] | null,
): State[K] {
  const {token} = globalState.orderGroups;
  return (token.orderGroupId === id && token[field]) || (defaultValue as State[K]);
}

export const isOrderGroupTokenLoading = (globalState: RootState, id: string): boolean =>
  getField(globalState, id, 'loading', false);
export const isOrderGroupTokenLoaded = (globalState: RootState, id: string): boolean =>
  getField(globalState, id, 'loaded', false);
export const getOrderGroupToken = (globalState: RootState, id: string): string =>
  getField(globalState, id, 'token', '') || '';
export const getOrderGroupTokenError = (globalState: RootState, id: string): Error | null =>
  getField(globalState, id, 'error');
