import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {EmptyObject} from 'types/EmptyObject';
import {createReducer} from 'typesafe-actions';
import {combineReducers} from 'utils/combineReducers';

import {
  loadLegalityDocumentAction,
  loadPrivacyDocumentsAction,
  loadTosDocumentsAction,
} from './actions';
import {type LegalityResponse, LegalityDocument} from './index';

const initialState = {
  privacy: getInitialState<EmptyObject, LegalityResponse>(),
  tos: getInitialState<EmptyObject, LegalityResponse>(),
  docs: getInitialState<{id: string}, LegalityDocument>(),
};

const privacyReducer = createReducer(initialState.privacy)
  .handleAction(loadPrivacyDocumentsAction.request, (state, {payload}) =>
    handleRequest(state, payload),
  )
  .handleAction(loadPrivacyDocumentsAction.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPrivacyDocumentsAction.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  );
const tosReducer = createReducer(initialState.tos)
  .handleAction(loadTosDocumentsAction.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadTosDocumentsAction.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadTosDocumentsAction.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  );

const docReducer = createReducer(initialState.docs)
  .handleAction(loadLegalityDocumentAction.request, (state, {payload}) =>
    handleRequest(state, payload),
  )
  .handleAction(loadLegalityDocumentAction.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadLegalityDocumentAction.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  );

export const reducer = combineReducers({
  privacy: privacyReducer,
  tos: tosReducer,
  docs: docReducer,
});
