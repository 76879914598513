import {clearPost} from 'store/modules/social/posts/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {
  LoadPostCommentMetadataRequest,
  LoadPostCommentMetadataResponse,
  loadPostCommentsMetadata,
} from './actions';

const initialState = getInitialState<
  LoadPostCommentMetadataRequest,
  LoadPostCommentMetadataResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadPostCommentsMetadata.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(loadPostCommentsMetadata.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPostCommentsMetadata.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(clearPost, () => initialState);
