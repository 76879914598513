import {createRequestAction} from 'store/utils/requestActions';
import {SocialUser} from 'types/SocialUser';
import {createAction} from 'typesafe-actions';

export type LoadSocialUserRequest = {
  userId: string;
};

export type LoadSocialUserResponse = SocialUser;

export const loadSocialUser = createRequestAction(
  '@socialUser/LOAD_REQUEST',
  '@socialUser/LOAD_SUCCESS',
  '@socialUser/LOAD_FAILURE',
)<LoadSocialUserRequest, LoadSocialUserResponse>();

export const clearSocialUser = createAction('@socialUser/CLEAR')<LoadSocialUserRequest>();

export type UploadSocialUserAvatarRequest = {
  file: File;
};

export type UploadSocialUserAvatarResponse = SocialUser;

export const uploadSocialUserAvatar = createRequestAction(
  '@uploadSocialUserAvatar/LOAD_REQUEST',
  '@uploadSocialUserAvatar/LOAD_SUCCESS',
  '@uploadSocialUserAvatar/LOAD_FAILURE',
)<UploadSocialUserAvatarRequest, UploadSocialUserAvatarResponse>();

export type SaveSocialUserProfileRequest = {
  description?: string;
  isProfileHidden?: boolean;
};

export type SaveSocialUserProfileResponse = SocialUser;

export const saveSocialUserProfile = createRequestAction(
  '@saveSocialUserProfile/LOAD_REQUEST',
  '@saveSocialUserProfile/LOAD_SUCCESS',
  '@saveSocialUserProfile/LOAD_FAILURE',
)<SaveSocialUserProfileRequest, SaveSocialUserProfileResponse>();
