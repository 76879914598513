import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {RewardWheel} from 'types/RewardWheel';

export type ActivateRewardWheelRequest = EmptyObject;
export type ActivateRewardWheelResponse = {
  wheel: RewardWheel;
};

export const activateRewardWheelAction = createRequestAction(
  '@rewardWheel/ACTIVATE_REQUEST',
  '@rewardWheel/ACTIVATE_SUCCESS',
  '@rewardWheel/ACTIVATE_FAILURE',
)<ActivateRewardWheelRequest, ActivateRewardWheelResponse>();
