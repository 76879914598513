import {App} from 'containers/App';
import {ApiClient} from 'helpers/ApiClient';
import React, {createElement, ReactElement, useMemo} from 'react';
import {Switch} from 'react-router-dom';
import {nextTick} from 'utils/nextTick';

import {AsyncRoute} from './AsyncRoute';

type Props = {
  client: ApiClient;
};

// compile `create` in next tick
// eslint-disable-next-line global-require
nextTick(() => require('.'));

function createSwitch(client: ApiClient): ReactElement {
  // eslint-disable-next-line @typescript-eslint/no-var-requires,global-require
  const {create} = require('.') as typeof import('.');

  // We want to omit keys for our routes to skip mount/unmount cycles.
  // In this case we can process transitions between views.
  return createElement(
    Switch,
    null,
    // eslint-disable-next-line react/jsx-key
    ...create(client).map((route) => <AsyncRoute {...route} />),
  );
}

export function Routes({client}: Props): JSX.Element {
  const children = useMemo(() => createSwitch(client), [client]);
  return <App>{children}</App>;
}
