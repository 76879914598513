import {assignRemove, assignSet} from 'utils/object';
import {enhanceLanguage} from 'store/utils/enhancers';

export const LOAD = 'productGroups/dict/LOAD';
export const LOAD_SUCCESS = 'productGroups/dict/LOAD_SUCCESS';
export const LOAD_FAIL = 'productGroups/dict/LOAD_FAIL';

const initialState = {
  data: {},
  loaded: {},
  loading: {},
  error: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.id),
        loaded: assignRemove(state.loaded, action.id),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS: {
      return {
        ...state,
        data: assignSet(state.data, action.id, action.result),
        loaded: assignSet(state.loaded, action.id, true),
        loading: assignRemove(state.loading, action.id),
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
      };
    default:
      return state;
  }
}

export default reducer;

export function isProductGroupLoaded(globalState, id) {
  const {dict} = globalState.productGroups;
  return dict.loaded[id];
}

export function getProductGroup(globalState, id) {
  const {dict} = globalState.productGroups;
  return isProductGroupLoaded(globalState, id) ? dict.data[id] : null;
}

export function getProductGroupError(globalState, id) {
  return globalState.productGroups.dict.error[id];
}

export function isProductGroupLoading(globalState, id) {
  return !!globalState.productGroups.dict.loading[id];
}

export function loadProductGroup(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: (client) =>
      client.api.get(`/productGroups/${id}`).then(({language, body: {payload}}) => ({
        language,
        payload: enhanceLanguage(payload, language),
      })),
  };
}
