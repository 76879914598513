import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {createReducer} from 'typesafe-actions';

import {loadGooglePayData, LoadGooglePayDataRequest, LoadGooglePayDataResponse} from './actions';

const initialState = getInitialState<LoadGooglePayDataRequest, LoadGooglePayDataResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadGooglePayData.request, (state, {payload, payload: {orderGroupId}}) =>
    handleRequest(state, orderGroupId, payload),
  )
  .handleAction(loadGooglePayData.success, (state, {payload, meta, meta: {orderGroupId}}) =>
    handleSuccess(state, orderGroupId, meta, payload),
  )
  .handleAction(loadGooglePayData.failure, (state, {payload, meta, meta: {orderGroupId}}) =>
    handleFailure(state, orderGroupId, meta, payload),
  );
