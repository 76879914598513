import {produce} from 'immer';
import {PopupState} from 'types/Popup';
import {createReducer} from 'typesafe-actions';

import {getPopupById, getPopupForEvent, loadShareData, popupMarkAsSeen, showPopup} from './actions';

const initialState: PopupState = {
  loaded: false,
  loading: false,
  shareLoading: false,
};

export const reducer = createReducer(initialState)
  .handleAction([getPopupById.request, getPopupForEvent.request], (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.loaded = false;
      delete draft.popupData;
      delete draft.error;
    }),
  )
  .handleAction([getPopupById.success, getPopupForEvent.success, showPopup], (state, {payload}) => {
    return produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      draft.popupData = payload;
    });
  })
  .handleAction([getPopupById.failure, getPopupForEvent.failure], (state, error) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = error;
    }),
  )
  .handleAction(loadShareData.request, (state) =>
    produce(state, (draft) => {
      draft.shareLoading = true;
    }),
  )
  .handleAction(loadShareData.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.shareData = payload;
      draft.shareLoading = false;
    }),
  )
  .handleAction(loadShareData.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.shareLoading = false;
      draft.shareError = payload;
    }),
  )
  .handleAction(popupMarkAsSeen.request, () => initialState);
