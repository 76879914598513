import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {getSelectors} from 'store/utils/reducerCreators/simpleMapped/selectors';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {createSelectorFactory} from 'store/utils/selectors';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {UrlResponse} from 'types/UrlResponse';
import {createReducer, FuncAction} from 'typesafe-actions';
import {Utms} from 'utils/url/tracking';

type LoadShareProductRequest = {
  productId: string;
  variantId?: string;
  utms?: Utms;
};

type LoadShareProductResponse = UrlResponse;

export const loadShareProductAction = createRequestAction(
  'share/products/LOAD',
  'share/products/LOAD_SUCCESS',
  'share/products/LOAD_FAIL',
)<LoadShareProductRequest, LoadShareProductResponse>();

const initialState = getInitialState<LoadShareProductRequest, LoadShareProductResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadShareProductAction.request, (state, {payload}) =>
    handleRequest(state, payload.productId, payload, true),
  )
  .handleAction(loadShareProductAction.success, (state, {meta, payload}) =>
    handleSuccess(state, meta.productId, meta, payload),
  )
  .handleAction(loadShareProductAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta.productId, meta, payload),
  );

export const {
  getData: getShareProduct,
  isLoading: isShareProductLoading,
  isLoaded: isShareProductLoaded,
  getRequest: getShareProductRequest,
  getError: getShareProductError,
} = getSelectors(createSelectorFactory((state) => state.share.products));

type LoadShareProductOptions = {
  productId: string;
  redirectToApp?: boolean;
  utms?: Utms;
  variantId?: string;
};

export function loadShareProduct({
  productId,
  redirectToApp,
  utms,
  variantId,
}: LoadShareProductOptions): FuncAction {
  return requestActionCreator(
    loadShareProductAction,
    {productId, redirectToApp, utms, variantId},
    (store, client) =>
      client.api
        .post<ClientBackendResponse<LoadShareProductResponse>>(`/products/${productId}/share`, {
          body: {redirectToApp, utms, variantId},
        })
        .then(({body: {payload}}) => payload),
  );
}
