import {ContentListPopupBase} from 'components/ContentListPopup';
import {PopupView, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React from 'react';
import {ContentListItem, ContentListItemType} from 'types/ContentList';
import {ContentListRowList} from 'types/ContentList/ContentListRowList';
import {ContentListScreen} from 'types/ContentListScreen';

import {ContentListScreenFooter} from './Footer';
import {RowList} from './RowList';

type Props = {
  screen: ContentListScreen;
  popupViewProps: PopupViewProps;
  width: string;
};

const renderItemByType = (type: ContentListItemType, item: ContentListItem): JSX.Element | null => {
  switch (type) {
    case ContentListItemType.ROW_LIST:
      return <RowList rowList={(item as ContentListRowList).content} />;
    default:
      return null;
  }
};

export const ContentListScreenPopup: React.FC<Props> = ({screen, popupViewProps, width}) => {
  const {onClose} = popupViewProps;
  const footer = screen.footer ? (
    <ContentListScreenFooter footer={screen.footer} onClose={onClose} />
  ) : null;

  return (
    <ContentListPopupBase
      contentList={screen.items}
      footer={footer}
      width={width}
      containerMargins="24px 0"
      renderItemByType={renderItemByType}
      {...popupViewProps}
    />
  );
};

export function createContentListScreenPopupView({screen}: Props): PopupView {
  return (popupViewProps: PopupViewProps) => (
    <ContentListScreenPopup screen={screen} popupViewProps={popupViewProps} width="560px" />
  );
}
