import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {Quiz, QuizAnswer} from 'types/QuarantineQuiz';
import {QuizMetainfo} from 'types/QuarantineQuiz/Metainfo';

type QuizLoadRequest = {
  quizType: string;
};

type QuizStartRequest = EmptyObject;

type QuizSaveRequest = {
  result: QuizAnswer;
};

type QuizLoadMetainfoRequest = {
  quizType: string;
  category?: string;
};

export type QuizResponse = {
  quiz: Quiz;
  currency: string;
  language: string;
};

export type QuizLoadMetainfoResponse = {
  metainfo: QuizMetainfo;
};

export const load = createRequestAction(
  '@quarantineQuiz/LOAD_REQUEST',
  '@quarantineQuiz/LOAD_SUCCESS',
  '@quarantineQuiz/LOAD_FAILURE',
)<QuizLoadRequest, QuizResponse>();

export const start = createRequestAction(
  '@quarantineQuiz/START_REQUEST',
  '@quarantineQuiz/START_SUCCESS',
  '@quarantineQuiz/START_FAILURE',
)<QuizStartRequest, QuizResponse>();

export const save = createRequestAction(
  '@quarantineQuiz/SAVE_REQUEST',
  '@quarantineQuiz/SAVE_SUCCESS',
  '@quarantineQuiz/SAVE_FAILURE',
)<QuizSaveRequest, QuizResponse>();

export const loadMetainfo = createRequestAction(
  '@quarantineQuiz/LOAD_METAINFO_REQUEST',
  '@quarantineQuiz/LOAD_METAINFO_SUCCESS',
  '@quarantineQuiz/LOAD_METAINFO_FAILURE',
)<QuizLoadMetainfoRequest, QuizLoadMetainfoResponse>();
