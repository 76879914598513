import PropTypes from 'prop-types';
import {objectValues} from 'utils/object';
import ImageBundle from './ImageBundle';
import PriceRange from './PriceRange';

export const GuessPriceState = {
  ACTIVE: 'active',
  WON: 'won',
  LOST: 'lost',
};

export const GuessPriceStateList = objectValues(GuessPriceState);

export default PropTypes.shape({
  id: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      image: ImageBundle,
      actualPrice: PropTypes.number,
      sliderPrices: PriceRange.isRequired,
      state: PropTypes.oneOf(GuessPriceStateList).isRequired,
    }),
  ),
  loadedTimeMs: PropTypes.number.isRequired,
  reward: PropTypes.shape({
    type: PropTypes.string.isRequired,
    payload: PropTypes.object,
  }),
  state: PropTypes.oneOf(GuessPriceStateList).isRequired,
  timeRemainingMs: PropTypes.number.isRequired,
});
