import {assignRemove, assignSet} from 'utils/object';
import {enhanceProducts} from 'store/utils/enhancers/enhanceProducts';

export const LOAD = 'relatedProducts/order/LOAD';
export const LOAD_SUCCESS = 'relatedProducts/order/LOAD_SUCCESS';
export const LOAD_FAIL = 'relatedProducts/order/LOAD_FAIL';

const initialState = {
  data: {},
  loading: {},
  error: {},
  loaded: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.orderId),
        loading: assignSet(state.loading, action.orderId, true),
        loaded: assignRemove(state.loaded, action.orderId),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(state.data, action.orderId, action.result),
        loading: assignRemove(state.loading, action.orderId),
        loaded: assignSet(state.loaded, action.orderId, action.result),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.orderId, action.error),
        loading: assignRemove(state.loading, action.orderId),
      };
    default:
      return state;
  }
}

export default reducer;

export function isRelatedProductsOrderLoading(globalState, orderId) {
  return !!globalState.relatedProducts.order.loading[orderId];
}

export function isRelatedProductsOrderLoaded(globalState, orderId) {
  const state = globalState.relatedProducts.order;
  return state.loaded[orderId];
}

export function getRelatedProductsOrderError(globalState, orderId) {
  return globalState.relatedProducts.order.error[orderId] || null;
}

export function getRelatedProductsOrder(globalState, orderId) {
  return isRelatedProductsOrderLoaded(globalState, orderId)
    ? globalState.relatedProducts.order.data[orderId].items
    : null;
}

export function loadRelatedProductsOrder(orderId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    orderId,
    promise: (client) =>
      client.api
        .get(`/related/purchases/order/${orderId}`)
        .then(({language, currency, body: {contexts, payload}}) => ({
          currency,
          items: enhanceProducts(payload.items, {language, currency, contexts}),
          language,
        })),
  };
}
