import {createRequestAction} from 'store/utils/requestActions';
import {RewardWheel, RewardWheelSource} from 'types/RewardWheel';

export type getRewardWheelRequest = {
  source?: RewardWheelSource;
};
export type GetRewardWheelResponse = RewardWheel;

export const getRewardWheelAction = createRequestAction(
  '@rewardWheel/GET_REQUEST',
  '@rewardWheel/GET_SUCCESS',
  '@rewardWheel/GET_FAILURE',
)<getRewardWheelRequest, GetRewardWheelResponse>();
