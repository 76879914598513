import {SearchContentFiltersAndSortings} from 'store/modules/search/types';
import {isBoolean, isRecord, isString} from 'utils/guards';

import {OfferBanner} from './Banner';
import {ColoredText} from './ColoredText';
import {ContentListItem} from './ContentList';
import {LinearGradient} from './Gradient';
import {ImageBundle} from './Image';
import {ShareParams} from './Popup';
import {
  isProductCollectionOwner,
  ProductCollectionOwner,
} from './ProductCollection/ProductCollectionOwner';
import {SocialUser} from './SocialUser';

export enum PromotionType {
  PRODUCT_COLLECTION_TYPE = 'productCollection',
  PRODUCT_GROUP_TYPE = 'productGroup',
  SEARCH_TYPE = 'search',
}

export type ProductCollectionPayload = {
  isLiked: boolean;
  owner: ProductCollectionOwner;
  productCollectionId: string;
};

export type PromotionBackground = {
  iconOnImage: {
    image: ImageBundle;
    icon?: ImageBundle;
  };
};

export type Promotion = {
  author?: SocialUser;
  description?: string;
  expirationType: string;
  fontBackgroundColor?: string;
  id: string;
  isAutoPromotion?: boolean;
  loadedTimeMs?: number;
  payload?:
    | {
        // promotion start [sale]
        remainingTimeMs?: number;
      }
    | ProductCollectionPayload;
  shareParams?: ShareParams;
  subtitle?: string;
  // promotion end
  timeRemainingMs?: number;
  title?: string;
  titleImage?: ImageBundle;
  type: PromotionType;
  background?: PromotionBackground;
  offerBanner?: OfferBanner;
  image?: ImageBundle;
};

export type ContentListPromotionDefaultWebHeaderBackground = {
  defaultWeb: {
    backgroundGradient: LinearGradient;
    backgroundImage: ImageBundle;
    title?: ColoredText;
    subtitle?: ColoredText;
    icon?: ImageBundle;
    offerBanner?: OfferBanner;
  };
};

export type ContentListPromotionCollectionWebHeaderBackground = {
  collectionWeb: {
    shareParams: ShareParams;
    backgroundImage: ImageBundle;
    author: SocialUser;
    title: ColoredText;
    subtitle?: ColoredText;
    description?: ColoredText;
    isLiked?: boolean;
  };
};

export type PromotionHeaderShareButton = {
  shareButton: {
    shareParams: ShareParams;
  };
};

export type PromotionHeaderLikeProductCollectionButton = {
  likeProductCollectionButton: {
    isLiked: boolean;
    ownerId: string;
    collectionId: string;
  };
};

export type PromotionHeaderButton =
  | PromotionHeaderShareButton
  | PromotionHeaderLikeProductCollectionButton;

export type ContentListPromotionHeader = {
  id: string;
  background:
    | ContentListPromotionDefaultWebHeaderBackground
    | ContentListPromotionCollectionWebHeaderBackground;
  bottomItems?: ContentListItem[];
  filters?: SearchContentFiltersAndSortings;
  buttons?: Partial<
    Record<'headerBottom' | 'headerTopCollapsed' | 'headerTopExpanded', PromotionHeaderButton[]>
  >;
  forAdults?: boolean;
};

export function isProductCollectionPayload(value: unknown): value is ProductCollectionPayload {
  return (
    isRecord(value) &&
    isBoolean(value.isLiked) &&
    isProductCollectionOwner(value.owner) &&
    isString(value.productCollectionId)
  );
}
