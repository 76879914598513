import {createRequestAction} from 'store/utils/requestActions';
import {ReferralProgramInfo} from 'types/ReferralProgram';

export type LoadReferralProgramResponse = ReferralProgramInfo;

export const loadReferralProgram = createRequestAction(
  '@referral/LOAD_REQUEST',
  '@referral/LOAD_SUCCESS',
  '@referral/LOAD_FAILURE',
)<{}, LoadReferralProgramResponse>();
