import {createRequestAction} from 'store/utils/requestActions';
import {ContentList} from 'types/ContentList';

export type LoadCountRequest = {
  categoryId: string;
};

export type LoadCountResponse = {
  count: number;
  currency: string;
  language: string;
};

export const loadCount = createRequestAction(
  '@categoryPagination/LOAD_COUNT_REQUEST',
  '@categoryPagination/LOAD_COUNT_SUCCESS',
  '@categoryPagination/LOAD_COUNT_FAILURE',
)<LoadCountRequest, LoadCountResponse>();

export type LoadPageRequest = {
  categoryId: string;
  page: number;
};

export type LoadPageResponse = {
  currency: string;
  language: string;
  list: ContentList;
};

export const loadPage = createRequestAction(
  '@categoryPagination/LOAD_PAGE_REQUEST',
  '@categoryPagination/LOAD_PAGE_SUCCESS',
  '@categoryPagination/LOAD_PAGE_FAILURE',
)<LoadPageRequest, LoadPageResponse>();
