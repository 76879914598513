import {discardableByUser} from 'helpers/discardable';

export const LOAD = 'paymentProviders/LOAD';
export const LOAD_SUCCESS = 'paymentProviders/LOAD_SUCCESS';
export const LOAD_FAIL = 'paymentProviders/LOAD_FAIL';

const BANK_CARD_REPLACEMENT_ID = 'solidgate';

const initialState = {
  error: null,
  hasBankCardReplacement: false,
  items: [],
  meta: {},
  loaded: false,
  loading: false,
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loaded: false,
        loading: true,
      };
    case LOAD_SUCCESS: {
      const items = action.result.items || [];
      return {
        ...state,
        hasBankCardReplacement: items.some((item) => item.id === BANK_CARD_REPLACEMENT_ID),
        items,
        loading: false,
        loaded: true,
        meta: {
          language: action.result.language,
        },
      };
    }
    case LOAD_FAIL:
      if (action.version !== state.version) {
        return state;
      }
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isPaymentProvidersLoaded(globalState) {
  return globalState.paymentProviders.loaded;
}

export function hasBankCardReplacement(globalState) {
  return (
    isPaymentProvidersLoaded(globalState) && globalState.paymentProviders.hasBankCardReplacement
  );
}

export function isPaymentProvidersLoading(globalState) {
  return globalState.paymentProviders.loading;
}

export function getPaymentProviders(globalState) {
  return isPaymentProvidersLoaded(globalState) ? globalState.paymentProviders.items : null;
}

export function getPaymentProvidersError(globalState) {
  return globalState.paymentProviders.error || null;
}

export function loadPaymentProviders() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api.get('/paymentProviders').then(({language, body: {payload}}) => ({
        ...payload,
        language,
      })),
  };
}
