import {
  loadPremiumSeoList,
  LoadPremiumSeoListResponse,
  LoadPremiumSeoRequest,
} from 'store/modules/premiumSeoList/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

const initialState = getInitialState<LoadPremiumSeoRequest, LoadPremiumSeoListResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadPremiumSeoList.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadPremiumSeoList.success, (state, d) => handleSuccess(state, d.meta, d.payload))
  .handleAction(loadPremiumSeoList.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );
