import {ecsError} from 'helpers/log/ECS/ecsError';
import {useLogger} from 'hooks/useLogger';
import React, {ReactNode, useCallback, useContext, useMemo, useState} from 'react';
import {
  createIntl,
  createIntlCache,
  CustomFormats,
  Formatters,
  IntlShape,
  RawIntlProvider,
} from 'react-intl';
import {LocaleData} from 'types/LocaleData';
import currencies from 'utils/currencies';

import {ApiClientContext} from './ApiClientContext';

export const formats: CustomFormats = {
  number: {
    pointsRate: {
      style: 'decimal',
      maximumFractionDigits: 1,
    },
  },
};

currencies.forEach((currency) => {
  // that should not happen, but it gives a TypeError otherwise :(
  if (!formats.number) {
    formats.number = {};
  }

  formats.number[currency] = {
    style: 'currency',
    currency,
  };
});

// formatjs has no ability to set defaults
function patchIntlNumbersWithLatn(intl: IntlShape): void {
  const patch = <Obj extends IntlShape | Formatters, Key extends keyof Obj>(
    obj: Obj,
    key: Key,
    creator: (fn: Obj[Key]) => Obj[Key],
  ): void => {
    obj[key] = creator(obj[key]);
  };
  const addLatn = <Opts extends {numberingSystem?: string} | undefined>(opts: Opts) => {
    return {numberingSystem: 'latn', ...opts};
  };
  const addGregoryAndLatn = <
    Opts extends {calendar?: string; numberingSystem?: string} | undefined,
  >(
    opts: Opts,
  ) => {
    return {numberingSystem: 'latn', calendar: 'gregory', ...opts};
  };

  // numbers
  patch(
    intl,
    'formatNumber',
    (fn) => (value, opts) =>
      // for AR locale Intl.NumberFormat uses special percent symbol, that can't be configured
      opts?.style === 'percent'
        ? fn(value, addLatn(opts)).replace('٪', '%')
        : fn(value, addLatn(opts)),
  );

  patch(intl, 'formatNumberToParts', (fn) => (value, opts) => fn(value, addLatn(opts)));
  patch(intl.formatters, 'getNumberFormat', (fn) => (value, opts) => fn(value, addLatn(opts)));
  // date & time
  patch(intl, 'formatTime', (fn) => (value, opts) => fn(value, addLatn(opts)));
  patch(intl, 'formatTimeToParts', (fn) => (value, opts) => fn(value, addLatn(opts)));
  patch(intl, 'formatDate', (fn) => (value, opts) => fn(value, addGregoryAndLatn(opts)));
  patch(intl, 'formatDateToParts', (fn) => (value, opts) => fn(value, addGregoryAndLatn(opts)));
  patch(
    intl,
    'formatDateTimeRange',
    (fn) => (from, to, opts) => fn(from, to, addGregoryAndLatn(opts)),
  );
  patch(
    intl.formatters,
    'getDateTimeFormat',
    (fn) => (value, opts) => fn(value, addGregoryAndLatn(opts)),
  );
}

type Props = {
  children: ReactNode;
  messages: LocaleData['messages'];
};

export function IntlProvider({children, messages}: Props): JSX.Element {
  const client = useContext(ApiClientContext);
  const locale = client?.device.getLanguageConfig().locale || '';
  const logger = useLogger('IntlProvider');
  const handleError = useCallback(
    (error: unknown) => logger.error({error: ecsError(error)}),
    [logger],
  );

  const timeZone = client?.cookiesRegistry.timezoneName.restore();

  const [intlCache] = useState(() => createIntlCache());

  const intl = useMemo(() => {
    const intl = createIntl(
      {
        formats,
        defaultLocale: 'ru-RU',
        defaultFormats: formats,
        locale,
        messages,
        textComponent: React.Fragment,
        onError: handleError,
        timeZone: timeZone || undefined,
      },
      intlCache,
    );

    patchIntlNumbersWithLatn(intl);

    return intl;
  }, [handleError, intlCache, locale, messages, timeZone]);

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>;
}
