import {createRequestAction} from 'store/utils/requestActions';
import {SocialPost} from 'types/SocialPost';
import {createAction} from 'typesafe-actions';

export type LoadPostRequest = {
  postId: string;
};

export type LoadPostResponse = SocialPost;

export const loadPost = createRequestAction(
  '@socialPosts/LOAD_REQUEST',
  '@socialPosts/LOAD_SUCCESS',
  '@socialPosts/LOAD_FAILURE',
)<LoadPostRequest, LoadPostResponse>();

export const clearPost = createAction('@socialPosts/CLEAR')<{postId: string}>();
