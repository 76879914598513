import {createReducer} from 'typesafe-actions';

import {load} from './actions';

type State = {
  url: string;
  hash: string;
};

const initialState: State = {
  url: '',
  hash: '',
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload: {url}}) => ({
    hash: '',
    url,
  }))
  .handleAction(load.success, (state, {meta: {url}, payload: {hash}}) => {
    if (state.url === url) {
      return {url, hash};
    }
    return state;
  });
