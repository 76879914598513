import {produce} from 'immer';
import {Error} from 'types/Error';
import {Quiz} from 'types/QuarantineQuiz';
import {QuizMetainfo} from 'types/QuarantineQuiz/Metainfo';
import {createReducer} from 'typesafe-actions';

import {load, loadMetainfo, save, start} from './actions';

type State = {
  quizType?: string;

  quiz?: Quiz;

  loading: boolean;
  loadingError?: Error;

  starting: boolean;
  startingError?: Error;

  saving: boolean;
  savingError?: Error;

  metainfo?: QuizMetainfo;
  metainfoError?: Error;
};

const initialState: State = {
  loading: false,
  starting: false,
  saving: false,
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload: {quizType}}) =>
    produce(state, (draft) => {
      draft.quizType = quizType;
      draft.loading = true;
      draft.loadingError = undefined;
    }),
  )
  .handleAction(load.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.quiz = payload.quiz;
    }),
  )
  .handleAction(load.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.loadingError = payload;
    }),
  )
  .handleAction(start.request, (state) =>
    produce(state, (draft) => {
      draft.starting = true;
      draft.startingError = undefined;
    }),
  )
  .handleAction(start.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.starting = false;
      draft.quiz = payload.quiz;
    }),
  )
  .handleAction(start.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.starting = false;
      draft.startingError = payload;
    }),
  )
  .handleAction(save.request, (state) =>
    produce(state, (draft) => {
      draft.saving = true;
      draft.savingError = undefined;
    }),
  )
  .handleAction(save.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.saving = false;
      draft.quiz = payload.quiz;
    }),
  )
  .handleAction(save.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.saving = false;
      draft.savingError = payload;
    }),
  )
  .handleAction(loadMetainfo.success, (state, {payload: {metainfo}}) =>
    produce(state, (draft) => {
      draft.metainfo = metainfo;
    }),
  )
  .handleAction(loadMetainfo.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.metainfoError = payload;
    }),
  );
