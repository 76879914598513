import {produce} from 'immer';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';
import {shallowDiffers} from 'utils/shallowDiffers';

import {loadPage, LoadPageRequest, LoadPageResponse} from './actions';

type State = {
  readonly data: LoadPageResponse | null;
  readonly error: Error | null;
  readonly loading: boolean;
  readonly request: LoadPageRequest | null;
  readonly loaded: boolean;
};

const initialState: State = {
  data: null,
  error: null,
  loaded: false,
  loading: false,
  request: null,
};

export const reducer = createReducer(initialState)
  .handleAction(loadPage.request, (state, {payload}) =>
    produce(state, (draft) => {
      draft.request = payload;
      draft.data = null;
      draft.error = null;
      draft.loading = true;
      draft.loaded = false;
    }),
  )
  .handleAction(loadPage.success, (state, {meta, payload}) =>
    produce(state, (draft) => {
      if (!shallowDiffers(draft.request, meta)) {
        draft.data = payload;
        draft.loading = false;
        draft.loaded = true;
      }
    }),
  )
  .handleAction(loadPage.failure, (state, {meta, payload}) =>
    produce(state, (draft) => {
      if (!shallowDiffers(draft.request, meta)) {
        draft.error = payload;
        draft.loading = false;
      }
    }),
  );
