import {combineReducers} from 'utils/combineReducers';

import {reducer as cached} from './cached/reducer';
import {reducer as detected} from './detected/reducer';
import {reducer as list} from './list/reducer';
import {reducer as metainfo} from './metainfo/reducer';
import {reducer as selected} from './selected/reducer';
import {reducer as suggest} from './suggest/reducer';
import {reducer as validated} from './validated/reducer';

export const reducer = combineReducers({
  cached,
  detected,
  list,
  metainfo,
  selected,
  suggest,
  validated,
});
