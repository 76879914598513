import {DEFAULT_APPEARANCE} from 'helpers/contentList';
import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadResponse = {
  currency: string;
  language: string;
  items: ContentListItem[];
  nextPageToken: string;
};

export type LoadRequest = {
  appearance: typeof DEFAULT_APPEARANCE;
  origin: {
    premium: Record<string, unknown>;
  };
  pageToken?: string;
  count?: number;
};

export const load = createRequestAction(
  '@premium/LOAD_REQUEST',
  '@premium/LOAD_SUCCESS',
  '@premium/LOAD_FAILURE',
)<{pageToken: string | null; count: number}, LoadResponse>();
