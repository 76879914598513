import {createContext} from 'react';

import {Route} from './types';

export const InitialDataContext = createContext<
  | {
      route: Route;
      props: Record<string, unknown>;
    }
  | undefined
>(undefined);
