import {castDraft, produce} from 'immer';
import {setStorageViewedCoupon, syncStorageViewedCoupons} from 'store/modules/couponCards/storage';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {
  loadAll,
  LoadAllResponse,
  loadOne,
  setViewedCouponCard,
  syncViewedCouponCards,
} from './actions';

const initialState = getInitialState<unknown, LoadAllResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadAll.request, (state, {payload}) => {
    return produce(state, (draft) => {
      draft.request = castDraft(payload);
      draft.error = null;
      draft.loading = true;
      draft.loaded = false;
    });
  })
  .handleAction(loadAll.success, (state, {meta, payload}) => handleSuccess(state, meta, payload))
  .handleAction(loadAll.failure, (state, {meta, payload}) => handleFailure(state, meta, payload))
  .handleAction(loadOne.request, (state, {payload}) => handleRequest(state, payload, true))
  .handleAction(loadOne.failure, (state, {meta, payload}) => handleFailure(state, meta, payload))
  .handleAction(loadOne.success, (state, {meta, payload}) => {
    const couponIndex = state.data?.items?.findIndex(({id}) => id === payload.item.id);

    if (Number(couponIndex) >= 0) {
      const nextItems = state.data?.items.map((item) =>
        item.id === payload.item.id ? payload.item : item,
      );

      return handleSuccess(state, meta, {items: nextItems || []});
    }

    return handleSuccess(state, meta, {
      items: [...(state.data?.items || []), payload.item],
    });
  })
  .handleAction(setViewedCouponCard, (state, {payload}) =>
    produce(state, (draft) => {
      draft.data?.items.forEach((item) => {
        if (item.id === payload) {
          setStorageViewedCoupon(payload);
          item.viewed = true;
        }
      });
    }),
  )
  .handleAction(syncViewedCouponCards, (state) =>
    produce(state, (draft) => {
      syncStorageViewedCoupons(draft.data?.items || []);
    }),
  );
