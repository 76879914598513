import classnames from 'classnames/bind';
import React, {ChangeEventHandler, MouseEventHandler, useEffect, useState} from 'react';

import styles from './index.scss';

const cn = classnames.bind(styles);

type RadioProxyProps = {
  id?: string;
  disabled?: boolean;
  checked?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onClick?: MouseEventHandler<HTMLInputElement>;
  name: string;
  value?: string;
  className?: string;
};

type Props = React.PropsWithChildren<{
  nowrap?: boolean;
  fullWidth?: boolean;
  hideButtonOnMobile?: boolean;
}> &
  RadioProxyProps;

export function RadioProxy({
  id,
  disabled,
  checked,
  onChange,
  onClick,
  name,
  value,
  className,
}: RadioProxyProps): JSX.Element {
  const [intermediateChecked, setIntermediateChecked] = useState(checked);

  useEffect(() => {
    // this is done to make sure that checked state is set to underlying DOM element.
    // without this intermediate value input stays unchecked, which breaks a11y.
    setTimeout(() => {
      setIntermediateChecked(checked);
    }, 0);
  }, [checked]);

  return (
    <input
      name={name}
      value={value}
      type="radio"
      onChange={onChange}
      onClick={onClick}
      checked={intermediateChecked}
      disabled={disabled}
      id={id}
      className={className}
    />
  );
}

export const Radio = React.memo(
  React.forwardRef(function Radio(
    {
      id,
      nowrap = false,
      fullWidth = false,
      disabled,
      checked,
      hideButtonOnMobile,
      onChange,
      onClick,
      children,
      name,
      value,
    }: Props,
    ref: React.Ref<HTMLLabelElement>,
  ) {
    return (
      <label
        className={cn('label', {nowrap, fullWidth, disabled, hideButtonOnMobile})}
        htmlFor={id}
        ref={ref}
      >
        <RadioProxy
          id={id}
          disabled={disabled}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          name={name}
          value={value}
          className={styles.radioButton}
        />
        <span className={styles.text}>{children}</span>
      </label>
    );
  }),
);
