import {getServerTimestamp} from 'helpers/serverTime';
import {assignSetByPath} from 'utils/object';

import {LOAD_SUCCESS as LOAD_FILTERS_SUCCESS, loadNotificationsFiltersSuccess} from './filters';
import {prepareFiltersPayload, prepareUnreadCountPayload} from './utils';

const PAGE_SIZE = 20;

const SET_CURRENT_FILTER = 'notifications/SET_CURRENT_FILTER';
const SET_UNREAD_COUNT = 'notifications/SET_UNREAD_COUNT';

const LOAD = 'notifications/LOAD';
const LOAD_SUCCESS = 'notifications/LOAD_SUCCESS';
const LOAD_FAIL = 'notifications/LOAD_FAIL';

const MARK_AS_READ = 'notifications/MARK_AS_READ';
const MARK_AS_READ_SUCCESS = 'notifications/MARK_AS_READ_SUCCESS';
const MARK_AS_READ_FAIL = 'notifications/MARK_AS_READ_FAIL';

const MARK_ALL_AS_READ = 'notifications/MARK_ALL_AS_READ';
const MARK_ALL_AS_READ_SUCCESS = 'notifications/MARK_ALL_AS_READ_SUCCESS';
const MARK_ALL_AS_READ_FAIL = 'notifications/MARK_ALL_AS_READ_FAIL';

const initialState = {
  data: {},

  currentFilterId: null,

  unreadCount: 0,
  unreadCountLastUpdate: 0,
};

function initFilterDataState(filterId) {
  return {
    filterId,
    items: null,
    loading: false,
    loadingError: null,
    pageLoading: false,
    pageLoadingError: null,
    nextPageToken: null,
  };
}

function patchNotifications(state, patch) {
  let dataChanged = false;

  const nextData = Object.keys(state.data).reduce((data, filterId) => {
    const filterData = state.data[filterId];
    let nextFilterData = filterData;

    if (filterData.items) {
      let itemsChanged = false;

      const nextItems = filterData.items.map((item) => {
        const nextItem = patch(item);

        if (item !== nextItem) {
          itemsChanged = true;

          return nextItem;
        }

        return item;
      });

      if (itemsChanged) {
        dataChanged = true;

        nextFilterData = {
          ...filterData,
          items: nextItems,
        };
      }
    }

    // eslint-disable-next-line no-param-reassign
    data[filterId] = nextFilterData;

    return data;
  }, {});

  return {
    ...state,
    data: dataChanged ? nextData : state.data,
  };
}

function prepareUnreadCount(unreadCount) {
  return {
    unreadCount,
    unreadCountLastUpdate: getServerTimestamp(),
  };
}

function baseReducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_CURRENT_FILTER: {
      const {filterId} = action;

      return {
        ...state,
        currentFilterId: filterId,
        data: {
          ...state.data,
          [filterId]: state.data[filterId] || initFilterDataState(filterId),
        },
      };
    }

    case LOAD: {
      const {filterId, pageToken} = action;
      let nextFilterData;

      if (filterId in state.data) {
        const filterData = state.data[filterId];
        nextFilterData = {
          ...filterData,
        };

        if (pageToken) {
          nextFilterData.pageLoading = true;
          nextFilterData.pageLoadingError = null;
        } else {
          nextFilterData.loading = true;
          nextFilterData.loadingError = null;

          if (filterData.items) {
            if (filterData.items.length > PAGE_SIZE) {
              nextFilterData.items = filterData.items.slice(0, PAGE_SIZE);
            } else {
              nextFilterData.items = filterData.items;
            }
          }
        }
      } else {
        nextFilterData = initFilterDataState(filterId);
      }

      return assignSetByPath(state, ['data', filterId], nextFilterData);
    }

    case LOAD_SUCCESS: {
      const {filterId, pageToken, result} = action;
      const {items, nextPageToken} = result;
      const filterData = state.data[filterId];
      let nextFilterData;

      if (pageToken) {
        nextFilterData = {
          ...filterData,
          pageLoading: false,
          items: filterData.items.concat(items || []),
        };
      } else {
        nextFilterData = {
          ...filterData,
          loading: false,
          items,
        };
      }

      nextFilterData.nextPageToken = items.length ? nextPageToken || null : null;

      return assignSetByPath(state, ['data', filterId], nextFilterData);
    }

    case LOAD_FAIL: {
      const {error, filterId, pageToken} = action;
      const filterData = state.data[filterId];
      let nextFilterData;

      if (pageToken) {
        nextFilterData = {
          ...filterData,
          pageLoading: false,
          pageLoadingError: error,
        };
      } else {
        nextFilterData = {
          ...filterData,
          loading: false,
          loadingError: error,
        };
      }

      return assignSetByPath(state, ['data', filterId], nextFilterData);
    }

    case SET_UNREAD_COUNT:
      return {
        ...state,
        ...prepareUnreadCount(action.unreadCount),
      };

    case LOAD_FILTERS_SUCCESS: {
      const {currentFilterId} = state;
      const {items: filters, unreadCount} = action.result;

      return {
        ...state,
        currentFilterId:
          filters && filters.some((filter) => filter.id === currentFilterId)
            ? currentFilterId
            : null,
        ...prepareUnreadCount(unreadCount),
      };
    }

    case MARK_AS_READ_SUCCESS: {
      const {ids} = action;
      const idsMap = ids.reduce((map, id) => {
        map[id] = true; // eslint-disable-line no-param-reassign

        return map;
      }, {});

      return patchNotifications(state, (item) =>
        idsMap[item.id] && !item.read ? {...item, read: true} : item,
      );
    }

    case MARK_ALL_AS_READ_SUCCESS:
      return patchNotifications(state, (item) => (item.read ? item : {...item, read: true}));

    default:
      return state;
  }
}

export const reducer = baseReducer;

export function setNotificationsCurrentFilter(filterId) {
  return {
    type: SET_CURRENT_FILTER,
    filterId,
  };
}

export function loadNotifications(filterId, pageToken) {
  const query = {
    count: PAGE_SIZE,
    filterId,
  };

  if (pageToken) {
    query.pageToken = pageToken;
  }

  return {
    filterId,
    pageToken,
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api.get('/notifications', {query}).then(
        ({
          body: {
            payload: {items, nextPageToken},
          },
        }) => ({
          items,
          nextPageToken,
        }),
      ),
  };
}

export function setNotificationsUnreadCount(unreadCount) {
  return {
    type: SET_UNREAD_COUNT,
    unreadCount,
  };
}

function processMarkAsReadPayload(data, dispatch) {
  if (data.body.payload.items) {
    dispatch(loadNotificationsFiltersSuccess(prepareFiltersPayload(data)));
  } else {
    dispatch(setNotificationsUnreadCount(prepareUnreadCountPayload(data).unreadCount));
  }
}

export function markNotificationsAsRead(ids) {
  return {
    ids,
    types: [MARK_AS_READ, MARK_AS_READ_SUCCESS, MARK_AS_READ_FAIL],
    promise: (client, dispatch, getState) =>
      client.api
        .post('/notifications/markAsRead', {body: {ids}})
        .then((data) => processMarkAsReadPayload(data, dispatch)),
  };
}

export function markAllNotificationsAsRead() {
  return {
    types: [MARK_ALL_AS_READ, MARK_ALL_AS_READ_SUCCESS, MARK_ALL_AS_READ_FAIL],
    promise: (client, dispatch) =>
      client.api
        .post('/notifications/markAllAsRead')
        .then((data) => processMarkAsReadPayload(data, dispatch)),
  };
}
