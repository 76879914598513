import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadPremiumSeoRequest = {
  premiumSeoId: string;
};

type RelatedPage = {title: string; id: string};

export type LoadPremiumSeoResponse = {
  metaTitle?: string;
  metaDescription?: string;
  title: string;
  items: ContentListItem[];
  relatedPages?: RelatedPage[];
};

export const loadPremiumSeo = createRequestAction(
  '@premiumSeo/LOAD_REQUEST',
  '@premiumSeo/LOAD_RESPONSE',
  '@premiumSeo/LOAD_FAILURE',
)<LoadPremiumSeoRequest, LoadPremiumSeoResponse>();
