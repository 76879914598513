import {discardableByUser} from 'helpers/discardable';
import {assignSet} from 'utils/object';

const LOAD = 'parcelWarrantyTexts/LOAD';
const LOAD_SUCCESS = 'parcelWarrantyTexts/LOAD_SUCCESS';
const LOAD_FAIL = 'parcelWarrantyTexts/LOAD_FAIL';

const initialState = {
  dict: {},
  loaded: {},
  error: {},
  loading: {},
  meta: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignSet(state.error, action.id, null),
        loaded: assignSet(state.loaded, action.id, false),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        loading: assignSet(state.loading, action.id, false),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignSet(state.loading, action.id, false),
      };
    default:
      return state;
  }
}

export const parcelWarrantyTexts = discardableByUser(reducer);

export function isParcelWarrantyTextsLoading(globalState, id) {
  const {loading} = globalState.parcelWarrantyTexts;
  return !!loading[id];
}

export function isParcelWarrantyTextsLoaded(globalState, id) {
  const {loaded} = globalState.parcelWarrantyTexts;
  return loaded[id];
}

export function getParcelWarrantyTexts(globalState, id) {
  return isParcelWarrantyTextsLoaded(globalState, id)
    ? globalState.parcelWarrantyTexts.dict[id]
    : null;
}

export function getParcelWarrantyTextsError(globalState, id) {
  return globalState.parcelWarrantyTexts.error[id] || null;
}

export function loadParcelWarrantyTexts(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: (client) =>
      client.api.get(`/parcels/${id}/warranty`).then(({language, body: {payload}}) => ({
        parcel: payload,
        language,
      })),
  };
}
