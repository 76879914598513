import {Type} from 'helpers/UserType';
import {createStore as _createStore, applyMiddleware, compose} from 'redux';
import appConfig from 'config';
import {getUserLanguage} from 'helpers/ApiClient/Device/language';
import {createStoreWithInitialState, addTransactions, isStoreWithTransactions} from 'utils/redux';
import {createUrl, getQueryData} from 'utils/url';
import {nextTickPromise} from 'utils/nextTick';
import {syncCouponViews} from './modules/couponViews';
import clientMiddleware from './middleware/clientMiddleware';
import versionMiddleware from './middleware/versionMiddleware';
import {isTrackingMarquesLoaded, loadTrackingMarques} from './modules/tracking';
import {setUser, updateUser, changeUser} from './modules/auth';
import {
  setOrigin,
  setDetectedLanguage,
  setRichMeta,
  setBot,
  setRegion,
  setCurrency,
  setLanguage,
  setDeviceId,
  setDeviceVars,
  setAppWebView,
} from './modules/preferences';
import {setCookiesSettings} from './modules/cookiesSettings';
import {setUserAdulthood} from './modules/adult';

function updatePreferences(client, store) {
  const state = store.getState();
  const {currency, language, region, origin, detectedLanguage} = state.preferences;
  const deviceCurrency = client.device.getCurrency();
  const deviceLanguage = client.device.getLanguage();
  const deviceRegion = client.device.getRegion();

  if (origin !== client.getOrigin()) {
    store.dispatch(setOrigin(client.getOrigin()));
  }

  const userLanguage = getUserLanguage(
    client.req,
    client.scope,
    client.device.getAvailableLanguagesConfig(),
  );
  if (detectedLanguage !== userLanguage) {
    store.dispatch(setDetectedLanguage(userLanguage));
  }

  if (currency !== deviceCurrency) {
    store.dispatch(setCurrency(deviceCurrency));
  }

  if (language !== deviceLanguage) {
    store.dispatch(setLanguage(deviceLanguage));
  }

  if (region !== deviceRegion) {
    store.dispatch(setRegion(deviceRegion));
  }
}

function updateConfig(client, store) {
  store.dispatch(setDeviceVars(client.device.getDeviceVars()));
}

function updateAdulthood(client, store) {
  store.dispatch(setUserAdulthood(client.device.isAdult()));
}

function updateCookiesSettings(client, store) {
  store.dispatch(setCookiesSettings(client.device.getCookiesSettings()));
}

export function createEmptyStore(client) {
  const reducer = require('./modules/reducer').default;

  if (client) {
    return {
      store: applyMiddleware(clientMiddleware(client))(_createStore)(reducer),
    };
  }

  return {store: _createStore(reducer)};
}

// action store can read actual state, but can't change it
// instead it save all actions
export function createActionStoreWrapper(client, store) {
  let pendingActions = [];

  const getPendingActions = () => {
    return [...pendingActions];
  };

  const releasePendingActions = () => {
    pendingActions.forEach((action) => {
      store.dispatch(action);
    });
    pendingActions = [];
  };

  const collectAction = (action) => {
    // filter redux init action
    if (!action?.type?.startsWith?.('@@redux/')) {
      pendingActions.push(action);
    }
  };

  const originalStore = isStoreWithTransactions(store) ? store.originalStore : store;

  const actionStore = {...originalStore, getPendingActions, releasePendingActions};
  actionStore.dispatch = clientMiddleware(client)(actionStore)(collectAction);

  return actionStore;
}

export async function createStore(client, data = {}) {
  const userType = client.device.getUserType();

  const reducer = await nextTickPromise(() => require('./modules/reducer').default);

  return nextTickPromise(async function createStoreNextTick() {
    const middleware = [versionMiddleware(client), clientMiddleware(client)];

    let finalCreateStore;
    if (
      ['stage', 'local', 'dev'].includes(appConfig.releaseStage) &&
      global.__REDUX_DEVTOOLS_EXTENSION__
    ) {
      finalCreateStore = compose(
        applyMiddleware(...middleware),
        global.__REDUX_DEVTOOLS_EXTENSION__(),
      )(_createStore);
    } else {
      finalCreateStore = applyMiddleware(...middleware)(_createStore);
    }

    let store = finalCreateStore(reducer, data);
    if (__SERVER__) {
      store = createStoreWithInitialState(store);
    }

    updateConfig(client, store);
    updatePreferences(client, store);
    updateAdulthood(client, store);
    store.dispatch(setDeviceId(client.device.getDeviceId()));
    store.dispatch(setUser(client.device.getUser()));
    store.dispatch(setAppWebView(client.device.isAppWebView()));
    updateCookiesSettings(client, store);

    client.device.on('deviceIdChange', () =>
      store.dispatch(setDeviceId(client.device.getDeviceId())),
    );
    client.device.on('configChange', (config, prevConfig) => {
      const next = config?.preferences;
      const prev = prevConfig?.preferences;
      if (__CLIENT__ && next && prev) {
        if (prev.currency !== next.currency) {
          const query = getQueryData(window.location.href);

          if (query.currency) {
            delete query.currency;

            const {origin, pathname, hash} = window.location;
            const url = createUrl(origin + pathname + hash, query);

            window.location.replace(url);
          } else {
            window.location.reload();
          }
          return;
        }
        if (prev.region !== next.region) {
          window.location.reload();
          return;
        }
      }

      updateConfig(client, store);
      updatePreferences(client, store);
    });
    client.device.on('userUpdate', () => {
      store.dispatch(updateUser(client.device.getUser()));
    });
    client.device.on('userChange', () => {
      store.dispatch(changeUser(client.device.getUser()));
      client.device.configure();
    });
    client.device.on('cookiesSettingsChange', () => updateCookiesSettings(client, store));

    const rich = __CLIENT__ || Boolean(userType && userType.verified);
    if (rich) {
      store.dispatch(setRichMeta(true));
    }

    const bot = Boolean(userType && userType.type === Type.BOT);
    if (bot) {
      store.dispatch(setBot(true));
    }

    if (!isTrackingMarquesLoaded(store.getState())) {
      store.dispatch(loadTrackingMarques());
    }

    if (__DEVELOPMENT__ && module.hot) {
      module.hot.accept('./modules/reducer', () => {
        store.replaceReducer(require('./modules/reducer'));
      });
    }

    store.dispatch(syncCouponViews());

    return {store: addTransactions(store, client.device.log)};
  }).catch((err) => {
    client.device.log.getLogger('store/create').error(err);
    throw err;
  });
}
