import {produce} from 'immer';
import {handleContentListProducts} from 'store/enhancer/contentList';
import {loadArbitraryContentList as loadArbitraryContentListAction} from 'store/modules/contentListArbitrary/actions';
import {loadAll as loadAllCouponCards} from 'store/modules/couponCards/actions';
import {applyOffers} from 'store/modules/offers/actions';
import {
  addProductToCollection,
  editProductsInCollections,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {favoriteProductsDepended} from 'store/utils/enhancers/favoriteProductsDepended';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  SimpleState,
} from 'store/utils/reducerCreators/simple';
import {OfferBannerState} from 'types/Banner';
import {isContentListDeliveryMethods} from 'types/ContentList/ContentListDeliveryMethods';
import {isContentListProductsList} from 'types/ContentList/ContentListProductsList';
import {isOfferButton} from 'types/PresalePromo/Presale/Button';
import {ProductHeader} from 'types/ProductNext/Header';
import {createReducer, isActionOf} from 'typesafe-actions';

import {
  load,
  LoadRequest,
  LoadResponse,
  LoadResponseIncomplete,
  setDeliveryMethod,
  update,
} from './actions';

type ResponseState = Partial<LoadResponseIncomplete> & {
  pendingVariantsData?: {productId: string; payload: ProductHeader};
};

type State = SimpleState<LoadRequest, ResponseState> & {nextPageLoading?: boolean};

const initialState: State = getInitialState<LoadRequest, ResponseState>();

function getIsNextPageLoad(
  data: ResponseState | null,
  pageToken: string | undefined,
): data is LoadResponse {
  return Boolean(data?.items && pageToken && pageToken === data?.nextPageToken);
}

function isSameProductRequest(state: State, request: LoadRequest) {
  return state.data?.header?.id === request.productId;
}

function needToSkipIncomplete(state: State, request: LoadRequest): boolean {
  return Boolean(state.loaded && request.incomplete && isSameProductRequest(state, request));
}

const initialReducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload}) => {
    const isNextPageLoad = getIsNextPageLoad(state.data, payload.pageToken);
    if (isSameProductRequest(state, payload) || isNextPageLoad) {
      return produce(state, (draft) => {
        draft.loading = true;
        if (isNextPageLoad) draft.nextPageLoading = true;
      });
    }

    return handleRequest(state, payload);
  })
  .handleAction(load.success, (state, {meta, meta: {pageToken}, payload}) => {
    if (needToSkipIncomplete(state, meta) || state.error) {
      return state;
    }

    return produce(state, (draft) => {
      draft.loading = false;
      draft.loaded = true;
      const {pendingVariantsData} = draft.data || {};

      if (getIsNextPageLoad(draft.data, pageToken)) {
        draft.nextPageLoading = false;
        draft.data.items.push(...payload.items);
        draft.data.nextPageToken = undefined;
      } else if (!pageToken) {
        if (payload.header?.couponInfo) {
          enhanceLoadedTime(payload.header.couponInfo.coupon);
        }
        draft.data = enhanceLoadedTime(payload);
        draft.data.ssr = state.data?.ssr ?? payload.ssr;
      }

      if (draft.data?.header && pendingVariantsData?.productId === draft.data.header.id) {
        if (!draft.data.header?.variants) {
          Object.assign(draft.data.header, pendingVariantsData.payload);
        }
        draft.data.pendingVariantsData = undefined;
      }
    });
  })
  .handleAction(load.failure, (state, {meta, payload}) => {
    if (needToSkipIncomplete(state, meta)) {
      return state;
    }

    return {
      ...handleFailure(state, meta, payload),
      nextPageLoading: false,
    };
  })
  .handleAction(loadArbitraryContentListAction.success, (state, {meta, payload}) =>
    produce(state, (draft) => {
      draft.data?.items?.forEach((item) => {
        if (item.id === meta.itemId && isContentListProductsList(item)) {
          item.content.productsList.items.push(...payload.items);
          item.content.productsList.nextPageToken = payload.nextPageToken;
        }
      });
    }),
  )
  .handleAction(update.success, (state, {payload}) => {
    if (state.data?.header?.id === payload.header.id) {
      return {
        ...state,
        data: {
          ...state.data,
          header: {
            // new payload may not have variants data
            ...state.data.header,
            ...payload.header,
          },
          footer: payload.footer,
        },
      };
    }
    return state;
  })
  .handleAction(setDeliveryMethod.success, (state, {meta}) =>
    produce(state, (draft) => {
      const deliveryMethodsContentListItem = draft.data?.items?.find((item) =>
        isContentListDeliveryMethods(item),
      );
      const deliveryMethods =
        deliveryMethodsContentListItem &&
        isContentListDeliveryMethods(deliveryMethodsContentListItem) &&
        deliveryMethodsContentListItem.content.deliveryMethods;

      if (deliveryMethods) {
        deliveryMethods.defaultDeliveryId = meta.deliveryId;
      }
    }),
  )
  .handleAction(editProductsInCollections.success, (state, {payload}) =>
    produce(state, (draft) => {
      payload.collectionsUpdatedProductInfoList.forEach(({productId, isFavorite, flow}) => {
        if (draft.data?.header?.id === productId) {
          draft.data.header.favorite = isFavorite;
          draft.data.header.productCollectionFlow = flow;
        }

        handleContentListProducts(draft.data?.items, (product) => {
          if (product.id === productId) {
            product.favorite = isFavorite;
          }
        });
      });
    }),
  )
  .handleAction(
    [addProductToCollection.success, removeProductFromCollection.success],
    (state, action) =>
      produce(state, (draft) => {
        handleContentListProducts(draft.data?.items, (product) => {
          if (product.id === action.meta.itemKey.productId) {
            product.favorite = isActionOf(addProductToCollection.success, action);
          }
        });
      }),
  )
  .handleAction(loadAllCouponCards.success, (state, {payload}) =>
    produce(state, (draft) => {
      if (draft?.data?.header?.couponInfo?.coupon) {
        const couponId = draft.data.header.couponInfo.coupon.id;
        const updatedCoupon = payload.items.find(({id}) => id === couponId);

        if (updatedCoupon) {
          draft.data.header.couponInfo.coupon = updatedCoupon;
        }
      }
    }),
  )
  .handleAction(applyOffers.success, (state, {payload}) =>
    produce(state, (draft) => {
      payload.appliedOffers.forEach(({id}) => {
        const bannerButton = draft.data?.header?.bmplProductPresale?.button;

        if (
          bannerButton &&
          isOfferButton(bannerButton) &&
          bannerButton.applyOffer.offerDescription.id === id
        ) {
          bannerButton.applyOffer.state = OfferBannerState.APPLIED;
        }
      });
    }),
  );

export const reducer = favoriteProductsDepended(initialReducer, ['data']);
