import {DotLoader, DotLoaderStyle} from 'components/DotLoader';
import {Button} from 'components/UIKit/Button';
import {Icon} from 'components/UIKit/Icon';
import {Popup} from 'components/UIKit/Popup';
import {Content} from 'components/UIKit/Popup/Content';
import {Footer} from 'components/UIKit/Popup/Footer';
import {StandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupView, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import {ApiResponse} from 'helpers/ApiClient/Transport/Response';
import {useScope} from 'hooks/useScope';
import React, {ReactElement} from 'react';
import {defineMessages, FormattedMessage, useIntl} from 'react-intl';
import {ApiError} from 'types/Error';
import {getLocalizedErrorMessage} from 'utils/error';

import styles from './styles.scss';

const messages = defineMessages({
  loadingTitle: {
    description: '[title] текст загрузки при активации купона',
    defaultMessage: 'Activating coupon...',
  },
  buttonText: {
    description: '[button] текст кнопки в попапе с ошибкой',
    defaultMessage: 'Got it',
  },
});

export function PromocodeLoadingPopup({onBack, onClose}: PopupViewProps): ReactElement {
  return (
    <Popup width="452px">
      <StandaloneControls onClose={onBack || onClose} />
      <Content>
        <div className={styles.root}>
          <div className={styles.loader}>
            <DotLoader style={DotLoaderStyle.DARK} />
          </div>
          <div className={styles.text}>
            <FormattedMessage {...messages.loadingTitle} />
          </div>
        </div>
      </Content>
    </Popup>
  );
}

export function createPromocodeLoadingPopupView(): PopupView {
  return (viewProps) => <PromocodeLoadingPopup {...viewProps} />;
}

type PromocodeErrorPopupProps = {error: ApiResponse<ApiError>};

export function PromocodeErrorPopup({
  error,
  onBack,
  onClose,
}: PromocodeErrorPopupProps & PopupViewProps): ReactElement {
  const scope = useScope();
  const intl = useIntl();
  const message = error?.body?.userMessage || getLocalizedErrorMessage(scope, intl, error);

  return (
    <Popup width="452px">
      <StandaloneControls onClose={onBack || onClose} />
      <Content>
        <div className={styles.root}>
          <div className={styles.errorIcon}>
            <Icon name="close-circle-filled-24" type="mono" />
          </div>
          <div className={styles.text}>{message}</div>
        </div>
      </Content>
      <Footer>
        <Button onClick={onBack || onClose} tag="button" size="medium" color="primary">
          <FormattedMessage {...messages.buttonText} />
        </Button>
      </Footer>
    </Popup>
  );
}

export function createPromocodeErrorPopupView(props: PromocodeErrorPopupProps): PopupView {
  return (viewProps) => <PromocodeErrorPopup {...viewProps} {...props} />;
}
