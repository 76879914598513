import {discardableByUser} from 'helpers/discardable';

const LOAD = 'devices/LOAD';
const LOAD_SUCCESS = 'devices/LOAD_SUCCESS';
const LOAD_FAIL = 'devices/LOAD_FAIL';

const SIGNOUT = 'devices/SIGNOUT';
const SIGNOUT_SUCCESS = 'devices/SIGNOUT_SUCCESS';
const SIGNOUT_FAIL = 'devices/SIGNOUT_FAIL';

const initialState = {
  items: [],
  meta: {},
  error: null,
  loading: false,
  loaded: false,
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case LOAD_SUCCESS: {
      return {
        ...state,
        items: action.result.items || [],
        meta: {language: action.result.language},
        error: null,
        loading: false,
        loaded: true,
      };
    }
    case SIGNOUT_SUCCESS:
      if (state.items) {
        return {
          ...state,
          items: state.items.filter((item) => item.thisDevice),
        };
      }
      return state;
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isDevicesLoading(globalState) {
  return globalState.devices.loading;
}

export function isDevicesLoaded(globalState) {
  const {devices} = globalState;
  return devices.loaded;
}

export function getDevices(globalState) {
  return isDevicesLoaded(globalState) ? globalState.devices.items : null;
}

export function getDevicesError(globalState) {
  return globalState.devices.error;
}

export function loadDevices() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api.get('/users/self/devices').then(({language, body: {payload}}) => {
        const items = payload.items || [];
        items.sort((left, right) => {
          if (left.thisDevice === right.thisDevice) {
            return right.lastSignInMs - left.lastSignInMs;
          }
          return left.thisDevice ? -1 : 1;
        });
        return {items, language};
      }),
  };
}

export function signoutOtherDevices() {
  return {
    types: [SIGNOUT, SIGNOUT_SUCCESS, SIGNOUT_FAIL],
    promise: (client) => client.api.post('/auth/signOutOtherDevices'),
  };
}
