import {createRequestAction} from 'store/utils/requestActions';
import {SocialUser} from 'types/SocialUser';
import {createAction} from 'typesafe-actions';

export type LoadSocialUserFollowersRequest = {
  userId: string;
  pageToken?: string;
};

export type LoadSocialUserFollowersResponse = {
  items: SocialUser[];
  nextPageToken?: string;
};

export const loadSocialUserFollowers = createRequestAction(
  '@socialUserFollowers/LOAD_REQUEST',
  '@socialUserFollowers/LOAD_SUCCESS',
  '@socialUserFollowers/LOAD_FAILURE',
)<LoadSocialUserFollowersRequest, LoadSocialUserFollowersResponse>();

export const clearSocialUserFollowers = createAction(
  '@socialUserFollowers/CLEAR',
)<LoadSocialUserFollowersRequest>();
