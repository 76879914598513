import {produce} from 'immer';
import {followSocialUser, unfollowSocialUser} from 'store/modules/social/subscribe/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

import {
  clearSocialUser,
  loadSocialUser,
  LoadSocialUserRequest,
  LoadSocialUserResponse,
  saveSocialUserProfile,
  uploadSocialUserAvatar,
} from './actions';

const initialState = getInitialState<LoadSocialUserRequest, LoadSocialUserResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadSocialUser.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadSocialUser.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(
    [uploadSocialUserAvatar.success, saveSocialUserProfile.success],
    (state, {payload}) => handleSuccess(state, state?.request as LoadSocialUserRequest, payload),
  )
  .handleAction(loadSocialUser.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(clearSocialUser, () => initialState)
  .handleAction([followSocialUser.success, unfollowSocialUser.success], (state, action) =>
    produce(state, (draft) => {
      const {userId} = action.meta;
      const isFollowing = isActionOf(followSocialUser.success, action);

      if (draft?.data?.isSelf && typeof draft.data.followingCount === 'number') {
        draft.data.followingCount += isFollowing ? 1 : -1;
      }
      if (draft?.data?.id === userId) {
        draft.data.isFollowing = isFollowing;
      }
    }),
  );
