import config from 'config';
import {
  setVersionMismatch,
  isVersionMismatchCritical,
  getVersionMismatch,
} from '../modules/version';

// use endless promise instead of rejected promise to freeze store without error reports
const endlessPromise = new Promise(() => {});

export default function versionMiddleware(client) {
  return ({dispatch, getState}) =>
    (next) =>
    (action) => {
      if (__SERVER__) {
        return next(action);
      }

      const versionMismatch = client.getBuildVersionMismatch();
      if (versionMismatch && versionMismatch !== getVersionMismatch(getState())) {
        const criticalMismatch = client.isBuildVersionCriticalMismatch();

        client.device.log
          .getLogger('versionMiddleware')
          .log(
            `Mismatch client (${config.version}) and backend (${versionMismatch}) version, critical: ${criticalMismatch}`,
          );

        next(setVersionMismatch({versionMismatch, criticalMismatch}));
      }

      if (isVersionMismatchCritical(getState())) {
        return endlessPromise;
      }

      return next(action);
    };
}
