import {NoOutlineContext} from 'providers/NoOutlineContext';
import React, {useEffect, useState} from 'react';

export const AccessibleFocusOutline = ({
  children,
}: React.PropsWithChildren<unknown>): JSX.Element => {
  const [enableOutline, setOutlineEnabled] = useState(false);

  useEffect(() => {
    const handleKeydown = (evt: KeyboardEvent): void => {
      const isTabEvent = evt.key === 'Tab';
      const isRadioSwitch =
        evt.target instanceof HTMLElement &&
        evt.target.matches('input[type="radio"]') &&
        ['ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight'].includes(evt.key);

      if (isTabEvent || isRadioSwitch) {
        setOutlineEnabled(true);
      }
    };
    const handleMousedown = (): void => setOutlineEnabled(false);

    window.addEventListener('keydown', handleKeydown);
    window.addEventListener('mousedown', handleMousedown);

    return (): void => {
      window.removeEventListener('keydown', handleKeydown);
      window.addEventListener('mousedown', handleMousedown);
    };
  }, []);

  return <NoOutlineContext.Provider value={!enableOutline}>{children}</NoOutlineContext.Provider>;
};
