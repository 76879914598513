import {createRequestAction} from 'store/utils/requestActions';
import {ProductLite} from 'types/ProductLite';

export type LoadPostProductsRequest = {
  postId: string;
  pageToken?: string;
};

export type LoadPostProductsResponse<TItems = ProductLite> = {
  items: TItems[];
  nextPageToken?: string;
  mainProductsCount: number;
};

export const loadPostProducts = createRequestAction(
  '@socialProducts/LOAD_REQUEST',
  '@socialProducts/LOAD_SUCCESS',
  '@socialProducts/LOAD_FAILURE',
)<LoadPostProductsRequest, LoadPostProductsResponse>();
