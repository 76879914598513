import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {getSelectors} from 'store/utils/reducerCreators/simple/selectors';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {createSelectorFactory} from 'store/utils/selectors';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {EmptyObject} from 'types/EmptyObject';
import {UrlResponse} from 'types/UrlResponse';
import {createReducer, FuncAction} from 'typesafe-actions';

type LoadShareReferralRequest = EmptyObject;

type LoadShareReferralResponse = UrlResponse;

export const loadShareReferralAction = createRequestAction(
  'share/referral/LOAD',
  'share/referral/LOAD_SUCCESS',
  'share/referral/LOAD_FAIL',
)<LoadShareReferralRequest, LoadShareReferralResponse>();

const initialState = getInitialState<LoadShareReferralRequest, LoadShareReferralResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadShareReferralAction.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(loadShareReferralAction.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadShareReferralAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );

export const {
  getData: getShareReferral,
  isLoading: isShareReferralLoading,
  isLoaded: isShareReferralLoaded,
  getRequest: getShareReferralRequest,
  getError: getShareReferralError,
} = getSelectors(createSelectorFactory((state) => state.share.referral));

export function loadShareReferral(): FuncAction {
  return requestActionCreator(loadShareReferralAction, {}, (store, client) =>
    client.api
      .post<ClientBackendResponse<LoadShareReferralResponse>>('/shareReferral')
      .then(({body: {payload}}) => payload),
  );
}
