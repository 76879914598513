import config from 'config';
import {produce} from 'immer';
import {isActionOf} from 'typesafe-actions';
import {assignRemove, assignSet} from 'utils/object';
import {START_PAGE_TOKEN} from 'store/consts';
import {enhanceProducts} from 'store/utils/enhancers/enhanceProducts';
import {
  wrapProductsInContentList,
  appendContentListItems,
  getItemsToShow,
  handleContentListProducts,
} from 'store/enhancer/contentList';
import {
  addProductToCollection,
  editProductsInCollections,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {isContentListEmpty} from 'helpers/contentList';

const LOAD = 'productGroup/products/LOAD';
const LOAD_SUCCESS = 'productGroup/products/LOAD_SUCCESS';
const LOAD_FAIL = 'productGroup/products/LOAD_FAIL';

const initialState = {
  data: {},
  nextPageToken: {},
  meta: {},
  loading: {},
  error: {},
  loaded: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        data: action.pageToken ? state.data : assignRemove(state.data, action.id),
        error: assignRemove(state.error, action.id),
        loaded: action.pageToken ? state.loaded : assignRemove(state.loaded, action.id),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(
          state.data,
          action.id,
          appendContentListItems(
            state.data[action.id],
            action.result.items,
            action.pageToken === state.nextPageToken[action.id],
            !action.result.nextPageToken,
          ),
        ),
        meta: assignSet(state.meta, action.id, {
          currency: action.result.currency,
          language: action.result.language,
        }),
        loaded: assignSet(
          state.loaded,
          action.id,
          assignSet(state.loaded[action.id] || {}, action.pageToken || START_PAGE_TOKEN, true),
        ),
        loading: assignRemove(state.loading, action.id),
        nextPageToken: assignSet(state.nextPageToken, action.id, action.result.nextPageToken),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
      };
    case addProductToCollection.success.getType():
    case removeProductFromCollection.success.getType(): {
      return produce(state, (draft) => {
        Object.values(draft.data).forEach(({items}) => {
          handleContentListProducts(items, (product) => {
            if (product.id === action.meta.itemKey.productId) {
              // eslint-disable-next-line no-param-reassign
              product.favorite = isActionOf(addProductToCollection.success, action);
            }
          });
        });
      });
    }
    case editProductsInCollections.success.getType(): {
      return produce(state, (draft) => {
        action.payload.collectionsUpdatedProductInfoList.forEach(({productId, isFavorite}) => {
          Object.values(draft.data).forEach(({items}) => {
            handleContentListProducts(items, (product) => {
              if (product.id === productId) {
                // eslint-disable-next-line no-param-reassign
                product.favorite = isFavorite;
              }
            });
          });
        });
      });
    }
    default:
      return state;
  }
}

export default reducer;

export function isProductGroupProductsLoaded(globalState, id, pageToken) {
  const {loaded} = globalState.productGroups.products;
  if (loaded[id]) {
    return !pageToken || !!loaded[id][pageToken];
  }
  return false;
}

export function isProductGroupProductsOutdated(globalState, id) {
  return (
    isProductGroupProductsLoaded(globalState, id) &&
    !isProductGroupProductsLoaded(globalState, id, START_PAGE_TOKEN)
  );
}

export function isProductGroupProductsLoading(globalState, id) {
  return !!globalState.productGroups.products.loading[id];
}

export function getProductGroupProducts(globalState, id) {
  const {products} = globalState.productGroups;
  return isProductGroupProductsLoaded(globalState, id) ? getItemsToShow(products.data[id]) : null;
}

export function getProductGroupProductsNextPageToken(globalState, id) {
  const {products} = globalState.productGroups;
  return isProductGroupProductsLoaded(globalState, id) ? products.nextPageToken[id] : null;
}

export function getProductGroupProductsError(globalState, id) {
  const {products} = globalState.productGroups;
  return products.error[id] || null;
}

export function isProductGroupProductsEmpty(globalState, id) {
  const {products} = globalState.productGroups;
  return (
    isProductGroupProductsLoaded(globalState, id) &&
    !getProductGroupProductsNextPageToken(globalState, id) &&
    isContentListEmpty(products.data[id].items)
  );
}

export function loadProductGroupProducts(id, pageToken, count = config.productsPageSize) {
  const query = {};
  if (pageToken && pageToken !== START_PAGE_TOKEN) {
    query.pageToken = pageToken;
  }
  if (count) {
    query.count = count;
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    count,
    pageToken,
    id,
    promise: (client) =>
      client.api
        .get(`/productGroups/${id}/products`, {query})
        .then(({language, currency, body: {contexts, payload}}) => ({
          language,
          currency,
          ...payload,
          items: wrapProductsInContentList(
            enhanceProducts(payload.items, {language, currency, pageToken, contexts}),
          ),
        })),
  };
}
