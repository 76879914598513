import {assignRemove, assignSet} from 'utils/object';
import {enhanceProducts} from 'store/utils/enhancers/enhanceProducts';

export const LOAD = 'relatedProducts/orderGroup/LOAD';
export const LOAD_SUCCESS = 'relatedProducts/orderGroup/LOAD_SUCCESS';
export const LOAD_FAIL = 'relatedProducts/orderGroup/LOAD_FAIL';

const initialState = {
  data: {},
  loading: {},
  error: {},
  loaded: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.orderGroupId),
        loading: assignSet(state.loading, action.orderGroupId, true),
        loaded: assignRemove(state.loaded, action.orderGroupId),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(state.data, action.orderGroupId, action.result),
        loading: assignRemove(state.loading, action.orderGroupId),
        loaded: assignSet(state.loaded, action.orderGroupId, action.result),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.orderGroupId, action.error),
        loading: assignRemove(state.loading, action.orderGroupId),
      };
    default:
      return state;
  }
}

export default reducer;

export function isRelatedProductsOrderGroupLoading(globalState, orderGroupId) {
  return !!globalState.relatedProducts.orderGroup.loading[orderGroupId];
}

export function isRelatedProductsOrderGroupLoaded(globalState, orderGroupId) {
  const state = globalState.relatedProducts.orderGroup;
  return state.loaded[orderGroupId];
}

export function getRelatedProductsOrderGroupError(globalState, orderGroupId) {
  return globalState.relatedProducts.orderGroup.error[orderGroupId] || null;
}

export function getRelatedProductsOrderGroup(globalState, orderGroupId) {
  return isRelatedProductsOrderGroupLoaded(globalState, orderGroupId)
    ? globalState.relatedProducts.orderGroup.data[orderGroupId].items
    : null;
}

export function loadRelatedProductsOrderGroup(orderGroupId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    orderGroupId,
    promise: (client) =>
      client.api
        .get(`/related/purchases/orderGroup/${orderGroupId}`)
        .then(({language, currency, body: {contexts, payload}}) => ({
          currency,
          items: enhanceProducts(payload.items, {language, currency, contexts}),
          language,
        })),
  };
}
