import {Draft, produce} from 'immer';
import {
  getInitialState as getInitialSimpleState,
  handleFailure as handleSimpleFailure,
  handleRequest as handleSimpleRequest,
  handleSuccess as handleSimpleSuccess,
  SimpleState,
} from 'store/utils/reducerCreators/simple';
import {Error} from 'types/Error';

export type SimpleMappedState<TRequest, TData> = {[K in string]?: SimpleState<TRequest, TData>};

export type SimpleMappedStateRequest<T> = T extends SimpleMappedState<infer U, unknown> ? U : never;

export type SimpleMappedStateData<T> = T extends SimpleMappedState<unknown, infer U> ? U : never;

export function getInitialState<TRequest, TData>(): SimpleMappedState<TRequest, TData> {
  return {};
}

export function handleRequest<TRequest, TData>(
  state: SimpleMappedState<TRequest, TData>,
  key: string,
  request: TRequest,
  keepData?: boolean,
): SimpleMappedState<TRequest, TData> {
  return produce(state, (draft) => {
    draft[key] = handleSimpleRequest(
      state[key] || getInitialSimpleState<TRequest, TData>(),
      request,
      keepData,
    ) as Draft<SimpleState<TRequest, TData>>;
  });
}

export function handleSuccess<TRequest, TData>(
  state: SimpleMappedState<TRequest, TData>,
  key: string,
  request: TRequest,
  data: TData,
): SimpleMappedState<TRequest, TData> {
  return produce(state, (draft) => {
    draft[key] = handleSimpleSuccess(
      state[key] || getInitialSimpleState<TRequest, TData>(),
      request,
      data,
    ) as Draft<SimpleState<TRequest, TData>>;
  });
}

export function handleFailure<TRequest, TData>(
  state: SimpleMappedState<TRequest, TData>,
  key: string,
  request: TRequest,
  error: Error,
): SimpleMappedState<TRequest, TData> {
  return produce(state, (draft) => {
    draft[key] = handleSimpleFailure(
      state[key] || getInitialSimpleState<TRequest, TData>(),
      request,
      error,
    ) as Draft<SimpleState<TRequest, TData>>;
  });
}
