import {enhanceCurrency, enhanceLanguage} from 'store/utils/enhancers';
import {createAsyncActionNames, actionNameCreator} from 'store/utils';
import {createNetworkError} from 'utils/error';

const promocodes = actionNameCreator('promocodes');
export const [REDEEM, REDEEM_SUCCESS, REDEEM_FAIL] = createAsyncActionNames(promocodes('REDEEM'));

// send same request id for same readeem code for idempotent retries
let lastRedeemCode = null;
let globalRequestId = 0;
function getRequestId(code) {
  if (code !== lastRedeemCode) {
    lastRedeemCode = code;
    globalRequestId += 1;
  }
  return globalRequestId.toString();
}

export function redeemPromocode(code) {
  const requestId = getRequestId(code);
  const body = {code, requestId};

  return {
    types: [REDEEM, REDEEM_SUCCESS, REDEEM_FAIL],
    code,
    requestId,
    promise: (client, dispatch) =>
      client.api.post('/promocodes/redeem', {body}).then(
        ({
          language,
          currency,
          body: {
            payload: {reward},
          },
        }) => {
          // drop global request id
          lastRedeemCode = null;
          const {type} = reward;
          if (type === 'coupon' || type === 'couponV2') {
            enhanceLanguage(enhanceCurrency(reward.payload.coupon, currency), language);
            return reward;
          }
          throw createNetworkError('promocode.unkonwn_type');
        },
        (response) => {
          if (response.status < 500) {
            // drop global request id
            lastRedeemCode = null;
          }
          throw response;
        },
      ),
  };
}
