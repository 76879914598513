import {
  loadProductCollectionList,
  LoadProductCollectionListRequest,
  LoadProductCollectionListResponse,
} from 'store/modules/productCollections/productCollectionList/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

const initialState = getInitialState<
  LoadProductCollectionListRequest,
  LoadProductCollectionListResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadProductCollectionList.request, (state, {payload}) =>
    handleRequest(state, payload, Boolean(payload.pageToken)),
  )
  .handleAction(loadProductCollectionList.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: meta.pageToken ? [...(state.data?.items || []), ...payload.items] : payload.items,
    }),
  )
  .handleAction(loadProductCollectionList.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );
