import {produce} from 'immer';
import {
  addProductToCollection,
  editProductsInCollections,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {clearPost} from 'store/modules/social/posts/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

import {loadPostProducts, LoadPostProductsRequest, LoadPostProductsResponse} from './actions';

const initialState = getInitialState<LoadPostProductsRequest, LoadPostProductsResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadPostProducts.request, (state, {payload, payload: {pageToken}}) =>
    handleRequest(state, payload, Boolean(pageToken)),
  )
  .handleAction(loadPostProducts.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: [...(state.data?.items || []), ...payload.items],
    }),
  )
  .handleAction(loadPostProducts.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(clearPost, () => initialState)
  .handleAction(
    [addProductToCollection.success, removeProductFromCollection.success],
    (state, action) =>
      produce(state, (draft) => {
        draft.data?.items.forEach((product) => {
          if (product.id === action.meta.itemKey.productId) {
            product.favorite = isActionOf(addProductToCollection.success, action);
          }
        });
      }),
  )
  .handleAction(editProductsInCollections.success, (state, {payload}) =>
    produce(state, (draft) => {
      payload.collectionsUpdatedProductInfoList.forEach(({productId, isFavorite}) => {
        draft.data?.items.forEach((product) => {
          if (product.id === productId) {
            product.favorite = isFavorite;
          }
        });
      });
    }),
  );
