import order from './order';
import orderGroup from './orderGroup';
import product from './product';

export default {
  order,
  orderGroup,
  product,
};

export {
  isRelatedProductsProductLoading,
  isRelatedProductsProductLoaded,
  getRelatedProductsProductError,
  getRelatedProductsProduct,
  loadRelatedProductsProduct,
} from './product';

export {
  isRelatedProductsOrderLoading,
  isRelatedProductsOrderLoaded,
  getRelatedProductsOrderError,
  getRelatedProductsOrder,
  loadRelatedProductsOrder,
} from './order';

export {
  isRelatedProductsOrderGroupLoading,
  isRelatedProductsOrderGroupLoaded,
  getRelatedProductsOrderGroupError,
  getRelatedProductsOrderGroup,
  loadRelatedProductsOrderGroup,
} from './orderGroup';
