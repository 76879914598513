import {createRequestAction} from 'store/utils/requestActions';
import {VatInvoice} from 'types/Vat';

export type LoadVatInvoiceRequest = {
  orderGroupId: string;
};

export type LoadVatInvoiceResponse = VatInvoice;

export const loadInvoice = createRequestAction(
  '@vat/LOAD_INVOICE_REQUEST',
  '@vat/LOAD_INVOICE_SUCCESS',
  '@vat/LOAD_INVOICE_FAILURE',
)<LoadVatInvoiceRequest, LoadVatInvoiceResponse>();
