/* eslint-disable @typescript-eslint/no-explicit-any */
import {shouldPolyfill as shouldPolyfillGetCanonicalLocales} from '@formatjs/intl-getcanonicallocales/should-polyfill';
import {shouldPolyfill as shouldPolyfillListFormat} from '@formatjs/intl-listformat/should-polyfill';
import {shouldPolyfill as shouldPolyfillIntlLocale} from '@formatjs/intl-locale/should-polyfill';
import {shouldPolyfill as shouldPolyfillPluralRules} from '@formatjs/intl-pluralrules/should-polyfill';
import {shouldPolyfill as shouldPolyfillRelativeTimeFormat} from '@formatjs/intl-relativetimeformat/should-polyfill';
import {AppLanguageCode} from 'config';
import {Log} from 'helpers/log';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {Expandable} from 'types/utility';

// Can not use dynamic import because nodejs crashes
const importsByLanguage: Record<
  AppLanguageCode,
  [() => Promise<unknown>, () => Promise<unknown>, () => Promise<unknown>, () => Promise<unknown>]
> = {
  cs: [
    () => import('intl/locale-data/jsonp/cs'),
    () => import('@formatjs/intl-pluralrules/locale-data/cs'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/cs'),
    () => import('@formatjs/intl-listformat/locale-data/cs'),
  ],
  el: [
    () => import('intl/locale-data/jsonp/el'),
    () => import('@formatjs/intl-pluralrules/locale-data/el'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/el'),
    () => import('@formatjs/intl-listformat/locale-data/el'),
  ],
  nl: [
    () => import('intl/locale-data/jsonp/nl'),
    () => import('@formatjs/intl-pluralrules/locale-data/nl'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/nl'),
    () => import('@formatjs/intl-listformat/locale-data/nl'),
  ],
  nb: [
    () => import('intl/locale-data/jsonp/nb'),
    () => import('@formatjs/intl-pluralrules/locale-data/nb'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/nb'),
    () => import('@formatjs/intl-listformat/locale-data/nb'),
  ],
  kk: [
    () => import('intl/locale-data/jsonp/kk'),
    () => import('@formatjs/intl-pluralrules/locale-data/kk'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/kk'),
    () => import('@formatjs/intl-listformat/locale-data/kk'),
  ],
  ru: [
    () => import('intl/locale-data/jsonp/ru'),
    () => import('@formatjs/intl-pluralrules/locale-data/ru'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ru'),
    () => import('@formatjs/intl-listformat/locale-data/ru'),
  ],
  'ru-ua': [
    () => import('intl/locale-data/jsonp/ru'),
    () => import('@formatjs/intl-pluralrules/locale-data/ru'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ru'),
    () => import('@formatjs/intl-listformat/locale-data/ru'),
  ],
  lol: [
    () => import('intl/locale-data/jsonp/ru'),
    () => import('@formatjs/intl-pluralrules/locale-data/ru'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ru'),
    () => import('@formatjs/intl-listformat/locale-data/ru'),
  ],
  // tlh: [
  //   () => import('intl/locale-data/jsonp/ru'),
  //   () => import('@formatjs/intl-pluralrules/locale-data/ru'),
  //   () => import('@formatjs/intl-relativetimeformat/locale-data/ru'),
  //   () => import('@formatjs/intl-listformat/locale-data/ru'),
  // ],
  en: [
    () => import('intl/locale-data/jsonp/en'),
    () => import('@formatjs/intl-pluralrules/locale-data/en'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/en'),
    () => import('@formatjs/intl-listformat/locale-data/en'),
  ],
  es: [
    () => import('intl/locale-data/jsonp/es'),
    () => import('@formatjs/intl-pluralrules/locale-data/es'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/es'),
    () => import('@formatjs/intl-listformat/locale-data/es'),
  ],
  et: [
    () => import('intl/locale-data/jsonp/et'),
    () => import('@formatjs/intl-pluralrules/locale-data/et'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/et'),
    () => import('@formatjs/intl-listformat/locale-data/et'),
  ],
  de: [
    () => import('intl/locale-data/jsonp/de'),
    () => import('@formatjs/intl-pluralrules/locale-data/de'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/de'),
    () => import('@formatjs/intl-listformat/locale-data/de'),
  ],
  fr: [
    () => import('intl/locale-data/jsonp/fr'),
    () => import('@formatjs/intl-pluralrules/locale-data/fr'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/fr'),
    () => import('@formatjs/intl-listformat/locale-data/fr'),
  ],
  hu: [
    () => import('intl/locale-data/jsonp/hu'),
    () => import('@formatjs/intl-pluralrules/locale-data/hu'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/hu'),
    () => import('@formatjs/intl-listformat/locale-data/hu'),
  ],
  it: [
    () => import('intl/locale-data/jsonp/it'),
    () => import('@formatjs/intl-pluralrules/locale-data/it'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/it'),
    () => import('@formatjs/intl-listformat/locale-data/it'),
  ],
  lt: [
    () => import('intl/locale-data/jsonp/lt'),
    () => import('@formatjs/intl-pluralrules/locale-data/lt'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/lt'),
    () => import('@formatjs/intl-listformat/locale-data/lt'),
  ],
  lv: [
    () => import('intl/locale-data/jsonp/lv'),
    () => import('@formatjs/intl-pluralrules/locale-data/lv'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/lv'),
    () => import('@formatjs/intl-listformat/locale-data/lv'),
  ],
  pl: [
    () => import('intl/locale-data/jsonp/pl'),
    () => import('@formatjs/intl-pluralrules/locale-data/pl'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/pl'),
    () => import('@formatjs/intl-listformat/locale-data/pl'),
  ],
  pt: [
    () => import('intl/locale-data/jsonp/pt'),
    () => import('@formatjs/intl-pluralrules/locale-data/pt'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/pt'),
    () => import('@formatjs/intl-listformat/locale-data/pt'),
  ],
  'pt-br': [
    () => import('intl/locale-data/jsonp/br'),
    () => import('@formatjs/intl-pluralrules/locale-data/br'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/br'),
    () => import('@formatjs/intl-listformat/locale-data/br'),
  ],
  ro: [
    () => import('intl/locale-data/jsonp/ro'),
    () => import('@formatjs/intl-pluralrules/locale-data/ro'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ro'),
    () => import('@formatjs/intl-listformat/locale-data/ro'),
  ],
  sv: [
    () => import('intl/locale-data/jsonp/sv'),
    () => import('@formatjs/intl-pluralrules/locale-data/sv'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/sv'),
    () => import('@formatjs/intl-listformat/locale-data/sv'),
  ],
  tr: [
    () => import('intl/locale-data/jsonp/tr'),
    () => import('@formatjs/intl-pluralrules/locale-data/tr'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/tr'),
    () => import('@formatjs/intl-listformat/locale-data/tr'),
  ],
  uk: [
    () => import('intl/locale-data/jsonp/uk'),
    () => import('@formatjs/intl-pluralrules/locale-data/uk'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/uk'),
    () => import('@formatjs/intl-listformat/locale-data/uk'),
  ],
  ka: [
    () => import('intl/locale-data/jsonp/ka'),
    () => import('@formatjs/intl-pluralrules/locale-data/ka'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ka'),
    () => import('@formatjs/intl-listformat/locale-data/ka'),
  ],
  he: [
    () => import('intl/locale-data/jsonp/he'),
    () => import('@formatjs/intl-pluralrules/locale-data/he'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/he'),
    () => import('@formatjs/intl-listformat/locale-data/he'),
  ],
  ar: [
    () => import('intl/locale-data/jsonp/ar'),
    () => import('@formatjs/intl-pluralrules/locale-data/ar'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ar'),
    () => import('@formatjs/intl-listformat/locale-data/ar'),
  ],
  sk: [
    () => import('intl/locale-data/jsonp/sk'),
    () => import('@formatjs/intl-pluralrules/locale-data/sk'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/sk'),
    () => import('@formatjs/intl-listformat/locale-data/sk'),
  ],
  ja: [
    () => import('intl/locale-data/jsonp/ja'),
    () => import('@formatjs/intl-pluralrules/locale-data/ja'),
    () => import('@formatjs/intl-relativetimeformat/locale-data/ja'),
    () => import('@formatjs/intl-listformat/locale-data/ja'),
  ],
};

async function polyfillPluralRules(dataLoader: () => Promise<unknown>): Promise<void> {
  if (shouldPolyfillPluralRules()) {
    await import('@formatjs/intl-pluralrules/polyfill');
  }

  if ((Intl as any)?.PluralRules?.polyfilled) {
    await dataLoader();
  }
}

async function polyfillRelativeTimeFormat(dataLoader: () => Promise<unknown>): Promise<void> {
  if (shouldPolyfillRelativeTimeFormat()) {
    await import('@formatjs/intl-relativetimeformat/polyfill');
  }

  if ((Intl as any)?.RelativeTimeFormat?.polyfilled) {
    await dataLoader();
  }
}

async function polyfillListFormat(dataLoader: () => Promise<unknown>): Promise<void> {
  if (shouldPolyfillListFormat()) {
    await import('@formatjs/intl-listformat/polyfill');
  }

  if ((Intl as any)?.ListFormat?.polyfilled) {
    await dataLoader();
  }
}

export async function polyfillIntl(language: string, log: Log): Promise<void> {
  const logger = log.getLogger('helpers/locale');
  const imports = (importsByLanguage as Expandable<typeof importsByLanguage>)[language];
  if (!imports) {
    throw new Error(`I18n polyfills for ${language} are not defined`);
  }

  const [intlData, pluralRulesData, relativeTimeFormatData, listFormatData] = imports;

  if (!global.Intl) {
    await import('intl');
    await intlData();
  }

  if (shouldPolyfillGetCanonicalLocales()) {
    await import('@formatjs/intl-getcanonicallocales/polyfill');
  }

  if (shouldPolyfillIntlLocale()) {
    await import('@formatjs/intl-locale/polyfill');
  }

  await Promise.all([
    polyfillPluralRules(pluralRulesData),
    polyfillRelativeTimeFormat(relativeTimeFormatData),
    polyfillListFormat(listFormatData),
  ]).catch((ex) => logger.error({error: ecsError(ex)}));
}
