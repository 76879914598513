import {RootState} from 'store/rootReducer';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {getSelectors} from 'store/utils/reducerCreators/simpleMapped/selectors';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {createSelectorFactory} from 'store/utils/selectors';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {ProductReviewFilters} from 'types/ProductReviews';
import {SocialPostFilterId} from 'types/SocialPost';
import {createReducer, FuncAction} from 'typesafe-actions';

type LoadProductReviewFiltersRequest = {productId: string};

type LoadProductReviewFiltersResponse = ProductReviewFilters;

export const loadProductReviewFiltersAction = createRequestAction(
  'productReviewFilters/LOAD',
  'productReviewFilters/LOAD_SUCCESS',
  'productReviewFilters/LOAD_FAIL',
)<LoadProductReviewFiltersRequest, LoadProductReviewFiltersResponse>();

const initialState = getInitialState<
  LoadProductReviewFiltersRequest,
  LoadProductReviewFiltersResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadProductReviewFiltersAction.request, (state, {payload}) =>
    handleRequest(state, payload.productId, payload, true),
  )
  .handleAction(loadProductReviewFiltersAction.success, (state, {meta, payload}) =>
    handleSuccess(state, meta.productId, meta, payload),
  )
  .handleAction(loadProductReviewFiltersAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta.productId, meta, payload),
  );

export const {
  getData: getProductReviewFilters,
  isLoading: isProductReviewFiltersLoading,
  isLoaded: isProductReviewFiltersLoaded,
  getRequest: getProductReviewFiltersRequest,
  getError: getProductReviewFiltersError,
} = getSelectors(createSelectorFactory((state) => state.productReviewFilters));

export function loadProductReviewFilters(productId: string): FuncAction {
  return requestActionCreator(loadProductReviewFiltersAction, {productId}, (store, client) =>
    client.api
      .get<
        ClientBackendResponse<{items: LoadProductReviewFiltersResponse}>
      >(`/products/${productId}/reviews/filters`)
      .then(({body: {payload}}) => payload.items),
  );
}

export const getProductReviewsCount = (state: RootState, productId: string): number =>
  getProductReviewFilters(state, productId)?.find((filter) => filter.id === SocialPostFilterId.ALL)
    ?.count?.value ?? 0;

export const hasProductReviews = (state: RootState, productId: string): boolean =>
  Boolean(getProductReviewsCount(state, productId));
