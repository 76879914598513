import {createRequestAction} from 'store/utils/requestActions';
import {RewardWheelState} from 'types/RewardWheel';
import {createAction} from 'typesafe-actions';

export type MarkRewardWheelAsSeenRequest = {
  state: RewardWheelState;
};
export type MarkRewardWheelAsSeenResponse = unknown;

export const markRewardWheelAsSeenAction = createRequestAction(
  '@rewardWheel/MARK_AS_SEEN_REQUEST',
  '@rewardWheel/MARK_AS_SEEN_SUCCESS',
  '@rewardWheel/MARK_AS_SEEN_FAILURE',
)<MarkRewardWheelAsSeenRequest, MarkRewardWheelAsSeenResponse>();

export const discardRewardWheel = createAction('@rewardWheel/DISCARD')();
