const LOAD = 'favoriteCategoryPromo/LOAD';
const LOAD_SUCCESS = 'favoriteCategoryPromo/LOAD_SUCCESS';
const LOAD_FAIL = 'favoriteCategoryPromo/LOAD_FAIL';
const SAVE = 'favoriteCategoryPromo/SAVE';
const SAVE_SUCCESS = 'favoriteCategoryPromo/SAVE_SUCCESS';
const SAVE_FAIL = 'favoriteCategoryPromo/SAVE_FAIL';

const RESET = 'favoriteCategoryPromo/RESET';

const initialState = {
  categoryId: null,

  data: {},
  loaded: false,
  loading: false,
  loadingError: null,

  saved: false,
  saving: false,
  savingError: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loaded: false,
        loading: true,
        loadingError: null,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        data: action.result,
        loaded: true,
        loading: false,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loaded: false,
        loading: false,
        loadingError: action.error,
      };

    case SAVE:
      return {
        ...state,
        savingError: null,
        saved: false,
        saving: true,
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        categoryId: action.result.categoryIds[0],
        saved: true,
        saving: false,
      };

    case SAVE_FAIL:
      return {
        ...state,
        categoryId: null,
        savingError: action.error,
        saved: false,
        saving: false,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

const getState = (globalState) => globalState.favoriteCategoryPromo;

export const isLoaded = (globalState) => {
  const state = getState(globalState);

  return state.loaded;
};
export const isLoading = (globalState) => getState(globalState).loading;
export const isSaved = (globalState) => getState(globalState).saved;
export const isSaving = (globalState) => getState(globalState).saving;
export const getSavingError = (globalState) => getState(globalState).savingError;
export const getCategoryId = (globalState) => getState(globalState).categoryId;
export const getPromo = (globalState) => getState(globalState).data.promo;
export const canLoadPromo = (globalState) => !isLoading(globalState) && !isLoaded(globalState);

export function loadPromo(orderGroupId) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api
        .post(`/orderGroups/${orderGroupId}/favoriteCategoryPromo`)
        .then(({language, currency, body: {payload}}) => ({
          currency,
          language,
          promo: payload.favoriteCategoryPromo,
        })),
  };
}

export function saveFavoriteCategory(categoryIds) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client) =>
      client.api
        .post('/favoriteCategoryPromo/accept', {body: {categoryIds}})
        .then(() => ({categoryIds})),
  };
}

export function resetFavoriteCategoryState() {
  return {
    type: RESET,
  };
}
