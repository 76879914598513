import {createRequestAction} from 'store/utils/requestActions';
import {AttributeSizeTable as SizeTable} from 'types/AttributeSizeTable';

export type LoadProductSizeTableRequest = {productId: string};

export type LoadProductSizeTableResponse = SizeTable;

export const loadProductSizeTableAction = createRequestAction(
  'productSizeTable/LOAD_REQUEST',
  'productSizeTable/LOAD_SUCCESS',
  'productSizeTable/LOAD_FAIL',
)<LoadProductSizeTableRequest, LoadProductSizeTableResponse>();
