import {START_PAGE_TOKEN} from 'store/consts';
import {appendContentListItems} from 'store/enhancer/contentList';
import {ContentListWithCount} from 'types/ContentList';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';
import {assignSet} from 'utils/object';

import {load} from './actions';

type State = {
  error: Error | null;
  itemsData: ContentListWithCount | null;
  loaded: {[key: string]: boolean};
  loading: boolean;
  meta: {
    currency?: string;
    language?: string;
  };
  nextPageToken: string;
};

const initialState: State = {
  error: null,
  itemsData: null,
  loaded: {},
  loading: false,
  meta: {},
  nextPageToken: '',
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload: {pageToken}}) => ({
    ...state,
    itemsData: pageToken ? state.itemsData : null,
    error: null,
    loaded: pageToken ? state.loaded : {},
    loading: true,
  }))
  .handleAction(
    load.success,
    (state, {payload: {items, nextPageToken, currency, language}, meta: {pageToken}}) => ({
      ...state,
      itemsData: appendContentListItems(
        state.itemsData,
        items,
        pageToken === state.nextPageToken,
        !nextPageToken,
      ),
      loaded: assignSet(state.loaded, pageToken || START_PAGE_TOKEN, true),
      loading: false,
      meta: {
        currency,
        language,
      },
      nextPageToken,
    }),
  )
  .handleAction(load.failure, (state, {payload}) => ({
    ...state,
    error: payload,
    loading: false,
  }));
