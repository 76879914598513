import {discardableByUser} from 'helpers/discardable';
import {assignSetByPath, getValueByPath} from 'utils/object';
import {getServerTimestamp} from 'helpers/serverTime';
import {enhanceProducts} from 'store/utils/enhancers/enhanceProducts';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {wrapProductsInContentList} from 'store/enhancer/contentList';
import {GuessPriceState} from 'shapes/GuessPriceSession';

const GUESS = 'games/guessPrice/GUESS';
const GUESS_FAIL = 'games/guessPrice/GUESS_FAIL';
const GUESS_SUCCESS = 'games/guessPrice/GUESS_SUCCESS';

const LOAD = 'games/guessPrice/LOAD';
const LOAD_FAIL = 'games/guessPrice/LOAD_FAIL';
const LOAD_SUCCESS = 'games/guessPrice/LOAD_SUCCESS';

const initialState = {
  data: {},
  current: null,
  error: null,
  guessError: null,
  guessing: false,
  loaded: false,
  loading: false,
};

function getSessionFromAction(state, action) {
  const {actualPrice, session} = action.result;

  if (actualPrice && session && session.items) {
    let found = false;
    const items = session.items.map((item) => {
      found = found || item.id !== action.itemId;
      return item.id !== action.itemId
        ? item
        : {
            ...item,
            actualPrice,
          };
    });

    if (!found && state.data && state.data.items) {
      const item = state.data.items.find(({id}) => id === action.itemId);
      if (item) {
        items.unshift({
          ...item,
          state: GuessPriceState.LOST,
          actualPrice,
        });
      }
    }

    return {
      ...session,
      items,
    };
  }

  return session;
}

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loading: true,
        loaded: false,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: getSessionFromAction(state, action),
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    case GUESS:
      return {
        ...state,
        guessError: null,
        guessing: true,
      };
    case GUESS_SUCCESS:
      return {
        ...state,
        data: getSessionFromAction(state, action),
        guessing: false,
      };
    case GUESS_FAIL:
      return {
        ...state,
        guessError: action.error,
        guessing: false,
      };
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isGuessPriceSessionExpired(session) {
  const expiration = session.loadedTimeMs + session.timeRemainingMs;
  return getServerTimestamp() > expiration;
}

export function isGuessPriceSessionLoaded(globalState) {
  const state = globalState.games.guessPriceGame;
  if (state.loaded) {
    return !isGuessPriceSessionExpired(state.data);
  }
  return false;
}

export function isGuessPriceSessionLoading(globalState) {
  return globalState.games.guessPriceGame.loading;
}

export function isGuessingPrice(globalState) {
  return globalState.games.guessPriceGame.guessing;
}

export function getGuessPriceSession(globalState) {
  return isGuessPriceSessionLoaded(globalState) ? globalState.games.guessPriceGame.data : null;
}

export function getGuessPriceError(globalState) {
  return globalState.games.guessPriceGame.error;
}

export function getGuessingError(globalState) {
  return globalState.games.guessPriceGame.guessError;
}

export const REWARD_PRODUCTS_PATH = ['payload', 'products', 'items'];

function parseResponseSession(currency, language, json, contexts) {
  const {reward} = json;
  return enhanceLoadedTime({
    ...json,
    language,
    currency,
    reward:
      reward && reward.type === 'products'
        ? assignSetByPath(
            reward,
            REWARD_PRODUCTS_PATH,
            wrapProductsInContentList(
              enhanceProducts(getValueByPath(reward, ...REWARD_PRODUCTS_PATH), {
                language,
                currency,
                contexts,
              }),
            ),
          )
        : json.reward,
  });
}

function getCurrentSession(client) {
  return client.api
    .get('/games/guessPrice/currentSession')
    .then(({currency, language, body: {contexts, payload}}) => ({
      session: parseResponseSession(currency, language, payload, contexts),
    }));
}

// NB! DO NOT LOAD GAME SESSION FOR EPHEMERAL USERS!
export function loadGuessPriceSession() {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => getCurrentSession(client),
  };
}

export function guessPrice(id, itemId, price) {
  const body = {itemId, price};

  return {
    types: [GUESS, GUESS_SUCCESS, GUESS_FAIL],
    id,
    itemId,
    price,
    promise: (client) =>
      client.api
        .post(`/games/guessPrice/sessions/${id}/guess`, {body})
        .then(({currency, language, body: {contexts, payload}}) => ({
          ...payload,
          session: parseResponseSession(currency, language, payload.session, contexts),
        }))
        .catch((resoponse) => {
          const {error} = resoponse;
          if (!error || error.status < 400 || error.status >= 500) {
            throw resoponse;
          }
          return getCurrentSession(client);
        }),
  };
}
