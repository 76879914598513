import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {load, LoadRequest, LoadResponse} from './actions';

const initialState = getInitialState<LoadRequest, LoadResponse>();

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload}) => handleRequest(state, payload, true))
  .handleAction(load.success, (state, {payload, meta}) => handleSuccess(state, meta, payload))
  .handleAction(load.failure, (state, {payload, meta}) => handleFailure(state, meta, payload));
