const LOAD = 'invites/LOAD';
const LOAD_SUCCESS = 'invites/LOAD_SUCCESS';
const LOAD_FAIL = 'invites/LOAD_FAIL';

const initialState = {
  url: null,
  token: null,
  loading: false,
  error: null,
  loaded: false,
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        token: action.token,
        error: null,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        token: action.token,
        url: action.result.url,
        error: null,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export function isLoaded(globalState, inviteToken) {
  const {token, loaded} = globalState.invites;
  return !!loaded && token === inviteToken;
}

export function load(inviteToken) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    token: inviteToken,
    promise: (client) =>
      client.api
        .get(`/invites/${inviteToken}/link`)
        .then(({language, currency, body: {payload}}) => ({
          ...payload,
          currency,
          language,
        })),
  };
}
