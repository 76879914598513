import {createRequestAction} from 'store/utils/requestActions';
import {SocialPost} from 'types/SocialPost';
import {createAction} from 'typesafe-actions';

export type LoadSocialUserPostsRequest = {
  userId: string;
  pageToken?: string;
};

export type LoadSocialUserPostsResponse = {
  items: SocialPost[];
  nextPageToken?: string;
};

export const loadSocialUserPosts = createRequestAction(
  '@socialUserPosts/LOAD_REQUEST',
  '@socialUserPosts/LOAD_SUCCESS',
  '@socialUserPosts/LOAD_FAILURE',
)<LoadSocialUserPostsRequest, LoadSocialUserPostsResponse>();

export const clearSocialUserPosts =
  createAction('@socialUserPosts/CLEAR')<LoadSocialUserPostsRequest>();
