import promoSubscription from './promoSubscription';

export default {
  promoSubscription,
};

export {
  cleanEmailPromoSubscription,
  isEmailPromoSubscriptionLoaded,
  isEmailPromoSubscriptionLoading,
  getEmailPromoSubscriptionError,
  hasEmailPromoSubscription,
  loadEmailPromoSubscription,
} from './promoSubscription';
