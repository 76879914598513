import {FavoriteTab} from 'types/FavoriteTab';
import {isNumber, isString} from 'utils/guards';

export function assignFavoriteTabCounterOffset(tab: FavoriteTab, offset: number): FavoriteTab {
  if (isNumber(tab?.itemCount.value) && isString(tab?.itemCount.text)) {
    tab.itemCount.value = Math.max(tab.itemCount.value + offset, 0);
    tab.itemCount.text = String(tab.itemCount.value);
  }

  return tab;
}
