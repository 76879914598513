import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';
import {unwrapErrorObject} from 'utils/error/unwrapErrorObject';

import {load, LoadRequest, LoadResponse} from './actions';

const initialState = getInitialState<LoadRequest, LoadResponse>();

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state) => handleRequest(state, {}))
  .handleAction(load.success, (state, {meta, payload}) => handleSuccess(state, meta, payload))
  .handleAction(load.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, unwrapErrorObject(payload)),
  );
