import {enhancePointsAccount, setPointsAccount} from 'store/modules/points/account';
import {getErrorType} from 'utils/error';
import {getValueByPath} from 'utils/object';

function getPayload(response, key = null) {
  const payload = getValueByPath(response, 'body', 'payload');
  if (!payload) {
    return undefined;
  }
  return key ? payload[key] : payload;
}

export function handleClientErrorResponse(dispatch, getState, response, params) {
  switch (getErrorType(response)) {
    case 'order.insufficient_point_balance': {
      const account = getPayload(response, 'account');
      if (account) {
        return dispatch(setPointsAccount(enhancePointsAccount(response, () => account)));
      }
      break;
    }

    case 'cart.leave_discount.expired':
      // TODO: implement QuitDiscount
      // https://github.com/joomcode/joom-android/blob/ba521af0ae056d8a21bfa1357593f15cea1e86d1/joom/app/src/main/java/com/joom/core/domain/RemoteError.kt#L32
      break;

    case 'payments.cardincorrect_number':
    case 'payments.cardinsufficient_funds':
    case 'payments.cardtoken_expired':
      // TODO: implement Payments
      // https://github.com/joomcode/joom-android/blob/ba521af0ae056d8a21bfa1357593f15cea1e86d1/joom/app/src/main/java/com/joom/core/domain/RemoteError.kt#L100
      break;

    default:
      break;
  }

  return null;
}
