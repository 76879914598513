import {ContentList} from 'components/ContentList';
import {Image} from 'components/Image';
import {Text} from 'components/Text';
import {Popup} from 'components/UIKit/Popup';
import {StandaloneControls as PopupStandaloneControls} from 'components/UIKit/Popup/Header/StandaloneControls';
import {PopupView, PopupViewProps} from 'components/UIKit/Popup/PopupProvider';
import React from 'react';
import {ContentListScreen, ContentListScreenHeader} from 'types/ContentListScreen';

import styles from './index.scss';

type PaymentRequisitesPopupHeaderProps = {
  header: ContentListScreenHeader;
};

const HEADER_ICON_PX_FIT = 80;
const HEADER_ICON_VW_FIT = {
  sm: 64,
  md: 80,
};

export function PaymentRequisitesPopupHeader({
  header,
}: PaymentRequisitesPopupHeaderProps): JSX.Element {
  const icon = header.iconImage ?? header.largeIcon;

  return (
    <div className={styles.header}>
      {icon && (
        <Image
          className={styles.headerIcon}
          contain
          image={icon}
          pxFit={HEADER_ICON_PX_FIT}
          vwFit={HEADER_ICON_VW_FIT}
        />
      )}
      {header.title && <Text className={styles.headerTitle} text={header.title} />}
      {header.subtitle && <Text className={styles.headerSubtitle} text={header.subtitle} />}
    </div>
  );
}

type PaymentRequisitesPopupProps = {
  screen: ContentListScreen;
};

const POPUP_WIDTH = '480px';

export function PaymentRequisitesPopup({
  onBack,
  onClose,
  screen,
}: PaymentRequisitesPopupProps & PopupViewProps): JSX.Element {
  return (
    <Popup width={POPUP_WIDTH}>
      <PopupStandaloneControls onClose={onClose} onBack={onBack} sticky />
      <div className={styles.inner} data-scrolllockignore>
        {screen.header && <PaymentRequisitesPopupHeader header={screen.header} />}
        <div className={styles.content}>
          <ContentList items={screen.items} />
        </div>
      </div>
    </Popup>
  );
}

export function createPaymentRequisitesPopupView({screen}: PaymentRequisitesPopupProps): PopupView {
  return (popupViewProps: PopupViewProps) => (
    <PaymentRequisitesPopup {...popupViewProps} screen={screen} />
  );
}
