import {actionNameCreator} from 'store/utils';

const actionsNs = actionNameCreator('deliveryPoints/common');

const SET_MAP_CENTER = actionsNs('SET_MAP_CENTER');
const SET_MAP_ZOOM = actionsNs('SET_MAP_ZOOM');
const SET_CHECKOUT_GROUP_ID = actionsNs('SET_CHECKOUT_GROUP_ID');
const SET_INITIALIZING = actionsNs('SET_INITIALIZING');
const SET_INITIALIZING_ERROR = actionsNs('SET_INITIALIZING_ERROR');
const RESET = actionsNs('RESET');
const SET_APPEARANCE = actionsNs('SET_APPEARANCE');

const initialState = {
  initializing: false,
  initializingError: null,
  checkoutGroupId: null,
  mapCenter: null,
  mapZoom: null,
  appearance: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET_MAP_CENTER:
      return {
        ...state,
        mapCenter: {
          value: action.coordinates,
        },
      };

    case SET_MAP_ZOOM:
      return {
        ...state,
        mapZoom: {
          value: action.zoom,
          minBounds: action.minBounds || null,
        },
      };

    case SET_CHECKOUT_GROUP_ID:
      return {
        ...state,
        checkoutGroupId: action.checkoutGroupId,
      };

    case SET_INITIALIZING: {
      const {initializing} = action;

      return {
        ...state,
        initializing,
      };
    }

    case SET_INITIALIZING_ERROR:
      return {
        ...state,
        initializingError: action.error,
      };

    case SET_APPEARANCE:
      return {
        ...state,
        appearance: action.appearance,
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

const getCommonState = (globalState) => globalState.deliveryPoints.common;

export const getInitializing = (globalState) => getCommonState(globalState).initializing;

export const getInitializingError = (globalState) => getCommonState(globalState).initializingError;

export const getMapCenter = (globalState) => getCommonState(globalState).mapCenter;

export const getMapZoom = (globalStage) => getCommonState(globalStage).mapZoom;

export const getCheckoutGroupId = (globalState) => getCommonState(globalState).checkoutGroupId;

export const getAppearance = (globalState) => getCommonState(globalState).appearance;

export const enhanceCheckoutGroupId = (globalState, data) => ({
  ...data,
  checkoutGroupId: getCheckoutGroupId(globalState),
});

export function setMapCenter(coordinates) {
  return {
    type: SET_MAP_CENTER,
    coordinates,
  };
}

export function setMapZoom(zoom, minBounds) {
  return {
    type: SET_MAP_ZOOM,
    zoom,
    minBounds,
  };
}

export function setCheckoutGroupId(checkoutGroupId) {
  return {
    type: SET_CHECKOUT_GROUP_ID,
    checkoutGroupId,
  };
}

export function setInitializing(initializing) {
  return {
    type: SET_INITIALIZING,
    initializing,
  };
}

export function setInitializingError(error) {
  return {
    type: SET_INITIALIZING_ERROR,
    error,
  };
}

export function resetCommon() {
  return {
    type: RESET,
  };
}

export function setAppearance(appearance) {
  return {
    type: SET_APPEARANCE,
    appearance,
  };
}
