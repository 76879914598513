import {combineReducers} from 'utils/combineReducers';

import {reducer as products} from './products';
import {reducer as referral} from './referral';

export const reducer = combineReducers({
  referral,
  products,
});

export {
  getShareReferral,
  getShareReferralError,
  isShareReferralLoaded,
  isShareReferralLoading,
  loadShareReferral,
} from './referral';

export {
  getShareProduct,
  getShareProductRequest,
  getShareProductError,
  isShareProductLoaded,
  isShareProductLoading,
  loadShareProduct,
} from './products';
