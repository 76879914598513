import {produce} from 'immer';
import {handleContentListProducts} from 'store/enhancer/contentList';
import {
  deleteProductCollection,
  editProductsInCollections,
  updateProductCollection,
} from 'store/modules/productCollections/actions';
import {
  loadProductCollection,
  LoadProductCollectionRequest,
  LoadProductCollectionResponse,
} from 'store/modules/productCollections/productCollection/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

const initialState = getInitialState<LoadProductCollectionRequest, LoadProductCollectionResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadProductCollection.request, (state, {payload}) =>
    handleRequest(
      state,
      payload,
      Boolean(payload.pageToken) &&
        payload.productCollectionId === state.request?.productCollectionId,
    ),
  )
  .handleAction(loadProductCollection.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, {
      ...payload,
      ...(meta.pageToken ? state.data : undefined),
      nextPageToken: payload.nextPageToken,
      items: meta.pageToken
        ? [...(state.data?.items || []), ...(payload.items || [])]
        : payload.items,
    }),
  )
  .handleAction(loadProductCollection.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(editProductsInCollections.success, (state, {meta}) =>
    produce(state, (draft) => {
      meta.updates.forEach(({productCollectionId, itemKey, isRemoving}) => {
        if (productCollectionId === draft.data?.header.productCollectionLite.id) {
          handleContentListProducts(draft.data?.items, (product) => {
            if (product.id === itemKey.productId) {
              product.favorite = !isRemoving;
            }
          });
        }
      });
    }),
  )
  .handleAction(updateProductCollection.success, (state, {payload}) => {
    if (
      payload.productCollection.header.productCollectionLite.id ===
      state.data?.header.productCollectionLite.id
    ) {
      return produce(state, (draft) => {
        draft.data = {
          ...payload.productCollection,
          items: draft.data?.items || [],
        };
      });
    }

    return state;
  })
  .handleAction(deleteProductCollection.success, (state, {meta}) => {
    if (meta.productCollectionId === state.data?.header.productCollectionLite.id) {
      return initialState;
    }

    return state;
  });
