import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {loadArticle, LoadArticleRequest, LoadArticleResponse} from './actions';

const initialState = getInitialState<LoadArticleRequest, LoadArticleResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadArticle.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadArticle.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadArticle.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );
