import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';
import {ContentListScreenFooter, ContentListScreenHeader} from 'types/ContentListScreen';

export type ContentListScreenResponse = {
  header: ContentListScreenHeader;
  footer: ContentListScreenFooter;
  items: ContentListItem[];
  nextPageToken: string;
};

export const loadContentListScreen = createRequestAction(
  '@contentListScreen/LOAD_REQUEST',
  '@contentListScreen/LOAD_SUCCESS',
  '@contentListScreen/LOAD_FAILURE',
)<undefined, ContentListScreenResponse>();
