import {produce} from 'immer';
import {followSocialUser, unfollowSocialUser} from 'store/modules/social/subscribe/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

import {
  clearSocialUserFollowing,
  loadSocialUserFollowing,
  LoadSocialUserFollowingRequest,
  LoadSocialUserFollowingResponse,
} from './actions';

const initialState = getInitialState<
  LoadSocialUserFollowingRequest,
  LoadSocialUserFollowingResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadSocialUserFollowing.request, (state, {payload}) =>
    handleRequest(state, payload, Boolean(payload.pageToken)),
  )
  .handleAction(loadSocialUserFollowing.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: [...(state.data?.items || []), ...payload.items],
    }),
  )
  .handleAction(loadSocialUserFollowing.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(clearSocialUserFollowing, () => initialState)
  .handleAction([followSocialUser.success, unfollowSocialUser.success], (state, action) =>
    produce(state, (draft) => {
      const {userId} = action.meta;

      const isFollowing = isActionOf(followSocialUser.success, action);

      draft?.data?.items.forEach((item) => {
        if (item.id === userId) {
          item.isFollowing = isFollowing;
        }
      });
    }),
  );
