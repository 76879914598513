import {produce} from 'immer';
import {
  clearProductCollectionSelection,
  startProductCollectionSelection,
  updateProductCollectionSelection,
} from 'store/modules/productCollections/productCollectionSelection/actions';
import {CommonProduct, getCommonProductId} from 'types/CommonProduct';
import {createReducer} from 'typesafe-actions';

type ProductCollectionSelectionState = {
  data: Record<string, CommonProduct> | null;
  selection: boolean;
};

const initialState: ProductCollectionSelectionState = {
  data: null,
  selection: false,
};

export const reducer = createReducer(initialState)
  .handleAction(clearProductCollectionSelection, (state) =>
    produce(state, (draft) => {
      draft.selection = false;
      draft.data = null;
    }),
  )
  .handleAction(startProductCollectionSelection, (state) =>
    produce(state, (draft) => {
      draft.selection = true;
      draft.data = {};
    }),
  )
  .handleAction(updateProductCollectionSelection, (state, {payload}) =>
    produce(state, (draft) => {
      draft.selection = true;
      draft.data = draft.data ?? {};

      const productId = getCommonProductId(payload.product);

      if (payload.selected) {
        draft.data[productId] = payload.product;
      } else {
        delete draft.data[productId];
      }
    }),
  );
