import {produce} from 'immer';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {discardRewardWheel} from '../actions';
import {activateRewardWheelAction} from '../activation/actions';
import {getRewardWheelAction, getRewardWheelRequest, GetRewardWheelResponse} from './actions';

const initialState = getInitialState<getRewardWheelRequest, GetRewardWheelResponse>();

export const reducer = createReducer(initialState)
  .handleAction(getRewardWheelAction.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(getRewardWheelAction.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(getRewardWheelAction.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(activateRewardWheelAction.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.data = payload.wheel;
    }),
  )
  .handleAction(discardRewardWheel, () => initialState);
