import {START_PAGE_TOKEN} from 'store/consts';
import {appendContentListItems} from 'store/enhancer/contentList';
import {ContentListWithCount} from 'types/ContentList';
import {Error} from 'types/Error';
import {Gradient} from 'types/Gradient';
import {ProductStore} from 'types/ProductStore';
import {createReducer} from 'typesafe-actions';
import {assignSet} from 'utils/object';

import {addStoreToFavorites, loadStore, removeStoreFromFavorites, updateStore} from './actions';

type State = {
  error: Error | null;
  store: ProductStore | null;
  header?: unknown;
  footer?: {
    button: {
      startText: {
        text: string;
      };
      endContent: unknown;
      background: Gradient;
      deeplink: string;
      eventParams: string;
    };
  };
  itemsData: ContentListWithCount | null;
  loaded: {[key: string]: boolean};
  loading: boolean;
  meta: {
    currency?: string;
    language?: string;
  };
  nextPageToken: string;
};

const initialState: State = {
  error: null,
  itemsData: null,
  loaded: {},
  loading: false,
  meta: {},
  nextPageToken: '',
  store: null,
};

export const reducer = createReducer(initialState)
  .handleAction(loadStore.request, (state, {payload: {pageToken}}) => ({
    ...state,
    itemsData: pageToken ? state.itemsData : null,
    error: null,
    loaded: pageToken ? state.loaded : {},
    loading: true,
  }))
  .handleAction(
    loadStore.success,
    (
      state,
      {payload: {lite, header, footer, items = [], nextPageToken = '', currency, language}, meta},
    ) => ({
      ...state,
      store: lite,
      header,
      footer,
      itemsData: appendContentListItems(
        state.itemsData,
        items,
        meta?.pageToken === state.nextPageToken,
        !nextPageToken,
      ),
      loaded: assignSet(state.loaded, meta?.pageToken || START_PAGE_TOKEN, true),
      loading: false,
      meta: {
        currency,
        language,
      },
      nextPageToken,
    }),
  )
  .handleAction(updateStore, (state, {payload: {lite, header, footer}}) => ({
    ...state,
    store: lite,
    header,
    footer,
  }))
  .handleAction(
    [loadStore.failure, addStoreToFavorites.failure, removeStoreFromFavorites.failure],
    (state, {payload}) => ({
      ...state,
      error: payload,
      loading: false,
    }),
  );
