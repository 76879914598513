import {AnyLink} from 'components/AnyLink';
import {Image} from 'components/Image';
import React from 'react';
import {RowList as RowListType} from 'types/ContentList/ContentListRowList';

import styles from './styles.scss';

const RowListItem = ({
  item,
}: {
  item?: RowListType['rowList']['items'][number];
}): JSX.Element | null => {
  if (!item) {
    return null;
  }

  const {content} = item;

  return (
    <AnyLink className={styles.rowListItem} to={item.url}>
      <div className={styles.title}>{item.title}</div>
      {content?.caption?.endIcon ? (
        <Image
          className={styles.endIcon}
          image={content.caption.endIcon}
          width="24px"
          height="24px"
        />
      ) : null}
    </AnyLink>
  );
};

export const RowList = ({rowList: {rowList}}: {rowList: RowListType}): JSX.Element => {
  const {items} = rowList;
  return (
    <div className={styles.rowList}>
      {items.map((item, index) => {
        // eslint-disable-next-line react/no-array-index-key
        return <RowListItem item={item} key={index} />;
      })}
    </div>
  );
};
