import {requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {ContentListScreenOrigin} from 'types/ContentListScreen';
import {FuncAction} from 'typesafe-actions';

import {
  ContentListScreenResponse,
  loadContentListScreen as loadContentListScreenAction,
} from './actions';

export const loadContentListScreen = (origin: ContentListScreenOrigin): FuncAction =>
  requestActionCreator(loadContentListScreenAction, {}, (store, client) => {
    return client.api
      .post<ClientBackendResponse<ContentListScreenResponse>>('/contentList/screen/get', {
        body: {origin},
      })
      .then(({body: {payload}}) => payload);
  });
