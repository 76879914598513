import {
  loadPremiumSeo,
  LoadPremiumSeoRequest,
  LoadPremiumSeoResponse,
} from 'store/modules/premiumSeo/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

const initialState = getInitialState<LoadPremiumSeoRequest, LoadPremiumSeoResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadPremiumSeo.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadPremiumSeo.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPremiumSeo.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );
