import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';
import {RewardWheelAvailability} from 'types/RewardWheel';
import {createAction} from 'typesafe-actions';

export type GetRewardWheelAvailabilityRequest = EmptyObject;
export type GetRewardWheelAvailabilityResponse = RewardWheelAvailability;

export const getRewardWheelAvailabilityAction = createRequestAction(
  '@rewardWheel/GET_AVAILABILITY_REQUEST',
  '@rewardWheel/GET_AVAILABILITY_SUCCESS',
  '@rewardWheel/GET_AVAILABILITY_FAILURE',
)<GetRewardWheelAvailabilityRequest, GetRewardWheelAvailabilityResponse>();

export const discardRewardWheelAvailability = createAction('@rewardWheel/DISCARD_AVAILABILITY')();
