import {Error} from 'types/Error';
import {PageMetadata} from 'types/PageMetadata';
import {createReducer} from 'typesafe-actions';
import {CircularMap, create, set} from 'utils/circularMap';

import {load} from './actions';

type State = {
  readonly data: CircularMap<PageMetadata>;
  readonly error: CircularMap<Error>;
  readonly loading: CircularMap<boolean>;
  readonly loaded: CircularMap<boolean>;
};

const initialState: State = {
  data: create<PageMetadata>(),
  error: create<Error>(),
  loaded: create<boolean>(),
  loading: create<boolean>(),
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload: {url}}) => ({
    ...state,
    loading: set(state.loading, url, true),
    error: set(state.error, url, null),
  }))
  .handleAction(load.success, (state, {meta: {url}, payload}) => ({
    ...state,
    loading: set(state.loading, url, null),
    loaded: set(state.loading, url, true),
    data: set(state.data, url, {
      ...payload,
      // this arrays may have null items
      ...(payload?.bottomLinksBlock?.links && {
        bottomLinksBlock: {
          links: payload.bottomLinksBlock.links.filter(Boolean),
        },
      }),
      ...(payload?.bottomTextBlock?.items && {
        bottomTextBlock: {
          items: payload.bottomTextBlock.items.filter(Boolean),
        },
      }),
    }),
  }))
  .handleAction(load.failure, (state, {meta: {url}, payload}) => ({
    ...state,
    loading: set(state.loading, url, null),
    error: set(state.error, url, payload),
  }));
