import {discardableByUser} from 'helpers/discardable';

export const SET = 'cards/fresh/SET';

const initialState = null;

export default discardableByUser(function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case SET:
      return action.data;
    default:
      return state;
  }
});

export function isSelectedCardLoaded(globalState) {
  return !!globalState.cards.selected.loaded;
}

export function setFreshCard(data = null) {
  return {
    type: SET,
    data,
  };
}
