import {createRequestAction} from 'store/utils/requestActions';
import {ProductCategory} from 'types/ProductCategory';

export type LoadProductBreadcrumbsRequest = {
  productId: string;
};

export type LoadProductBreadcrumbsResponse = ProductCategory[];

export const loadProductBreadcrumbs = createRequestAction(
  '@productBreadcrumbs/LOAD_REQUEST',
  '@productBreadcrumbs/LOAD_RESPONSE',
  '@productBreadcrumbs/LOAD_FAILURE',
)<LoadProductBreadcrumbsRequest, LoadProductBreadcrumbsResponse>();
