import {createRequestAction} from 'store/utils/requestActions';

export type LoadGooglePayDataRequest = {
  orderGroupId: string;
};

export type LoadGooglePayDataResponse = google.payments.api.PaymentDataRequest;

export const loadGooglePayData = createRequestAction(
  '@googlePayData/LOAD_REQUEST',
  '@googlePayData/LOAD_SUCCESS',
  '@googlePayData/LOAD_FAILURE',
)<LoadGooglePayDataRequest, LoadGooglePayDataResponse>();
