import {assignRemove, assignSet} from 'utils/object';

export const LOAD = 'variantPrices/LOAD';
export const LOAD_SUCCESS = 'variantPrices/LOAD_SUCCESS';
export const LOAD_FAIL = 'variantPrices/LOAD_FAIL';

const initialState = {
  data: {},
  loading: {},
  error: {},
  loaded: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.id),
        loading: assignSet(state.loading, action.id, true),
        loaded: assignRemove(state.loaded, action.id),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        data: assignSet(state.data, action.id, action.result),
        loading: assignRemove(state.loading, action.id),
        loaded: assignSet(state.loaded, action.id, action.result),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
      };
    default:
      return state;
  }
}

export default reducer;

export function isVariantPricesLoading(globalState, id) {
  return !!globalState.variantPrices.loading[id];
}

export function isVariantPricesLoaded(globalState, id) {
  const {variantPrices} = globalState;
  return variantPrices.loaded[id];
}

export function getVariantPrices(globalState, id) {
  return isVariantPricesLoaded(globalState, id) ? globalState.variantPrices.data[id] : null;
}

export function getCheckoutItemById(globalState, id) {
  const prices = getVariantPrices(globalState, id);
  if (prices) {
    const {productVariantId, quantity, total} = prices;
    return {
      productVariantId,
      quantity,
      total,
    };
  }
  return null;
}

export function getVariantPricesError(globalState, id) {
  return globalState.variantPrices.error[id] || null;
}

export function getVariantPricesId(productId, productVariantId, quantity, couponId = '') {
  return [productId, productVariantId, quantity, couponId].join('/');
}

export function loadVariantPrices(productId, productVariantId, quantity, couponId = '') {
  const body = {
    items: [{productId, productVariantId, quantity}],
  };

  if (couponId) {
    body.couponId = couponId;
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id: getVariantPricesId(productId, productVariantId, quantity, couponId),
    productId,
    productVariantId,
    quantity,
    couponId,
    promise: (client) =>
      client.api.post('/cart/getPrices', {body}).then(({language, currency, body: {payload}}) => ({
        currency,
        productId,
        productVariantId,
        quantity,
        couponId,
        ...payload,
      })),
  };
}
