import {Request} from 'express';
import {History} from 'history';
import React, {ReactNode} from 'react';

type Props = {
  children: ReactNode;
  context?: StaticContext;
  req?: Request;
  history?: History;
};

export type StaticContext = {
  url?: string;
  statusCode?: number;
};

export function RouterProvider({req, context, history, children}: Props): JSX.Element {
  if (__SERVER__) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const {StaticRouter} = require('react-router-dom');
    return (
      <StaticRouter location={req?.originalUrl} context={context}>
        {children}
      </StaticRouter>
    );
  }

  if (history) {
    // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
    const {Router} = require('react-router-dom');

    return <Router history={history}>{children}</Router>;
  }

  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  const {BrowserRouter} = require('react-router-dom');
  return <BrowserRouter>{children}</BrowserRouter>;
}
