import {
  discardCartCount,
  loadCartCount as loadCartCountAction,
  setCartCount,
} from 'store/modules/cartCount/actions';
import {createReducer} from 'typesafe-actions';

type State = {
  count: number | null;
  loaded: boolean;
};

const initialState: State = {
  count: null,
  loaded: false,
};

export const reducer = createReducer(initialState)
  .handleAction(loadCartCountAction.success, (state, {payload}) => ({
    ...state,
    count: payload.count,
    loaded: true,
  }))
  .handleAction(setCartCount, (state, {payload}) => ({
    ...state,
    count: payload,
    loaded: true,
  }))
  .handleAction(discardCartCount, () => initialState);
