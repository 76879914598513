import {produce} from 'immer';
import {
  addCollectionToFavorites,
  removeCollectionFromFavorites,
} from 'store/modules/productCollections/actions';
import {
  loadProductCollectionLikedList,
  LoadProductCollectionLikedListRequest,
  LoadProductCollectionLikedListResponse,
} from 'store/modules/productCollections/productCollectionLikedList/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {isContentListProductCollectionBanner} from 'types/ContentList/ContentListProductCollectionBanner';
import {createReducer, isActionOf} from 'typesafe-actions';

const initialState = getInitialState<
  LoadProductCollectionLikedListRequest,
  LoadProductCollectionLikedListResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadProductCollectionLikedList.request, (state, {payload}) =>
    handleRequest(state, payload, Boolean(payload.pageToken)),
  )
  .handleAction(loadProductCollectionLikedList.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: meta.pageToken ? [...(state.data?.items ?? []), ...payload.items] : payload.items,
    }),
  )
  .handleAction(loadProductCollectionLikedList.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(
    [addCollectionToFavorites.success, removeCollectionFromFavorites.success],
    (state, action) =>
      produce(state, (draft) => {
        draft.data?.items?.forEach((item) => {
          if (
            isContentListProductCollectionBanner(item) &&
            item.content.productCollectionBanner.productCollectionLite.id ===
              action.meta.collectionId
          ) {
            item.content.productCollectionBanner.productCollectionLite.isLiked = isActionOf(
              addCollectionToFavorites.success,
              action,
            );
          }
        });
      }),
  );
