import {discardableByUser} from 'helpers/discardable';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {getSelectors} from 'store/utils/reducerCreators/simpleMapped/selectors';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {createSelectorFactory} from 'store/utils/selectors';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {WarrantyTexts} from 'types/WarrantyTexts';
import {createReducer, FuncAction} from 'typesafe-actions';

type LoadProductWarrantyTextsActionRequest = {id: string};

type LoadProductWarrantyTextsActionResponse = WarrantyTexts;

export const loadProductWarrantyTextsAction = createRequestAction(
  'productWarrantyTexts/LOAD',
  'productWarrantyTexts/LOAD_SUCCESS',
  'productWarrantyTexts/LOAD_FAIL',
)<LoadProductWarrantyTextsActionRequest, LoadProductWarrantyTextsActionResponse>();

const initialState = getInitialState<
  LoadProductWarrantyTextsActionRequest,
  LoadProductWarrantyTextsActionResponse
>();

const innerReducer = createReducer(initialState)
  .handleAction(loadProductWarrantyTextsAction.request, (state, {payload}) =>
    handleRequest(state, payload.id, payload, true),
  )
  .handleAction(loadProductWarrantyTextsAction.success, (state, {meta, payload}) =>
    handleSuccess(state, meta.id, meta, payload),
  )
  .handleAction(loadProductWarrantyTextsAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta.id, meta, payload),
  );

export const reducer = discardableByUser(innerReducer);

export const {
  getData: getProductWarrantyTexts,
  isLoading: isProductWarrantyTextsLoading,
  isLoaded: isProductWarrantyTextsLoaded,
  getRequest: getProductWarrantyTextsRequest,
  getError: getProductWarrantyTextsError,
} = getSelectors(createSelectorFactory((state) => state.productWarrantyTexts));

export function loadProductWarrantyTexts(id: string): FuncAction {
  return requestActionCreator(loadProductWarrantyTextsAction, {id}, (store, client) =>
    client.api
      .get<ClientBackendResponse<LoadProductWarrantyTextsActionResponse>>(
        `/products/${id}/warranty`,
      )
      .then(({body: {payload}}) => payload),
  );
}
