import {prepareSettingsPayload} from './utils';

const settingsUrl = '/notifications/settings';

const LOAD = 'notifications/settings/LOAD';
const LOAD_SUCCESS = 'notifications/settings/LOAD_SUCCESS';
const LOAD_FAIL = 'notifications/settings/LOAD_FAIL';

const SAVE = 'notifications/settings/SAVE';
const SAVE_SUCCESS = 'notifications/settings/SAVE_SUCCESS';
const SAVE_FAIL = 'notifications/settings/SAVE_FAIL';

const initialState = {
  items: [],

  loading: false,
  loadingError: null,
  pageLoading: false,
  pageLoadingError: null,
  nextPageToken: null,

  saving: false,
  savingError: null,
};

function baseReducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD: {
      const {pageToken} = action;

      const nextState = {
        ...state,
        loadingError: null,
        pageLoadingError: null,
      };

      if (pageToken) {
        nextState.pageLoading = true;
      } else {
        nextState.loading = true;
      }

      return nextState;
    }

    case LOAD_SUCCESS: {
      const {pageToken, result} = action;
      const {items, nextPageToken} = result;

      const nextState = {
        ...state,
        nextPageToken: items.length ? nextPageToken || null : null,
      };

      if (pageToken) {
        nextState.items = state.items.concat(items || []);
        nextState.pageLoading = false;
      } else {
        nextState.items = items;
        nextState.loading = false;
      }

      return nextState;
    }

    case LOAD_FAIL: {
      const {error, pageToken} = action;

      const nextState = {
        ...state,
      };

      if (pageToken) {
        nextState.pageLoading = false;
        nextState.pageLoadingError = error;
      } else {
        nextState.loading = false;
        nextState.loadingError = error;
      }

      return nextState;
    }

    case SAVE:
      return {
        ...state,
        saving: true,
        savingError: null,
      };

    case SAVE_SUCCESS: {
      const {items, nextPageToken} = action.result;

      return {
        ...state,
        items,
        nextPageToken: items.length ? nextPageToken || null : null,
        saving: false,
      };
    }

    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
        savingError: action.error,
      };

    default:
      return state;
  }
}

export const reducer = baseReducer;

export function loadNotificationsSettings(pageToken) {
  const query = {};

  if (pageToken) {
    query.pageToken = pageToken;
  }

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) => client.api.get(settingsUrl, {query}).then(prepareSettingsPayload),
  };
}

export function saveNotificationsSettings(items) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client) => client.api.post(settingsUrl, {body: {items}}).then(prepareSettingsPayload),
  };
}
