import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';
import {unwrapErrorObject} from 'utils/error/unwrapErrorObject';

import {loadInvoice, LoadVatInvoiceRequest, LoadVatInvoiceResponse} from './actions';

const initialState = getInitialState<LoadVatInvoiceRequest, LoadVatInvoiceResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadInvoice.request, (state, {payload}) => handleRequest(state, payload))
  .handleAction(loadInvoice.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadInvoice.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, unwrapErrorObject(payload)),
  );
