import {produce} from 'immer';
import {handleContentListProducts} from 'store/enhancer/contentList';
import {
  addProductToCollection,
  editProductsInCollections,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';
import {
  loadAllCollectionProducts,
  LoadAllCollectionProductsRequest,
  LoadAllCollectionProductsResponse,
} from 'store/modules/productCollections/allCollectionProducts/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

const initialState = getInitialState<
  LoadAllCollectionProductsRequest,
  LoadAllCollectionProductsResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadAllCollectionProducts.request, (state, {payload}) =>
    handleRequest(state, payload, Boolean(payload.pageToken)),
  )
  .handleAction(loadAllCollectionProducts.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, {
      ...payload,
      items: meta.pageToken ? [...(state.data?.items || []), ...payload.items] : payload.items,
    }),
  )
  .handleAction(loadAllCollectionProducts.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(
    [addProductToCollection.success, removeProductFromCollection.success],
    (state, action) =>
      produce(state, (draft) => {
        handleContentListProducts(draft.data?.items, (product) => {
          if (product.id === action.meta.itemKey.productId) {
            product.favorite = isActionOf(addProductToCollection.success, action);
          }
        });
      }),
  )
  .handleAction(editProductsInCollections.success, (state, {meta}) =>
    produce(state, (draft) => {
      meta.updates.forEach(({itemKey, isRemoving}) => {
        handleContentListProducts(draft.data?.items, (product) => {
          if (product.id === itemKey.productId) {
            product.favorite = !isRemoving;
          }
        });
      });
    }),
  );
