import {VerifyHcaptchaBody} from 'helpers/ApiClient/Transport/HcaptchaProtectionTransport';
import {hcaptchaError, HcaptchaErrorType} from 'types/HcaptchaError';
import {loadAsyncScript} from 'utils/dom';
import {memoize} from 'utils/memoize';
import {createUrl} from 'utils/url';

const HCAPTCHA_ONLOAD_NAME = '__hcaptchaOnLoad';

declare const window: Window & {[HCAPTCHA_ONLOAD_NAME]?: () => void};

const loadHcaptcha = memoize(
  (scriptUrl: string, language: string): Promise<typeof window.hcaptcha> => {
    const url = createUrl(scriptUrl, {
      hl: language,
      recaptchacompat: false,
      render: 'explicit',
      onload: HCAPTCHA_ONLOAD_NAME,
    });
    return new Promise((resolve, reject) => {
      window[HCAPTCHA_ONLOAD_NAME] = () => {
        window[HCAPTCHA_ONLOAD_NAME] = undefined;
        resolve(window.hcaptcha);
      };
      loadAsyncScript(url).catch(reject);
    });
  },
  (args: unknown[]) => args.join(' '),
);

export async function hcaptchaExecute({
  scriptUrl,
  siteKey,
  language,
  verify,
  container,
  errorMessage,
  retry,
  onLoadingChange,
}: {
  scriptUrl: string;
  siteKey: string;
  language: string;
  verify: (body: VerifyHcaptchaBody) => Promise<unknown>;
  container: HTMLElement | null;
  errorMessage: string;
  retry?: () => Promise<unknown>;
  onLoadingChange?: (isLoading: boolean) => void;
}): Promise<void> {
  try {
    onLoadingChange?.(true);
    const hcaptcha = await loadHcaptcha(scriptUrl, language);
    let captchaId: number;

    const handleSuccess = async () => {
      const clientToken = hcaptcha.getResponse(captchaId);
      const ekey = hcaptcha.getRespKey(captchaId);

      try {
        onLoadingChange?.(true);
        await verify({clientToken, ekey});
        await retry?.().catch(() => null);
        window.location.reload();
      } catch (error) {
        onLoadingChange?.(false);
        verify({
          error: hcaptchaError(
            HcaptchaErrorType.INTERNAL,
            (error as Error)?.message || 'unexpected error',
          ),
        });
        // eslint-disable-next-line no-alert
        alert(errorMessage);
      }
    };

    const createHandleError = (defaultMessage: string) => {
      return function handleError(error?: Error) {
        verify({
          error: hcaptchaError(HcaptchaErrorType.HCAPTCHA, error?.message || defaultMessage),
        });
        // eslint-disable-next-line no-alert
        alert(errorMessage);
      };
    };

    if (container) {
      hcaptcha.render(container, {
        sitekey: siteKey,
        callback: handleSuccess,
        'error-callback': createHandleError('hcaptcha unexpected error'),
        'expired-callback': createHandleError('hcaptcha response expires'),
      });
    } else {
      throw hcaptchaError(HcaptchaErrorType.INTERNAL, 'hcaptcha container missing');
    }
    // TODO: add `error: unknown` after updating prettier
  } catch (error) {
    verify({
      error: hcaptchaError(
        Object(error).type || HcaptchaErrorType.INTERNAL,
        Object(error).message || 'unexpected error',
      ),
    });
    // eslint-disable-next-line no-alert
    alert(errorMessage);
    throw error;
  } finally {
    onLoadingChange?.(false);
  }
}
