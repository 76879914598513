import {createRequestAction} from 'store/utils/requestActions';
import {type Address, type AddressFieldName} from 'types/Address';
import {type NamedColor} from 'types/Color';

export type LoadRequest = Partial<Address>;

export type LoadResponse = {
  description?: Address['description'];
  messages?: {
    [key in AddressFieldName]: {
      severity?: 'error';
      text?: string;
      textColor?: NamedColor;
    };
  };
  // unused
  popup?: {
    id?: string;
    title?: string;
    text?: string;
    editButtonText?: string;
  };
};

export const load = createRequestAction(
  '@addresses/validated/LOAD_REQUEST',
  '@addresses/validated/LOAD_SUCCESS',
  '@addresses/validated/LOAD_FAILURE',
)<LoadRequest, LoadResponse>();

export const loadPartial = createRequestAction(
  '@addresses/validated/LOAD_PARTIAL_REQUEST',
  '@addresses/validated/LOAD_PARTIAL_SUCCESS',
  '@addresses/validated/LOAD_PARTIAL_FAILURE',
)<LoadRequest, LoadResponse>();
