import {ApiClient} from 'helpers/ApiClient';
import {getPassiveEventOptions} from 'utils/dom';

export function pwaInstallPrompt(client: ApiClient): void {
  if (__SERVER__) return;

  const pwaInstallPromptEnabled = client.device.getDeviceVar('pwaInstallPromptEnabled');

  if (!pwaInstallPromptEnabled) {
    window.addEventListener(
      'beforeinstallprompt',
      (e: Event) => e.preventDefault(),
      getPassiveEventOptions(),
    );
  }
}
