import {combineReducers} from 'utils/combineReducers';

import {reducer as exists} from './exists/reducer';
import {reducer as form} from './form/reducer';
import {reducer as passport} from './passport/reducer';

export const reducer = combineReducers({
  exists,
  form,
  passport,
});
