import config from 'config';
import {produce} from 'immer';
import {isActionOf} from 'typesafe-actions';
import {DEFAULT_APPEARANCE, isContentListEmpty} from 'helpers/contentList';
import {
  appendContentListItems,
  enhanceContentListItems,
  getItemsToShow,
  handleContentListProducts,
} from 'store/enhancer/contentList';
import {assignSet} from 'utils/object';
import {START_PAGE_TOKEN} from 'store/consts';
import {
  addProductToCollection,
  editProductsInCollections,
  removeProductFromCollection,
} from 'store/modules/productCollections/actions';

const LOAD = 'best/LOAD';
const LOAD_SUCCESS = 'best/LOAD_SUCCESS';
const LOAD_FAIL = 'best/LOAD_FAIL';

const initialState = {
  error: null,
  itemsData: {},
  loaded: {},
  loading: false,
  meta: {},
  nextPageToken: '',
};

export const best = function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      if (!action.lite && state.lite && state.loaded[START_PAGE_TOKEN]) {
        return state;
      }

      return {
        ...state,
        itemsData: action.pageToken ? state.itemsData : [],
        error: null,
        loaded: action.pageToken ? state.loaded : {},
        loading: true,
      };
    case LOAD_SUCCESS: {
      if (action.lite && !state.lite && state.loaded[START_PAGE_TOKEN]) {
        return state;
      }

      const itemsData = appendContentListItems(
        state.itemsData,
        action.result.items,
        action.pageToken === state.nextPageToken,
        !action.result.nextPageToken,
      );

      // some content list items generated randomly every time
      // use lite items to prevent content changes if it has equal ids (content)
      if (state.lite && state.itemsData?.items?.length) {
        const isEqualIds = state.itemsData.items.every(
          ({id}, index) => itemsData.items[index]?.id === id,
        );
        if (isEqualIds) {
          itemsData.items = [
            ...state.itemsData.items,
            ...itemsData.items.slice(state.itemsData.items.length),
          ];
        }
      }

      return {
        ...state,
        lite: action.lite,
        itemsData,
        loaded: assignSet(state.loaded, action.pageToken || START_PAGE_TOKEN, true),
        loading: false,
        meta: {
          currency: action.result.currency,
          language: action.result.language,
        },
        nextPageToken: action.result.nextPageToken,
      };
    }
    case LOAD_FAIL: {
      return {
        ...state,
        error: action.error,
        loading: false,
      };
    }
    case addProductToCollection.success.getType():
    case removeProductFromCollection.success.getType(): {
      return produce(state, (draft) => {
        const items = draft.itemsData?.items;

        handleContentListProducts(items, (product) => {
          if (product.id === action.meta.itemKey.productId) {
            // eslint-disable-next-line no-param-reassign
            product.favorite = isActionOf(addProductToCollection.success, action);
          }
        });
      });
    }
    case editProductsInCollections.success.getType(): {
      return produce(state, (draft) => {
        action.payload.collectionsUpdatedProductInfoList.forEach(({productId, isFavorite}) => {
          const items = draft.itemsData?.items;

          handleContentListProducts(items, (product) => {
            if (product.id === productId) {
              // eslint-disable-next-line no-param-reassign
              product.favorite = isFavorite;
            }
          });
        });
      });
    }
    default:
      return state;
  }
};

function getState(globalState) {
  return globalState.best;
}

export function isBestLoaded(globalState, pageToken) {
  const state = getState(globalState);
  return !pageToken || !!state.loaded[pageToken];
}

export function isBestLoading(globalState) {
  return getState(globalState).loading || false;
}

export function getBestError(globalState) {
  return getState(globalState).error || null;
}

export function getBestNextPageToken(globalState) {
  return isBestLoaded(globalState) ? getState(globalState).nextPageToken : null;
}

export function isBestOutdated(globalState) {
  return isBestLoaded(globalState) && !isBestLoaded(globalState, START_PAGE_TOKEN);
}

export function isBestEmpty(globalState) {
  return (
    isBestLoaded(globalState) &&
    !getBestNextPageToken(globalState) &&
    isContentListEmpty(getState(globalState).itemsData.items)
  );
}

export function isBestLite(globalState) {
  return getState(globalState).lite;
}

export function getBest(globalState) {
  return isBestLoaded(globalState) ? getItemsToShow(getState(globalState).itemsData) : null;
}

export function loadBestLite() {
  const body = {
    appearance: DEFAULT_APPEARANCE,
  };

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    lite: true,
    pageToken: START_PAGE_TOKEN,
    promise: (client) =>
      client.api
        .post('/contentList/best/lite/get', {body})
        .then(({currency, language, body: {contexts, payload}}) => ({
          currency,
          language,
          ...payload,
          items: enhanceContentListItems(payload.items, language, currency, null, contexts),
        })),
  };
}

export function loadBest(pageToken, count = config.productsPageSize) {
  const body = {
    appearance: DEFAULT_APPEARANCE,
  };
  if (pageToken && pageToken !== START_PAGE_TOKEN) {
    body.pageToken = pageToken;
  }
  if (count) {
    body.count = count;
  }

  const cache = {
    qd: {language: true, currency: ['RUB', 'USD', 'EUR']},
    body: {pageToken: false, count: [config.productsPageSize]},
    time: 1000 * 60 * 15,
    requestTime: 1000 * 60 * 10,
  };

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    lite: false,
    count,
    pageToken,
    promise: (client) =>
      client.api
        .post('/contentList/best/get', {body, cache})
        .then(({currency, language, body: {contexts, payload}}) => ({
          currency,
          language,
          ...payload,
          items: enhanceContentListItems(payload.items, language, currency, pageToken, contexts),
        })),
  };
}
