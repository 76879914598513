import {produce} from 'immer';
import {
  addCollectionToFavorites,
  removeCollectionFromFavorites,
} from 'store/modules/productCollections/actions';
import {enhanceLoadedTime, enhanceLanguage} from 'store/utils/enhancers';
import {isProductCollectionPayload} from 'types/Promotion';
import {isActionOf} from 'typesafe-actions';
import {assignRemove, assignSet} from 'utils/object';
import {applyOffers} from 'store/modules/offers/actions';
import {OfferBannerState} from 'types/Banner';
import {LOAD_SUCCESS as LIST_LOAD_SUCCESS} from './list';

const LOAD = 'promotions/dict/LOAD';
const LOAD_SUCCESS = 'promotions/dict/LOAD_SUCCESS';
const LOAD_FAIL = 'promotions/dict/LOAD_FAIL';

const initialState = {
  data: {},
  loaded: {},
  loading: {},
  error: {},
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignRemove(state.error, action.id),
        loaded: assignRemove(state.loaded, action.id),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS: {
      return {
        ...state,
        data: assignSet(state.data, action.id, action.result),
        loaded: assignSet(state.loaded, action.id, true),
        loading: assignRemove(state.loading, action.id),
      };
    }
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignRemove(state.loading, action.id),
      };
    case LIST_LOAD_SUCCESS: {
      const data = {...state.data};
      const loaded = {...state.loaded};
      action.result.items.forEach((item) => {
        data[item.id] = item;
        loaded[item.id] = true;
      });

      return {
        ...state,
        data,
        loaded,
      };
    }
    case addCollectionToFavorites.success.getType():
    case removeCollectionFromFavorites.success.getType(): {
      return produce(state, (draft) => {
        Object.values(draft.data).forEach((item) => {
          if (
            isProductCollectionPayload(item.payload) &&
            item.payload.productCollectionId === action.meta.collectionId
          ) {
            // eslint-disable-next-line no-param-reassign
            item.payload.isLiked = isActionOf(addCollectionToFavorites.success, action);
          }
        });
      });
    }
    case applyOffers.success.getType(): {
      return produce(state, (draft) => {
        action.meta.applyOffers.forEach(({id}) => {
          const promotionOfferBanner = draft.data[id]?.offerBanner;

          if (promotionOfferBanner) {
            promotionOfferBanner.state = OfferBannerState.APPLIED;
          }
        });
      });
    }
    default:
      return state;
  }
}

export default reducer;

export function isPromotionLoaded(globalState, id) {
  const {promotions} = globalState;
  return !!promotions.dict.loaded[id];
}

export function getPromotion(globalState, id) {
  const {promotions} = globalState;
  return isPromotionLoaded(globalState, id) ? promotions.dict.data[id] : null;
}

export function getPromotionError(globalState, id) {
  return globalState.promotions.dict.error[id];
}

export function isPromotionLoading(globalState, id) {
  return !!globalState.promotions.dict.loading[id];
}

export function getPromotionExpirationMs(promotion) {
  if (
    !promotion ||
    promotion.expirationType !== 'date' ||
    typeof promotion.timeRemainingMs !== 'number'
  ) {
    return null;
  }

  return Math.max(0, promotion.loadedTimeMs + promotion.timeRemainingMs);
}

export function getPromotionStartMs(promotion) {
  if (!promotion || !promotion.payload || typeof promotion.payload.remainingTimeMs !== 'number') {
    return null;
  }

  return Math.max(0, promotion.loadedTimeMs + promotion.payload.remainingTimeMs);
}

export function isPromotionActive(promotion) {
  const startMs = getPromotionStartMs(promotion);
  const expirationMs = getPromotionExpirationMs(promotion);
  return !startMs && (expirationMs === null || expirationMs > 0);
}

export function loadPromotion(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: (client) =>
      client.api
        .get(`/promotions/${id}`)
        .then(({language, body: {payload}}) =>
          enhanceLoadedTime(enhanceLanguage(payload, language)),
        ),
  };
}
