import {actionNameCreator, createAsyncActionNames, extractResponsePayload} from 'store/utils';
import {isDefAndNotNull} from 'utils/function';
import {objectFilter} from 'utils/object';

import {enhanceCheckoutGroupId} from './common';

const actionsNs = actionNameCreator('deliveryPoints/form');
const [LOAD, LOAD_SUCCESS, LOAD_FAIL] = createAsyncActionNames(actionsNs('LOAD'));
const [VALIDATE, VALIDATE_SUCCESS, VALIDATE_FAIL] = createAsyncActionNames(actionsNs('VALIDATE'));
const [SAVE, SAVE_SUCCESS, SAVE_FAIL] = createAsyncActionNames(actionsNs('SAVE'));
const RESET = actionsNs('RESET');

const initialState = {
  initialValues: null,
  parts: null,
  messages: null,
  loading: false,
  loadingError: null,
  validating: false,
  validatingError: null,
  saved: false,
  saving: false,
  savingError: null,
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...initialState,
        loading: true,
      };

    case LOAD_SUCCESS:
      return {
        ...state,
        initialValues: action.result.fields,
        parts: action.result.parts,
        loading: false,
      };

    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loadingError: action.error,
      };

    case VALIDATE:
      return {
        ...state,
        validating: true,
        validatingError: null,
      };

    case VALIDATE_SUCCESS:
      return {
        ...state,
        messages: action.result.messages,
        validating: false,
      };

    case VALIDATE_FAIL:
      return {
        ...state,
        validating: false,
        validatingError: action.error,
      };

    case SAVE:
      return {
        ...state,
        saving: true,
        savingError: null,
      };

    case SAVE_SUCCESS:
      return {
        ...state,
        saved: true,
        saving: false,
      };

    case SAVE_FAIL:
      return {
        ...state,
        saving: false,
        savingError: action.error,
      };

    default:
      return state;
  }
}

export const getPointFormState = (globalState) => globalState.deliveryPoints.form;

export function loadPointForm(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client, dispatch, getState) => {
      const query = enhanceCheckoutGroupId(getState(), {id});

      return client.api.get('/deliveryPoints/metainfo', {query}).then(extractResponsePayload);
    },
  };
}

export function validatePointForm(id, fields) {
  return {
    types: [VALIDATE, VALIDATE_SUCCESS, VALIDATE_FAIL],
    promise: (client, dispatch, getState) => {
      const body = enhanceCheckoutGroupId(getState(), {id, fields});

      return client.api.post('/deliveryPoints/form/validate', {body}).then(extractResponsePayload);
    },
  };
}

export function savePointForm(id, fields = null) {
  return {
    types: [SAVE, SAVE_SUCCESS, SAVE_FAIL],
    promise: (client, dispatch, getState) => {
      const body = enhanceCheckoutGroupId(getState(), objectFilter({id, fields}, isDefAndNotNull));
      return client.api.post('/deliveryPoints/selections/add', {body}).then(extractResponsePayload);
    },
  };
}

export function resetForm() {
  return {
    type: RESET,
  };
}
