import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';
import type {ContentListProductRaw} from 'types/ContentList/ContentListProduct';
import {GalleryItem} from 'types/GalleryItem';
import {Gradient} from 'types/Gradient';
import {ImageBundle} from 'types/Image';
import {Button, Text} from 'types/Layout';
import {OpenPayload} from 'types/OpenPayload';
import {PopupResponse} from 'types/Popup';
import {ProductStore} from 'types/ProductStore';
import {createAction} from 'typesafe-actions';

export type LoadStorePageRequest = {
  productGroupParams?: unknown[];
  openPayload?: OpenPayload;
  pageToken?: string;
};

type StoreFeature = {
  id: string;
  title: Text;
  subtitle: Text;
  titleStartIcon?: ImageBundle;
  deeplink?: string;
};

type LoadStorePageResponseBase = {
  lite: ProductStore;
  nextPageToken?: string;
  currency?: string;
  language?: string;
  footer?: {
    button: {
      startText: {
        text: string;
      };
      background: Gradient;
      deeplink: string;
      eventParams: string;
      endContent:
        | {
            timer: {
              timeRemainingMs: number;
            };
          }
        | {
            icon: {
              icon: ImageBundle;
            };
          };
    };
  };
  header?: {
    id: string;
    name: string;
    enabled?: boolean;
    description?: string;
    fullDescription?: {title: string; text: string}[];
    icon?: ImageBundle;
    gallery?: {
      background: Record<string, unknown>;
      items: GalleryItem;
    };
    location?: string;
    favoritesCount?: {value: number; text: string};
    features?: StoreFeature[];
    fullDescriptionButton?: Button;
    feedback?: {deeplink: string};
  };
  tabs?: unknown[];
};

export type LoadStorePageResponse = LoadStorePageResponseBase & {
  items?: ContentListItem[];
};

export type LoadStorePageResponseRaw = LoadStorePageResponseBase & {
  items?: ContentListProductRaw[];
};

export type LoadStoreRequest = {
  storeId: string;
  language: string;
};

export type UpdateStorePayload = Pick<LoadStorePageResponse, 'footer' | 'header' | 'lite'>;

export type AddStoreToFavoritesRequest = {
  storeId: string;
};

export type RemoveStoreFromFavoritesRequest = {
  storeId: string;
};

export type AddStoreToFavoritesResponse = {store: UpdateStorePayload; popup: PopupResponse};
export type RemoveStoreFromFavoritesResponse = {store: UpdateStorePayload};

export const loadStore = createRequestAction(
  '@store/LOAD_REQUEST',
  '@store/LOAD_SUCCESS',
  '@store/LOAD_FAILURE',
)<LoadStorePageRequest, LoadStorePageResponse>();

export const addStoreToFavorites = createRequestAction(
  '@store/ADD_TO_FAVORITES_REQUEST',
  '@store/ADD_TO_FAVORITES_SUCCESS',
  '@store/ADD_TO_FAVORITES_FAILURE',
)<AddStoreToFavoritesRequest, void>();

export const removeStoreFromFavorites = createRequestAction(
  '@store/REMOVE_FROM_FAVORITES_REQUEST',
  '@store/REMOVE_FROM_FAVORITES_SUCCESS',
  '@store/REMOVE_FROM_FAVORITES_FAILURE',
)<AddStoreToFavoritesRequest, void>();

export const updateStore = createAction('@store/UPDATE_SUCCESS')<UpdateStorePayload>();

export const updateStoreFailure = createAction('@store/UPDATE_FAILURE')();
