import {produce} from 'immer';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {add, List, load, remove, update} from './actions';

const initialState = getInitialState<unknown, List>();

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state, {payload}) => handleRequest(state, payload, true))
  .handleAction(load.success, (state, {payload, meta}) => handleSuccess(state, meta, payload))
  .handleAction(load.failure, (state, {payload, meta}) => handleFailure(state, meta, payload))
  .handleAction(add.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.data?.items.push(payload);
    }),
  )
  .handleAction(update.success, (state, {payload, meta}) =>
    produce(state, (draft) => {
      const item = draft.data?.items.find((item) => item.id === meta.id);

      if (item) {
        Object.assign(item, payload);
      }
    }),
  )
  .handleAction(remove.success, (state, {meta}) =>
    produce(state, (draft) => {
      if (!draft.data) {
        return;
      }

      draft.data.items = draft.data.items.filter((item) => item.id !== meta.id);
    }),
  );
