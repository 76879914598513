import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadSocialUserProductCollectionsRequest = {
  userId: string;
  pageToken?: string;
};

export type LoadSocialUserProductCollectionsResponse = {
  items: ContentListItem[];
  nextPageToken?: string;
};

export const loadSocialUserProductCollections = createRequestAction(
  '@socialUserProductCollections/LOAD_REQUEST',
  '@socialUserProductCollections/LOAD_SUCCESS',
  '@socialUserProductCollections/LOAD_FAILURE',
)<LoadSocialUserProductCollectionsRequest, LoadSocialUserProductCollectionsResponse>();
