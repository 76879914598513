import {createRequestAction} from 'store/utils/requestActions';
import {Address} from 'types/Address';
import {EmailConfirmation} from 'types/EmailConfirmation';

export type List = {
  items: Address[];
  emailConfirmationBanner?: EmailConfirmation;
};

type Id = {
  id: string;
};

type Data = {
  data: Partial<Address>;
};

export const load = createRequestAction(
  '@addresses/list/LOAD_REQUEST',
  '@addresses/list/LOAD_SUCCESS',
  '@addresses/list/LOAD_FAILURE',
)<unknown, List>();

export const add = createRequestAction(
  '@addresses/list/ADD_REQUEST',
  '@addresses/list/ADD_SUCCESS',
  '@addresses/list/ADD_FAILURE',
)<Data, Address>();

export const update = createRequestAction(
  '@addresses/list/UPDATE_REQUEST',
  '@addresses/list/UPDATE_SUCCESS',
  '@addresses/list/UPDATE_FAILURE',
)<Id & Data, Address>();

export const remove = createRequestAction(
  '@addresses/list/REMOVE_REQUEST',
  '@addresses/list/REMOVE_SUCCESS',
  '@addresses/list/REMOVE_FAILURE',
)<Id, unknown>();
