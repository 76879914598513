import {produce} from 'immer';
import {Brand} from 'types/Brand';
import {Error} from 'types/Error';
import {createReducer} from 'typesafe-actions';

import {load} from './actions';

const initialState: {
  loading: boolean;
  error: Error | null;
  items: Record<string, Brand>;
} = {
  loading: false,
  error: null,
  items: {},
};

export const reducer = createReducer(initialState)
  .handleAction(load.request, (state) =>
    produce(state, (draft) => {
      draft.loading = true;
      draft.error = null;
    }),
  )
  .handleAction(load.success, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = null;
      draft.items[payload.id] = payload;
    }),
  )
  .handleAction(load.failure, (state, {payload}) =>
    produce(state, (draft) => {
      draft.loading = false;
      draft.error = payload;
    }),
  );
