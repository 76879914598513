import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {discardRewardWheel} from '../actions';
import {
  activateRewardWheelAction,
  ActivateRewardWheelRequest,
  ActivateRewardWheelResponse,
} from './actions';

const initialState = getInitialState<ActivateRewardWheelRequest, ActivateRewardWheelResponse>();

export const reducer = createReducer(initialState)
  .handleAction(activateRewardWheelAction.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(activateRewardWheelAction.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(activateRewardWheelAction.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(discardRewardWheel, () => initialState);
