import dict from './dict';
import list from './list';
import fresh from './fresh';
import selected from './selected';

export default {
  dict,
  list,
  fresh,
  selected,
};
