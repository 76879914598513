import {createRequestAction} from 'store/utils/requestActions';
import {EmptyObject} from 'types/EmptyObject';

export type LegalityCoolbeRequest = EmptyObject;

export type LegalityCoolbeResponse = {
  availableCountries: Array<{code: string}>;
};

export const loadLegalityCoolbe = createRequestAction(
  '@legalityCoolbe/LOAD',
  '@legalityCoolbe/LOAD_SUCCESS',
  '@legalityCoolbe/LOAD_FAILURE',
)<LegalityCoolbeRequest, LegalityCoolbeResponse>();
