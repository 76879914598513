import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {createReducer} from 'typesafe-actions';

import {
  loadArbitraryContentList as loadArbitraryContentListAction,
  LoadArbitraryContentListRequest,
  LoadArbitraryContentListResponse,
} from './actions';

const initialState = getInitialState<
  LoadArbitraryContentListRequest,
  LoadArbitraryContentListResponse
>();

export const reducer = createReducer(initialState)
  .handleAction(loadArbitraryContentListAction.request, (state, {payload}) =>
    payload.itemId ? handleRequest(state, payload.itemId, payload, true) : state,
  )
  .handleAction(loadArbitraryContentListAction.success, (state, {meta, payload}) =>
    meta.itemId ? handleSuccess(state, meta.itemId, meta, payload) : state,
  )
  .handleAction(loadArbitraryContentListAction.failure, (state, {meta, payload}) =>
    meta.itemId ? handleFailure(state, meta.itemId, meta, payload) : state,
  );
