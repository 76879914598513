import {produce} from 'immer';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {deletePersonalData} from '../actions';
import {
  loadPersonalDataForm,
  LoadPersonalDataFormRequest,
  LoadPersonalDataFormResponse,
  updatePersonalDataForm,
  validatePersonalDataForm,
} from './actions';

const initialState = getInitialState<LoadPersonalDataFormRequest, LoadPersonalDataFormResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadPersonalDataForm.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(loadPersonalDataForm.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadPersonalDataForm.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(updatePersonalDataForm.request, (state, {payload}) =>
    handleRequest(state, payload, true),
  )
  .handleAction(updatePersonalDataForm.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(updatePersonalDataForm.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction([validatePersonalDataForm.request], (state) =>
    produce(state, (draft) => {
      draft.loading = true;
    }),
  )
  .handleAction([validatePersonalDataForm.failure, validatePersonalDataForm.success], (state) =>
    produce(state, (draft) => {
      draft.loading = false;
    }),
  )
  .handleAction(deletePersonalData.success, () => initialState);
