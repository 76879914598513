import loadable from '@loadable/component';
import {AppLanguageCode} from 'config';
import {ComponentType, ReactElement} from 'react';
import {isLocaleData, LocaleData} from 'types/LocaleData';
import {requireModule} from 'utils/loadable';
import {memoizeLastShallowEqual} from 'utils/memoize';

type LocaleDataModule = {locale: LocaleData};
type LoaderProps = {language: string};

const loader = /* #__LOADABLE__ */ (props: LoaderProps) =>
  import(
    /* webpackChunkName: "__locale__.[request]" */ `./locales/${props.language}`
  ) as Promise<LocaleDataModule>;

const createLocaleDataComponent = memoizeLastShallowEqual(
  (
    localeData: LocaleData,
  ): ComponentType<
    LoaderProps & {
      children?: (localeData: LocaleData) => ReactElement | null;
    }
  > =>
    function LocaleDataLoadable({children}) {
      return children?.(localeData) || null;
    },
);

// loadable.lib can't work with full dynamic imports :(
export const LocaleDataLib = loadable(loader, {
  resolveComponent: ({locale}) => createLocaleDataComponent(locale),
});

export async function loadLocale(language: string): Promise<LocaleData> {
  const module = await requireModule(loader, {language});

  if (isLocaleData(module?.locale)) {
    return module.locale;
  }

  throw new Error(`Bad locale data for ${language} language.`);
}

type CheckLocalesMap<LocalesMap extends Record<AppLanguageCode, {locale: LocaleData}>> = LocalesMap;

// this type only for type checking
export type LocalesGuard = CheckLocalesMap<{
  en: typeof import('./locales/en');
  cs: typeof import('./locales/cs');
  de: typeof import('./locales/de');
  el: typeof import('./locales/el');
  es: typeof import('./locales/es');
  et: typeof import('./locales/et');
  fr: typeof import('./locales/fr');
  hu: typeof import('./locales/hu');
  it: typeof import('./locales/it');
  lt: typeof import('./locales/lt');
  lv: typeof import('./locales/lv');
  nb: typeof import('./locales/nb');
  nl: typeof import('./locales/nl');
  pl: typeof import('./locales/pl');
  pt: typeof import('./locales/pt');
  'pt-br': typeof import('./locales/pt-br');
  ro: typeof import('./locales/ro');
  kk: typeof import('./locales/kk');
  ru: typeof import('./locales/ru');
  'ru-ua': typeof import('./locales/ru-ua');
  sk: typeof import('./locales/sk');
  sv: typeof import('./locales/sv');
  tr: typeof import('./locales/tr');
  uk: typeof import('./locales/uk');
  ka: typeof import('./locales/ka');
  he: typeof import('./locales/he');
  ar: typeof import('./locales/ar');
  ja: typeof import('./locales/ja');
  lol: typeof import('./locales/lol');
  tlh: typeof import('./locales/tlh');
}>;
