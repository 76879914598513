import {enhanceLanguage, enhanceLoadedTime} from 'store/utils/enhancers';

const LOAD = 'promotions/list/LOAD';
export const LOAD_SUCCESS = 'promotions/list/LOAD_SUCCESS';
const LOAD_FAIL = 'promotions/list/LOAD_FAIL';

const initialState = {
  items: [],
  meta: {},
  nextPageToken: null,
  error: null,
  loaded: false,
  loading: false,
};

function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: null,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        meta: {language: action.result.language},
        items: action.result.items.map((item) => item.id),
        nextPageToken: action.result.nextPageToken,
        error: null,
        loading: false,
        loaded: true,
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: action.error,
        loading: false,
        loaded: false,
      };
    default:
      return state;
  }
}

export default reducer;

export function isPromotionsListLoaded(globalState) {
  const {promotions} = globalState;
  return promotions.list.loaded;
}

export function isPromotionsListLoading(globalState) {
  return globalState.promotions.list.loading;
}

export function getPromotionsListIds(globalState) {
  if (isPromotionsListLoaded(globalState)) {
    return globalState.promotions.list.items;
  }
  return null;
}

export function getPromotionsListError(globalState) {
  return globalState.promotions.list.error;
}

export function loadPromotionsList() {
  const cache = {
    qd: {language: true},
    time: 1000 * 60 * 15,
    requestTime: 1000 * 60 * 10,
  };

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: (client) =>
      client.api.get('/promotions', {cache}).then(({language, body: {payload}}) => ({
        language,
        ...payload,
        items: (payload.items || [])
          .filter((item) => item.type === 'productGroup')
          .map((item) => enhanceLoadedTime(enhanceLanguage(item, language))),
      })),
  };
}
