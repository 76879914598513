import {getServerTimestamp} from 'helpers/serverTime';
import {enhanceCart} from 'store/enhancer/cart';
import {enhanceLoadedTime} from 'store/utils/enhancers';
import {loadCartCount, setCartCount} from 'store/modules/cartCount/actions';
import {actionNameCreator, createAsyncActionNames} from 'store/utils';
import {loadCart} from 'store/modules/groupedCart/actions';
import {getCartAllSelectedRequestData} from 'store/modules/groupedCart/selection';
import {getType} from 'typesafe-actions';

const MINICART_LIFE_TIME_MS = 60 * 1000; // 1min

const actionsNs = actionNameCreator('miniCart');
const [MINICART_LOAD, MINICART_LOAD_SUCCESS, MINICART_LOAD_FAIL] = createAsyncActionNames(
  actionsNs('MINICART_LOAD'),
);
const OPEN = actionsNs('OPEN');
const CLOSE = actionsNs('CLOSE');

const initialState = {
  lastAddedItemVariantId: null,
  opened: false,

  cart: null,
  cartLoading: false,
  cartLoadingError: null,

  openSource: null,
  closeSource: null,

  loadedTimeMs: 0,
};

function baseReducer(state = initialState, action = {}) {
  switch (action.type) {
    case OPEN:
      return {
        ...state,
        opened: true,
        openSource: action.openSource,
        closeSource: null,
        lastAddedItemVariantId: action.lastAddedItemVariantId,
      };

    case CLOSE:
      return {
        ...state,
        opened: false,
        openSource: null,
        closeSource: action.closeSource,
      };

    case MINICART_LOAD: {
      const {silent} = action;

      return {
        ...state,
        cartLoading: state.cart ? !silent : true,
        cartLoadingError: null,
      };
    }

    case MINICART_LOAD_SUCCESS: {
      const {cart, loadedTimeMs} = action.result;

      return {
        ...state,
        cart,
        cartLoading: false,
        loadedTimeMs,
      };
    }

    case MINICART_LOAD_FAIL: {
      const {silent} = action;

      return {
        ...state,
        cartLoading: false,
        // при тихом обновлении не запоминаем ошибку, если есть данные корзины
        cartLoadingError: silent && state.cart ? null : action.error,
      };
    }

    case getType(loadCart.request):
    case getType(loadCartCount.request):
    case getType(setCartCount):
      return {
        ...state,
        loadedTimeMs: 0, // Оставляем все данные, но помечаем их протухшими
      };

    default:
      return state;
  }
}

export const reducer = baseReducer;

const getMiniCartState = (globalState) => globalState.miniCart;

export const getMiniCart = (globalState) => getMiniCartState(globalState).cart;

export const getMiniCartOpened = (globalState) => getMiniCartState(globalState).opened;

export const getMiniCartLoading = (globalState) => getMiniCartState(globalState).cartLoading;

export const getMiniCartLoadingError = (globalState) =>
  getMiniCartState(globalState).cartLoadingError;

export const getLastAddedItemVariantId = (globalState) =>
  getMiniCartState(globalState).lastAddedItemVariantId;

export const getMiniCartOpenSource = (globalState) => getMiniCartState(globalState).openSource;

export const getMiniCartCloseSource = (globalState) => getMiniCartState(globalState).closeSource;

export const isMiniCartExpired = (globalState) =>
  getServerTimestamp() - (getMiniCartState(globalState).loadedTimeMs || 0) > MINICART_LIFE_TIME_MS;

export function openMiniCart(lastAddedItemVariantId, openSource) {
  return {
    type: OPEN,
    lastAddedItemVariantId,
    openSource,
  };
}

export function closeMiniCart(closeSource) {
  return {
    type: CLOSE,
    closeSource,
  };
}

/**
 * Загрузка данных для миникорзины
 * @param {boolean} silent - если true, то не будет меняться состояние загрузки и инфа по ошибке
 * @param {boolean} noCartItemSelection - не посылать на бэкенд данные о выбранных товарах, чтобы не перетирать на бэкенде выбранные товары, в случае если это состояние хранится там. см. WEB-8919
 * @return {Object}
 */
export function loadMiniCart(silent = false, noCartItemSelection = false) {
  const body = {
    cartItemSelection: noCartItemSelection ? undefined : getCartAllSelectedRequestData(),
  };

  return {
    silent,
    types: [MINICART_LOAD, MINICART_LOAD_SUCCESS, MINICART_LOAD_FAIL],
    promise: (client) =>
      client.api
        .post('/cart/get', {body})
        .then(({body: {payload}}) =>
          enhanceCart(payload, {
            language: client.device.getLanguage(),
            currency: client.device.getCurrency(),
          }),
        )
        .then((cart) => enhanceLoadedTime({cart})),
  };
}
