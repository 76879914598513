import {MessageFormatElement} from 'react-intl';

export type LocaleData = {
  messages: Record<string, string> | Record<string, MessageFormatElement[]>;
  badge: {
    default: {
      appstore: string;
      googleplay: string;
      huawei: string;
      rustore?: string;
    };
  };
};

export function isLocaleData(data: unknown): data is LocaleData {
  return Boolean(data && typeof data === 'object' && 'messages' in data);
}
