import {combineReducers} from 'utils/combineReducers';

import {reducer as addresses} from './modules/addresses/reducer';
import {reducer as bestLite} from './modules/bestLite/reducer';
import {reducer as brand} from './modules/brand/reducer';
import {reducer as cardEditorPreferences} from './modules/cardEditorPreferences/reducer';
import {reducer as cartCount} from './modules/cartCount/reducer';
import {reducer as categoryPagination} from './modules/categoryPagination/reducer';
import {reducer as categoryPromoLinks} from './modules/categoryPromoLinks/reducer';
import {reducer as categoryRedirect} from './modules/categoryRedirect/reducer';
import {reducer as clearCookies} from './modules/clearCookies/reducer';
import {reducer as clientProduct} from './modules/clientProduct/reducer';
import {reducer as contentListArbitrary} from './modules/contentListArbitrary/reducer';
import {reducer as cookiesSettings} from './modules/cookiesSettings/reducer';
import {reducer as couponCards} from './modules/couponCards/reducer';
import {reducer as couponsFull} from './modules/couponsFull/reducer';
import {reducer as faq} from './modules/faq/reducer';
import {reducer as favoriteShops} from './modules/favoriteShopsList/reducer';
import {reducer as favoriteTabs} from './modules/favoriteTabs/reducer';
import {reducer as googlePayData} from './modules/googlePayData/reducer';
import {reducer as groupedCart} from './modules/groupedCart';
import {reducer as idler} from './modules/idler/reducer';
import {reducer as legalityCoolbe} from './modules/legalityCoolbe/reducer';
import {reducer as legalityDocuments} from './modules/legalityDocuments/reducer';
import {reducer as offers} from './modules/offers/reducer';
import {reducer as orderGroups} from './modules/orderGroups';
import {reducer as pageHash} from './modules/pageHash/reducer';
import {reducer as pageMetadata} from './modules/pageMetadata/reducer';
import {reducer as payments} from './modules/payments/reducer';
import {reducer as personalData} from './modules/personalData/reducer';
import {reducer as popup} from './modules/popup/reducer';
import {reducer as premium} from './modules/premium/reducer';
import {reducer as premiumSeo} from './modules/premiumSeo/reducer';
import {reducer as premiumSeoList} from './modules/premiumSeoList/reducer';
import {reducer as productBreadcrumbs} from './modules/productBreadcrumbs/reducer';
import {reducer as productCollections} from './modules/productCollections/reducer';
import {reducer as productReviewFilters} from './modules/productReviewFilters';
import {reducer as productReviews} from './modules/productReviews';
import {reducer as productSizeTable} from './modules/productSizeTable/reducer';
import {reducer as productWarrantyTexts} from './modules/productWarrantyTexts';
import {reducer as promotionBanner} from './modules/promotionBanner/reducer';
import {reducer as quarantineQuiz} from './modules/quarantineQuiz/reducer';
import {reducer as referralProgram} from './modules/referralProgram/reducer';
import {reducer as refundToCard} from './modules/refundToCard/reducer';
import {reducer as rewardWheel} from './modules/rewardWheel/reducer';
import {reducer as share} from './modules/share';
import {reducer as similars} from './modules/similars/reducer';
import {reducer as social} from './modules/social/reducer';
import {reducer as storePage} from './modules/store/reducer';
import {reducer as storeReviews} from './modules/storeReviews/reducer';
import {reducer as trendingLinks} from './modules/trendingLinks';
import {reducer as trendSimilar} from './modules/trendSimilar/reducer';
import {reducer as vat} from './modules/vat/reducer';
import {reducer as version} from './modules/version';

const map = {
  addresses,
  bestLite,
  brand,
  cardEditorPreferences,
  categoryPagination,
  categoryPromoLinks,
  clearCookies,
  cookiesSettings,
  clientProduct,
  couponCards,
  couponsFull,
  faq,
  favoriteShops,
  favoriteTabs,
  idler,
  legalityDocuments,
  legalityCoolbe,
  pageHash,
  pageMetadata,
  payments,
  personalData,
  popup,
  premium,
  premiumSeo,
  premiumSeoList,
  promotionBanner,
  referralProgram,
  refundToCard,
  quarantineQuiz,
  rewardWheel,
  cartCount,
  groupedCart,
  social,
  storePage,
  trendingLinks,
  trendSimilar,
  productSizeTable,
  vat,
  googlePayData,
  version,
  categoryRedirect,
  productCollections,
  productBreadcrumbs,
  similars,
  productWarrantyTexts,
  productReviews,
  productReviewFilters,
  share,
  orderGroups,
  offers,
  contentListArbitrary,
  storeReviews,
};

const rootReducer = combineReducers(map);

export type RootState = ReturnType<typeof rootReducer>;

export {map, rootReducer};
