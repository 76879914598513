import {combineReducers} from 'utils/combineReducers';

import {reducer as allCollectionProducts} from './allCollectionProducts/reducer';
import {reducer as collection} from './productCollection/reducer';
import {reducer as collectionDiscovery} from './productCollectionDiscovery/reducer';
import {reducer as collectionLikedList} from './productCollectionLikedList/reducer';
import {reducer as collectionList} from './productCollectionList/reducer';
import {reducer as collectionSelection} from './productCollectionSelection/reducer';

export const reducer = combineReducers({
  allCollectionProducts,
  collection,
  collectionDiscovery,
  collectionLikedList,
  collectionList,
  collectionSelection,
});
