import {BackendTrendingLink} from 'store/modules/trendingLinks/types';
import {RootState} from 'store/rootReducer';
import {SimpleState} from 'store/utils/reducerCreators/simple';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {TrendingLink} from 'types/TrendingLink';
import {createReducer, FuncAction} from 'typesafe-actions';
import {arrayToObject} from 'utils/array';

type LoadRequest = {slug: string};
type LoadResponse = TrendingLink;

export const loadTrendingLinkBySlugAction = createRequestAction(
  'trendingLinks/bySlug/LOAD',
  'trendingLinks/bySlug/LOAD_SUCCESS',
  'trendingLinks/bySlug/LOAD_FAIL',
)<LoadRequest, LoadResponse>();

const initialState = getInitialState<LoadRequest, LoadResponse>();

// to avoid bugs like WEB-3348 - THE SLUG WAS CALLED CONSTRUCTOR
const SAFE_SLUG_PREFIX = 'slug:';
const getSafeSlug = (slug: string) => `${SAFE_SLUG_PREFIX}${slug}`;

export const reducer = createReducer(initialState)
  .handleAction(loadTrendingLinkBySlugAction.request, (state, {payload}) =>
    handleRequest(state, getSafeSlug(payload.slug), payload, true),
  )
  .handleAction(loadTrendingLinkBySlugAction.success, (state, {meta, payload}) =>
    handleSuccess(state, getSafeSlug(meta.slug), meta, payload),
  )
  .handleAction(loadTrendingLinkBySlugAction.failure, (state, {meta, payload}) =>
    handleFailure(state, getSafeSlug(meta.slug), meta, payload),
  );

type LoadSimpleState = SimpleState<LoadRequest, LoadResponse>;

function getState(globalState: RootState, slug: string): LoadSimpleState | undefined {
  return globalState.trendingLinks.bySlug[getSafeSlug(slug)];
}

export function isTrendingLinkBySlugLoaded(
  globalState: RootState,
  slug: string,
): LoadSimpleState['loaded'] {
  return Boolean(getState(globalState, slug)?.loaded);
}

export function getTrendingLinkBySlug(
  globalState: RootState,
  slug: string,
): LoadSimpleState['data'] {
  if (!isTrendingLinkBySlugLoaded(globalState, slug)) {
    return null;
  }

  return getState(globalState, slug)?.data || null;
}

export function isTrendingLinkBySlugLoading(
  globalState: RootState,
  slug: string,
): LoadSimpleState['loading'] {
  return Boolean(getState(globalState, slug)?.loading);
}

export function getTrendingLinkBySlugError(
  globalState: RootState,
  slug: string,
): LoadSimpleState['error'] {
  return getState(globalState, slug)?.error || null;
}

export function loadTrendingLinkBySlug(slug: string): FuncAction<Promise<LoadResponse>> {
  return requestActionCreator(loadTrendingLinkBySlugAction, {slug}, (store, client) =>
    client.api
      .post<
        ClientBackendResponse<BackendTrendingLink>
      >('/trendingLinks/getByUrl', {body: {url: slug}})
      .then(({language, body: {payload}}) => ({
        ...payload,
        language,
        appearanceByLang: arrayToObject(payload.appearanceByLang, (item) =>
          item.language.toLowerCase(),
        ),
      })),
  );
}
