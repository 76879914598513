import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItem} from 'types/ContentList';

export type LoadAllCollectionProductsRequest = {
  pageToken?: string;
};

export type LoadAllCollectionProductsResponse = {
  items: ContentListItem[];
  nextPageToken?: string;
};

export const loadAllCollectionProducts = createRequestAction(
  '@allCollectionsProducts/LOAD_REQUEST',
  '@allCollectionsProducts/LOAD_RESPONSE',
  '@allCollectionsProducts/LOAD_FAILURE',
)<LoadAllCollectionProductsRequest, LoadAllCollectionProductsResponse>();
