import {produce} from 'immer';
import {IdlerState} from 'types/Idler';
import {createReducer} from 'typesafe-actions';

import {
  buyPerkAction,
  buyShipperAction,
  closeIdler,
  fetchIdlerStateAction,
  recalculatePackages,
  toggleMute,
  upgradeShipperAction,
} from './actions';

const initialState: {
  isMute: boolean;
  isShown: boolean;
  loading: boolean;
  lastFetchedAt: number;
  lastFetchedPackages: number;
  state?: IdlerState;
} = {
  isMute: false,
  isShown: false,
  loading: false,
  lastFetchedAt: 0,
  lastFetchedPackages: 0,
  state: undefined,
};

export const reducer = createReducer(initialState)
  .handleAction(
    [
      fetchIdlerStateAction.request,
      buyShipperAction.request,
      upgradeShipperAction.request,
      buyPerkAction.request,
    ],
    (state) =>
      produce(state, (draft) => {
        draft.loading = true;
      }),
  )
  .handleAction(
    [
      fetchIdlerStateAction.success,
      buyShipperAction.success,
      upgradeShipperAction.success,
      buyPerkAction.success,
    ],
    (state, {payload}) =>
      produce(state, (draft) => {
        draft.isShown = true;
        draft.loading = false;
        draft.lastFetchedAt = Date.now();
        draft.state = payload;
        draft.lastFetchedPackages = draft.state.packages;
      }),
  )
  .handleAction(
    [
      fetchIdlerStateAction.failure,
      buyShipperAction.failure,
      upgradeShipperAction.failure,
      buyPerkAction.failure,
    ],
    (state) =>
      produce(state, (draft) => {
        draft.loading = false;
      }),
  )
  .handleAction(closeIdler, (state) =>
    produce(state, (draft) => {
      draft.isShown = false;
    }),
  )
  .handleAction(recalculatePackages, (state) =>
    produce(state, (draft) => {
      if (draft.state) {
        const newPackagesNumber =
          draft.lastFetchedPackages +
          ((Date.now() - draft.lastFetchedAt) / 1000) * draft.state.income;

        draft.state.packages = Math.floor(newPackagesNumber);
      }
    }),
  )
  .handleAction(toggleMute, (state) =>
    produce(state, (draft) => {
      draft.isMute = !state.isMute;
    }),
  );
