import {produce} from 'immer';
import {
  clearSocialFeed,
  loadSocialFeed,
  LoadSocialFeedRequest,
  LoadSocialFeedResponse,
} from 'store/modules/social/feed/actions';
import {likePost, unlikePost} from 'store/modules/social/postLikes/actions';
import {getInitialState, handleFailure, handleSuccess} from 'store/utils/reducerCreators/simple';
import {createReducer, isActionOf} from 'typesafe-actions';

const initialState = getInitialState<LoadSocialFeedRequest, LoadSocialFeedResponse>();

export const reducer = createReducer(initialState)
  .handleAction(clearSocialFeed, () => initialState)
  .handleAction(loadSocialFeed.request, (state, {payload}) =>
    produce(state, (draft) => {
      draft.request = payload;
      draft.data = {
        ...state.data,
        items: payload.pageToken ? state.data?.items : undefined,
      };
      draft.error = null;
      draft.loading = true;
    }),
  )
  .handleAction(loadSocialFeed.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, {
      ...payload,
      categories: payload.categories || state.data?.categories,
      items: [...(state.data?.items || []), ...(payload.items || [])],
    }),
  )
  .handleAction(loadSocialFeed.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction([likePost.request, unlikePost.failure], (state, action) =>
    produce(state, (draft) => {
      const {postId} = isActionOf(likePost.request, action) ? action.payload : action.meta;

      draft.data?.items?.forEach((item) => {
        if (item.id === postId) {
          item.likesCount = (item.likesCount || 0) + 1;
          item.isLiked = true;
        }
      });
    }),
  )
  .handleAction([unlikePost.request, likePost.failure], (state, action) =>
    produce(state, (draft) => {
      const {postId} = isActionOf(unlikePost.request, action) ? action.payload : action.meta;

      draft.data?.items?.forEach((item) => {
        if (item.id === postId) {
          item.likesCount = (item.likesCount || 1) - 1;
          item.isLiked = false;
        }
      });
    }),
  );
