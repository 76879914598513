import {Address} from 'types/Address';
import {createReducer} from 'typesafe-actions';

import {cache, reset} from './actions';

const initialState: Partial<Address> = {};

export const reducer = createReducer(initialState)
  .handleAction(cache, (state, {payload}) => ({
    ...state,
    ...payload,
  }))
  .handleAction(reset, () => ({}));
