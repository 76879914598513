import {createRequestAction} from 'store/utils/requestActions';

export const followSocialUser = createRequestAction(
  '@socialUserSubscribe/LOAD_REQUEST',
  '@socialUserSubscribe/LOAD_SUCCESS',
  '@socialUserSubscribe/LOAD_FAILURE',
)<{userId: string}, void>();

export const unfollowSocialUser = createRequestAction(
  '@socialUserUnsubscribe/LOAD_REQUEST',
  '@socialUserUnsubscribe/LOAD_SUCCESS',
  '@socialUserUnsubscribe/LOAD_FAILURE',
)<{userId: string}, void>();
