/* eslint-disable @typescript-eslint/no-var-requires,global-require */
import {AuthMigrationMethod, AuthViewType} from 'components/Auth/Views/Type';
import {CouponPopup} from 'components/Header/CouponRedesign/CouponPopup';
import {ecsError} from 'helpers/log/ECS/ecsError';
import {useStore} from 'hooks/redux';
import {useSendAnalytics} from 'hooks/useAnalytics';
import {useApiClient} from 'hooks/useApiClient';
import {useLogger} from 'hooks/useLogger';
import {usePopupManager} from 'hooks/usePopupManager';
import {DeepLinkActionDispatchContext} from 'providers/DeepLinks';
import React, {FC, ReactNode, useCallback} from 'react';
import {loadContentListScreen} from 'store/modules/contentListScreen';
import {loadCouponCard} from 'store/modules/couponCards';
import {getCouponCard} from 'store/modules/couponCards/selectors';
import {fetchIdlerState} from 'store/modules/idler';
import {redeemPromocode} from 'store/modules/promocodes';
import {activateRewardWheel, getRewardWheel} from 'store/modules/rewardWheel';
import {addStoreToFavorites} from 'store/modules/store';
import {DeepLinkActionDispatch, DeepLinkActionType} from 'types/DeepLinks';
import {loadable} from 'utils/nextTickLoadable';

const Auth = loadable(() => import('containers/Auth/Auth'), {
  resolveComponent: (module) => module.default,
  ssr: false,
});

export const DeepLinksManager: FC<{children: ReactNode}> = ({children}) => {
  const {dispatch, getState} = useStore();
  const client = useApiClient();
  const popupManager = usePopupManager();
  const sendEvent = useSendAnalytics();
  const logger = useLogger('DeepLinksManager');

  const actionDispatch = useCallback<DeepLinkActionDispatch>(
    async (action) => {
      switch (action.type) {
        case DeepLinkActionType.POPUP: {
          const {createClientPopupView} = require('containers/ClientPopup');
          popupManager.open(createClientPopupView(action.params));
          break;
        }
        case DeepLinkActionType.FEEDBACK: {
          // don't parse chunk before it needed
          const {createFeedbackPopupView} = require('containers/Feedback');
          const {context} = action.params;

          popupManager.open(createFeedbackPopupView({context}));
          break;
        }
        case DeepLinkActionType.REWARD_WHEEL: {
          await dispatch(getRewardWheel(action.params?.source));
          break;
        }
        case DeepLinkActionType.REWARD_WHEEL_ACTIVATE: {
          await dispatch(activateRewardWheel(action.params.rewardWheelId));
          break;
        }
        case DeepLinkActionType.IDLER: {
          await dispatch(fetchIdlerState());
          break;
        }
        case DeepLinkActionType.STORE_SUBSCRIBE: {
          await dispatch(addStoreToFavorites(action.params.storeId, action.params.payload));
          break;
        }
        case DeepLinkActionType.PROMOCODE: {
          const {
            createPromocodeLoadingPopupView,
            createPromocodeErrorPopupView,
          } = require('components/PromocodePopup');

          const closeLoadingPopup = popupManager.open(createPromocodeLoadingPopupView());

          try {
            const {
              payload: {
                coupon: {id},
              },
            } = await dispatch(redeemPromocode(action.params.code));

            const {item: coupon} = await dispatch(loadCouponCard(id));

            closeLoadingPopup();

            popupManager.open((viewProps) => (
              <CouponPopup {...viewProps} coupon={coupon} source="deeplink" />
            ));
          } catch (error) {
            logger.error({
              error: ecsError(error),
              message: `Couldn't redeem promocode ${action.params.code}`,
            });

            closeLoadingPopup();

            popupManager.open(createPromocodeErrorPopupView({error}));
          }
          break;
        }
        case DeepLinkActionType.COUPON: {
          // don't parse chunk before it needed
          const {deepLinks} = require('helpers/deepLink');
          const coupon = getCouponCard(getState(), action.params.couponId);
          const match = coupon && deepLinks.match(coupon.deeplink);

          if (match) {
            try {
              const clientPopupProps = {
                popupId: match.params.popupId,
                popupParams: JSON.parse(match.params.popupParams),
              };
              const {createClientPopupView} = require('containers/ClientPopup');
              popupManager.open(createClientPopupView(clientPopupProps));
            } catch (cause) {
              logger.error(`Couldn't parse JSON from popupParams: ${match.params.popupParams}`);
            }
          } else {
            logger.error(
              `Couldn't open popup for coupon ${action.params.couponId}, are couponCards loaded?`,
            );
          }
          break;
        }
        case DeepLinkActionType.AUTH_MIGRATE: {
          sendEvent('profileSignInClick', {signInVariant: 'changeSignInVariant'});

          popupManager.open({
            withoutOverlay: true,
            render: ({onBack, onClose}) => (
              <Auth
                view={AuthViewType.MIGRATION}
                source="migration"
                onBack={onBack}
                onClose={onClose}
                onSuccess={onClose}
                migrationMethod={AuthMigrationMethod.MIGRATE}
              />
            ),
          });
          break;
        }
        case DeepLinkActionType.AUTH_RECOVERY: {
          sendEvent('profileSignInClick', {signInVariant: 'changeSignInVariant'});

          popupManager.open({
            withoutOverlay: true,
            render: ({onBack, onClose}) => (
              <Auth
                view={AuthViewType.MIGRATION}
                source="recovery"
                onBack={onBack}
                onClose={onClose}
                onSuccess={onClose}
                migrationMethod={AuthMigrationMethod.RECOVERY}
                preferredProvider={action.params?.preferredProvider}
              />
            ),
          });
          break;
        }
        case DeepLinkActionType.SUPPORT: {
          const {createFormPopupView} = await import('connectors/Faq/FormPopup');

          popupManager.open(createFormPopupView({metadata: action.params?.payload?.metadata}));
          break;
        }
        case DeepLinkActionType.CONTENT_LIST_SCREEN: {
          const screen = await dispatch(loadContentListScreen(action.params.origin));
          const isPaymentRequisites = action.params.origin?.params?.type === 'paymentRequisites';

          popupManager.open(
            isPaymentRequisites
              ? require('components/PaymentRequisitesPopup').createPaymentRequisitesPopupView({
                  screen,
                })
              : require('components/ContentListScreen').createContentListScreenPopupView({screen}),
          );
          break;
        }
        case DeepLinkActionType.ENTITY_REDIRECT: {
          client.jmtMigration.manualRedirectToOppositeEntity();
          break;
        }
        default:
          break;
      }
    },
    [client.jmtMigration, dispatch, getState, logger, popupManager, sendEvent],
  );

  return (
    <DeepLinkActionDispatchContext.Provider value={actionDispatch}>
      {children}
    </DeepLinkActionDispatchContext.Provider>
  );
};
