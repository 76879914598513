import {createRequestAction} from 'store/utils/requestActions';
import {ContentListItemRaw} from 'types/ContentList';
import {ClientProduct, ClientProductIncomplete} from 'types/ProductNext';

type LoadRequest = {
  productId: string;
  openPayload: Record<string, unknown> | undefined;
  preselectedVariantId?: string;
  pageToken?: string;
  count?: number;
  reviewFilters?: string[];
  reviewSortOrder?: string;
  quickLook?: boolean;
  landing?: boolean;
  incomplete: boolean;
};

type LoadResponseIncomplete = ClientProductIncomplete & {items: ContentListItemRaw[]};

type LoadResponse = ClientProduct & {items: ContentListItemRaw[]};

const load = createRequestAction(
  '@clientProduct/LOAD_REQUEST',
  '@clientProduct/LOAD_SUCCESS',
  '@clientProduct/LOAD_FAILURE',
)<LoadRequest, LoadResponseIncomplete>();

type UpdateRequest = {
  productId: string;
};

type UpdateResponse = Omit<ClientProductIncomplete, 'items' | 'ssr' | 'incomplete'>;

const update = createRequestAction(
  '@clientProduct/UPDATE_HEADER_REQUEST',
  '@clientProduct/UPDATE_HEADER_SUCCESS',
  '@clientProduct/UPDATE_HEADER_FAILURE',
)<UpdateRequest, UpdateResponse>();

type SetDeliveryMethodRequest = {
  deliveryId: string;
};

const setDeliveryMethod = createRequestAction(
  '@clientProduct/SET_DELIVERY_METHOD_REQUEST',
  '@clientProduct/SET_DELIVERY_METHOD_SUCCESS',
  '@clientProduct/SET_DELIVERY_METHOD_FAILURE',
)<SetDeliveryMethodRequest, unknown>();

export {
  load,
  update,
  LoadRequest,
  LoadResponse,
  LoadResponseIncomplete,
  UpdateRequest,
  UpdateResponse,
  setDeliveryMethod,
  SetDeliveryMethodRequest,
};
