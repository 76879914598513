import {BackendTrendingLink} from 'store/modules/trendingLinks/types';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {getSelectors} from 'store/utils/reducerCreators/simpleMapped/selectors';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {createSelectorFactory} from 'store/utils/selectors';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {TrendingLink} from 'types/TrendingLink';
import {createReducer, FuncAction} from 'typesafe-actions';

type LoadRequest = {categoryId: string};
type LoadResponse = TrendingLink[];

export const loadTrendingLinkByCategoryAction = createRequestAction(
  'trendingLinks/byCategory/LOAD',
  'trendingLinks/byCategory/LOAD_SUCCESS',
  'trendingLinks/byCategory/LOAD_FAIL',
)<LoadRequest, LoadResponse>();

const initialState = getInitialState<LoadRequest, LoadResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadTrendingLinkByCategoryAction.request, (state, {payload}) =>
    handleRequest(state, payload.categoryId, payload, true),
  )
  .handleAction(loadTrendingLinkByCategoryAction.success, (state, {meta, payload}) =>
    handleSuccess(state, meta.categoryId, meta, payload),
  )
  .handleAction(loadTrendingLinkByCategoryAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta.categoryId, meta, payload),
  );

export const {
  isLoading: isTrendingLinksByCategoryLoading,
  isLoaded: isTrendingLinksByCategoryLoaded,
  getData: getTrendingLinksByCategory,
  getError: getTrendingLinksByCategoryError,
  getRequest: getTrendingLinksByCategoryRequest,
} = getSelectors(createSelectorFactory((state) => state.trendingLinks.byCategory));

export function loadTrendingLinksByCategory(
  categoryId: string,
  count = 20,
): FuncAction<Promise<LoadResponse>> {
  return requestActionCreator(loadTrendingLinkByCategoryAction, {categoryId}, (store, client) =>
    client.api
      .post<
        ClientBackendResponse<{items: BackendTrendingLink[]}>
      >('/trendingLinks/getByCategory', {body: {categoryId, count}})
      .then(({body: {payload}}) =>
        (payload.items || []).map((item) => {
          delete item.appearanceByLang;
          return item as TrendingLink;
        }),
      ),
  );
}
