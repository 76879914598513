import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {createReducer} from 'typesafe-actions';

import {LoadRequest, LoadResponse, loadTrendSimilar} from './actions';

const initialState = getInitialState<LoadRequest, LoadResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadTrendSimilar.request, (state, {payload, payload: {slug}}) =>
    handleRequest(state, slug, payload),
  )
  .handleAction(loadTrendSimilar.success, (state, {payload, meta, meta: {slug}}) =>
    handleSuccess(state, slug, meta, payload),
  )
  .handleAction(loadTrendSimilar.failure, (state, {payload, meta, meta: {slug}}) =>
    handleFailure(state, slug, meta, payload),
  );
