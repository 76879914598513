import {produce} from 'immer';
import {send as sendFeedback} from 'store/modules/feedback/actions';
import {clearPost} from 'store/modules/social/posts/actions';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {FeedbackContextType, isFeedbackSocialPostCommentContext} from 'types/Feedback';
import {SocialPostComment} from 'types/SocialPostComment';
import {createReducer, isActionOf} from 'typesafe-actions';

import {
  createPostComment,
  deletePostComment,
  loadPostCommentReplies,
  loadPostComments,
  LoadPostCommentsRequest,
  LoadPostCommentsResponse,
  updatePostComment,
} from './actions';

type Response = LoadPostCommentsResponse[];

const initialState = getInitialState<LoadPostCommentsRequest, Response>();

export const reducer = createReducer(initialState)
  .handleAction(loadPostComments.request, (state, {payload, payload: {pageToken}}) =>
    handleRequest(state, payload, Boolean(pageToken)),
  )
  .handleAction(loadPostComments.success, (state, {payload, meta}) =>
    handleSuccess(state, meta, [...(state.data || []), payload]),
  )
  .handleAction(loadPostComments.failure, (state, {payload, meta}) =>
    handleFailure(state, meta, payload),
  )
  .handleAction(loadPostCommentReplies.success, (state, {payload, meta: {commentId}}) => {
    return produce(state, (draft) => {
      const item = draft.data
        ?.find((data) => data?.items.find((comment) => comment.id === commentId))
        ?.items.find((comment) => comment.id === commentId);

      if (item) {
        item.replies = {
          ...payload,
          items: [...(item.replies?.items || []), ...payload.items],
        };
      }
    });
  })
  .handleAction(clearPost, () => initialState)
  .handleAction(
    createPostComment.success,
    (state, {payload, meta: {parentCommentId, updatePermissions}}) =>
      produce(state, (draft) => {
        const comments = draft?.data;

        if (!comments?.[0]) {
          return;
        }

        comments.forEach((data) => {
          data.totalCount = data.totalCount || 0;
          data.totalCount += 1;
        });

        if (!parentCommentId) {
          comments[0].items.unshift(payload);
        } else {
          const item = comments
            ?.find((data) => data?.items.find((comment) => comment.id === parentCommentId))
            ?.items.find((comment) => comment.id === parentCommentId);

          if (item) {
            item.replies = item.replies || {totalCount: 0, items: []};
            item.replies.items.unshift(payload);
            item.replies.totalCount += 1;
          }
        }

        if (updatePermissions) {
          comments.forEach((data) =>
            data?.items.forEach((comment) => {
              [comment].concat(comment.replies?.items || []).forEach((item) => {
                if (item.author.id === payload.author.id) {
                  item.isEditable = payload.isEditable;
                  item.isDeletable = payload.isDeletable;
                  item.isReportable = payload.isReportable;
                }
              });
            }),
          );
        }
      }),
  )
  .handleAction(
    [updatePostComment.success, deletePostComment.success, sendFeedback.success],
    (state, action) =>
      produce(state, (draft) => {
        const commentsPages = draft.data;
        if (!commentsPages) return;

        let commentId: string | undefined;

        if (isActionOf(sendFeedback.success, action)) {
          if (isFeedbackSocialPostCommentContext(action.meta.context)) {
            commentId = action.meta.context[FeedbackContextType.SOCIAL_POST_COMMENT].commentId;
          }
        } else {
          commentId = action.meta.commentId;
        }

        if (!commentId) {
          return;
        }

        function findAndUpdate(items: SocialPostComment[] = []): boolean {
          const updatedItem = items.find((comment, index) => {
            if (comment.id === commentId) {
              if (isActionOf(updatePostComment.success, action)) {
                items.splice(index, 1, action.payload);
              } else if (isActionOf(deletePostComment.success)) {
                commentsPages?.forEach((data) => {
                  if (data.totalCount) {
                    data.totalCount -= 1 + (items[index]?.replies?.items?.length || 0);
                  }
                });
                items.splice(index, 1);
              } else {
                items.splice(index, 1);
              }
              return true;
            }

            return findAndUpdate(comment?.replies?.items);
          });

          return Boolean(updatedItem);
        }

        commentsPages.find((comments) => findAndUpdate(comments.items));
      }),
  );
