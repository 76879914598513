import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simple';
import {createReducer} from 'typesafe-actions';

import {
  loadProductSizeTableAction,
  LoadProductSizeTableRequest,
  LoadProductSizeTableResponse,
} from './actions';

const initialState = getInitialState<LoadProductSizeTableRequest, LoadProductSizeTableResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadProductSizeTableAction.request, (state, {payload}) =>
    handleRequest(state, payload),
  )
  .handleAction(loadProductSizeTableAction.success, (state, {meta, payload}) =>
    handleSuccess(state, meta, payload),
  )
  .handleAction(loadProductSizeTableAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta, payload),
  );
