import produce from 'immer';
import {send as sendFeedback} from 'store/modules/feedback/actions';
import {RootState} from 'store/rootReducer';
import {WithLanguage} from 'store/utils/enhancers';
import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
  SimpleMappedState,
} from 'store/utils/reducerCreators/simpleMapped';
import {getSelectors} from 'store/utils/reducerCreators/simpleMapped/selectors';
import {createRequestAction, requestActionCreator} from 'store/utils/requestActions';
import {createSelectorFactory} from 'store/utils/selectors';
import {ClientBackendResponse} from 'types/ClientBackendResponse';
import {FeedbackContextType, isFeedbackProductReviewContext} from 'types/Feedback';
import {ReviewsDescriptionButton} from 'types/ProductReviews';
import {SocialPost, SocialPostFilterId} from 'types/SocialPost';
import {createReducer, FuncAction} from 'typesafe-actions';
import {isRecord} from 'utils/guards';

export type LoadProductReviewsRequest = {id: string};

export type LoadProductReviewsResponse = {
  items: Array<WithLanguage<SocialPost>>;
  language: string;
  prevPageToken?: string;
  pageToken?: string;
  nextPageToken?: string;
  descriptionButton: ReviewsDescriptionButton;
};

export const loadProductReviewsAction = createRequestAction(
  'productReviews/LOAD',
  'productReviews/LOAD_SUCCESS',
  'productReviews/LOAD_FAIL',
)<LoadProductReviewsRequest, LoadProductReviewsResponse>();

type State = SimpleMappedState<LoadProductReviewsRequest, LoadProductReviewsResponse> & {
  lastId?: string;
};

const initialState: State = getInitialState();

export const reducer = createReducer(initialState)
  .handleAction(loadProductReviewsAction.request, (state, {payload}) =>
    handleRequest(state, payload.id, payload, true),
  )
  .handleAction(loadProductReviewsAction.success, (state, {meta, payload}) => {
    const lastPage = state.lastId ? state[state.lastId]?.data : null;
    const prevPageToken =
      // TODO(4u@): Hacky way to put previous page token to store
      lastPage && lastPage.pageToken && lastPage.nextPageToken === payload.pageToken
        ? lastPage.pageToken
        : undefined;

    // eslint-disable-next-line no-param-reassign
    state = {...state, lastId: meta.id} as State;

    return handleSuccess(state, meta.id, meta, {
      ...payload,
      prevPageToken,
    });
  })
  .handleAction(loadProductReviewsAction.failure, (state, {meta, payload}) =>
    handleFailure(state, meta.id, meta, payload),
  )
  .handleAction(sendFeedback.success, (state, action) =>
    produce(state, (draft) => {
      if (!isFeedbackProductReviewContext(action.meta.context)) {
        return;
      }

      const {reviewId} = action.meta.context[FeedbackContextType.PRODUCT_REVIEW];

      Object.values(draft).forEach((reviewsState) => {
        if (!isRecord(reviewsState)) {
          return;
        }

        const reviews = reviewsState?.data?.items;

        if (!reviews) {
          return;
        }

        const reviewIndex = reviews.findIndex((review) => review.id === reviewId);

        if (reviewIndex < 0) {
          return;
        }

        reviews.splice(reviewIndex, 1);
      });
    }),
  );

export const DEFAULT_FILTER = SocialPostFilterId.ALL;
export const DEFAULT_SORTING = 'default';

export function getProductReviewsId(
  productId: string,
  filter?: string,
  sorting?: string,
  token?: string,
): string {
  return `${productId}:${filter || DEFAULT_FILTER}:${sorting || DEFAULT_SORTING}:${token || 'none'}`;
}

export const {
  getData: getProductReviews,
  isLoading: isProductReviewsLoading,
  isLoaded: isProductReviewsLoaded,
  getRequest: getProductReviewsRequest,
  getError: getProductReviewsError,
} = getSelectors(createSelectorFactory((state) => state.productReviews));

export function getProductReviewsItems(state: RootState, id: string): SocialPost[] | undefined {
  return getProductReviews(state, id)?.items;
}

export function getProductReviewsDescriptionButton(
  state: RootState,
  id: string,
): ReviewsDescriptionButton | undefined {
  return getProductReviews(state, id)?.descriptionButton;
}

export function getProductReviewsNextPageToken(state: RootState, id: string): string {
  return getProductReviews(state, id)?.nextPageToken || '';
}

export function getProductReviewsPrevPageToken(state: RootState, id: string): string {
  return getProductReviews(state, id)?.prevPageToken || '';
}

type LoadProductReviewsApiResponse = ClientBackendResponse<
  Pick<LoadProductReviewsResponse, 'items' | 'nextPageToken' | 'descriptionButton'>
>;

export function loadProductReviews(
  productId: string,
  {
    filter = DEFAULT_FILTER,
    sort = DEFAULT_SORTING,
    pageToken,
  }: {
    filter?: string;
    sort?: string;
    pageToken?: string;
  },
): FuncAction {
  const query = {
    filterIds: [filter],
    sort: sort === DEFAULT_SORTING ? undefined : sort,
    ...(pageToken && {pageToken}),
  };

  const id = getProductReviewsId(productId, filter, sort, pageToken);

  return requestActionCreator(loadProductReviewsAction, {id}, (store, client) =>
    client.api
      .get<LoadProductReviewsApiResponse>(`/social/products/${productId}/reviews`, {
        query,
        querySerialization: {
          arrayFormat: 'none',
        },
      })
      .then(({language, body: {payload}}) => {
        const {items = [], descriptionButton} = payload;

        items.forEach((item) => {
          item.language = language;
        });

        const nextPageToken = payload.nextPageToken || '';
        return {language, items, nextPageToken, pageToken, descriptionButton};
      }),
  );
}
