import {actionNameCreator, createAsyncActionNames} from 'store/utils';
import {getUniqueRequestId} from 'utils/request';

import {arrayToObject} from 'utils/array';
import {isNotNullish} from 'utils/guards';
import {getCheckoutGroupId} from './common';

const actionsNs = actionNameCreator('deliveryPoints/info');
const [LOAD_ITEMS, LOAD_ITEMS_SUCCESS, LOAD_ITEMS_FAIL] = createAsyncActionNames(
  actionsNs('LOAD_ITEMS'),
);
const [LOAD_ACTIVE_ITEMS, LOAD_ACTIVE_ITEMS_SUCCESS, LOAD_ACTIVE_ITEMS_FAIL] =
  createAsyncActionNames(actionsNs('LOAD_ACTIVE_ITEMS'));
const RESET_ACTIVE_ITEMS = actionsNs('RESET_ACTIVE_ITEM');
const SET_SELECTED_ITEM = actionsNs('SET_SELECTED_ITEM');
const RESET = actionsNs('RESET');

const ITEMS_PAGE_SIZE = 20;

const initialState = {
  activeItems: {
    ids: [],
    list: [],
    map: {},
    loading: false,
    loadingError: null,
  },
  selectedItemId: null,
  items: {
    requestId: null,
    center: null,
    list: [],
    map: {},
    loading: false,
    loadingError: null,
    pageLoading: false,
    pageLoadingError: null,
    nextPageToken: null,
  },
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD_ITEMS: {
      const {coordinates, pageToken, requestId} = action;
      const nextItems = {
        ...state.items,
        center: coordinates,
        requestId,
      };

      if (pageToken) {
        nextItems.pageLoading = true;
        nextItems.pageLoadingError = null;
      } else {
        nextItems.loading = true;
        nextItems.loadingError = null;
        nextItems.nextPageToken = null;
      }

      return {
        ...state,
        items: nextItems,
      };
    }

    case LOAD_ITEMS_SUCCESS: {
      if (action.requestId !== state.items.requestId) {
        return state;
      }

      const {pageToken, result} = action;
      const nextItems = {...state.items};

      if (pageToken) {
        nextItems.list = state.items.list.concat(result.items);
        nextItems.pageLoading = false;
      } else {
        nextItems.list = result.items;
        nextItems.loading = false;
      }

      nextItems.map = arrayToObject(nextItems.list, (item) => item.id);
      nextItems.nextPageToken = result.nextPageToken;

      return {
        ...state,
        items: nextItems,
      };
    }

    case LOAD_ITEMS_FAIL: {
      if (action.requestId !== state.items.requestId) {
        return state;
      }

      const {pageToken, error} = action;
      const nextItems = {...state.items};

      if (pageToken) {
        nextItems.pageLoading = false;
        nextItems.pageLoadingError = error;
      } else {
        nextItems.loading = false;
        nextItems.loadingError = error;
      }

      return {
        ...state,
        items: nextItems,
      };
    }

    case LOAD_ACTIVE_ITEMS: {
      const {ids} = action;
      const activeItemsList = ids.map((id) => state.items.map[id]).filter(isNotNullish);
      const activeItemsMap = arrayToObject(activeItemsList, (item) => item.id);

      return {
        ...state,
        activeItems: {
          ...state.activeItems,
          ids,
          list: activeItemsList,
          map: activeItemsMap,
          loading: true,
          loadingError: null,
        },
      };
    }

    case LOAD_ACTIVE_ITEMS_SUCCESS: {
      const {result} = action;
      const activeItemsList = result?.items ?? [];
      const activeItemsMap = arrayToObject(activeItemsList, (item) => item.id);

      return {
        ...state,
        activeItems: {
          ...state.activeItems,
          list: activeItemsList,
          map: activeItemsMap,
          loading: false,
          loadingError: null,
        },
      };
    }

    case LOAD_ACTIVE_ITEMS_FAIL: {
      const {error} = action;

      return {
        ...state,
        activeItems: {
          ...state.activeItems,
          loading: false,
          loadingError: error,
        },
      };
    }

    case RESET_ACTIVE_ITEMS:
      return {
        ...state,
        activeItems: {
          ...state.activeItems,
          ids: [],
          list: [],
          map: {},
          loading: false,
          loadingError: null,
        },
      };

    case SET_SELECTED_ITEM: {
      return {
        ...state,
        selectedItem: action.id,
      };
    }

    case RESET:
      return initialState;

    default:
      return state;
  }
}

const getInfoState = (globalState) => globalState.deliveryPoints.info;

export const getPointsInfoState = (globalState) => getInfoState(globalState).items;

export const getCenterForPointsInfo = (globalScope) => getPointsInfoState(globalScope).center;

export const getActivePointsInfoState = (globalState) => getInfoState(globalState).activeItems;

export function loadPointsInfo(coordinates, pageToken) {
  return {
    coordinates,
    pageToken,
    requestId: getUniqueRequestId(),
    types: [LOAD_ITEMS, LOAD_ITEMS_SUCCESS, LOAD_ITEMS_FAIL],
    promise: (client, dispatch, getState) => {
      const query = {
        ...coordinates,
        checkoutGroupId: getCheckoutGroupId(getState()),
        count: ITEMS_PAGE_SIZE,
      };

      if (pageToken) {
        query.pageToken = pageToken;
      }

      return client.api.get('/deliveryPoints/infos', {query}).then(({body: {payload}}) => payload);
    },
  };
}

export function loadActivePointsInfo(ids) {
  return {
    ids,
    types: [LOAD_ACTIVE_ITEMS, LOAD_ACTIVE_ITEMS_SUCCESS, LOAD_ACTIVE_ITEMS_FAIL],
    promise: (client, dispatch, getState) => {
      const query = {
        ids,
        checkoutGroupId: getCheckoutGroupId(getState()),
      };

      return client.api
        .get('/deliveryPoints/infos/byIds', {
          query,
          querySerialization: {
            arrayFormat: 'none',
          },
        })
        .then(({body: {payload}}) => payload);
    },
  };
}

export function setActivePoints(ids) {
  return ({dispatch}) => {
    if (ids?.length) {
      dispatch(loadActivePointsInfo(ids));
    }
  };
}

export function resetActivePoints() {
  return {
    type: RESET_ACTIVE_ITEMS,
  };
}

export function setSelectedPointInfoId(id) {
  return {
    type: SET_SELECTED_ITEM,
    id,
  };
}

export function resetInfo() {
  return {
    type: RESET,
  };
}
