import {createAsyncActionNames, actionNameCreator} from 'store/utils';

import {getCheckoutGroupId} from './common';

const actionsNs = actionNameCreator('deliveryPoints/address');
const [LOAD_SUGGEST, LOAD_SUGGEST_SUCCESS, LOAD_SUGGEST_FAIL] = createAsyncActionNames(
  actionsNs('LOAD_SUGGEST'),
);
const [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL] = createAsyncActionNames(actionsNs('SEARCH'));
const [INFO, INFO_SUCCESS, INFO_FAIL] = createAsyncActionNames(actionsNs('INFO'));
const [DETECT, DETECT_SUCCESS, DETECT_FAIL] = createAsyncActionNames(actionsNs('DETECT'));
const RESET = actionsNs('RESET');

const initialState = {
  search: {
    searchPhrase: null,
    item: null,
    loading: false,
    loadingError: null,
  },
  suggest: {
    searchPhrase: null,
    items: null,
    loading: false,
    loadingError: null,
  },
  detect: {
    loading: false,
  },
};

export function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case DETECT:
      return {
        ...state,
        detect: {
          loading: true,
        },
      };
    case DETECT_SUCCESS:
    case DETECT_FAIL:
      return {
        ...state,
        detect: {
          loading: false,
        },
      };
    case LOAD_SUGGEST:
      return {
        ...state,
        suggest: {
          ...state.suggest,
          searchPhrase: action.searchPhrase,
          loading: true,
          loadingError: null,
        },
      };

    case LOAD_SUGGEST_SUCCESS:
      if (state.suggest.searchPhrase !== action.searchPhrase) {
        return state;
      }

      return {
        ...state,
        suggest: {
          ...state.suggest,
          items: action.result.items,
          loading: false,
        },
      };

    case LOAD_SUGGEST_FAIL:
      if (state.suggest.searchPhrase !== action.searchPhrase) {
        return state;
      }

      return {
        ...state,
        suggest: {
          ...state.suggest,
          loading: false,
          loadingError: action.error,
        },
      };

    case SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          searchPhrase: action.searchPhrase,
          item: null,
          loading: true,
          loadingError: null,
        },
      };

    case SEARCH_SUCCESS:
      if (state.search.searchPhrase !== action.searchPhrase) {
        return state;
      }

      return {
        ...state,
        search: {
          ...state.search,
          item: action.result,
          loading: false,
        },
      };

    case SEARCH_FAIL:
      if (state.search.searchPhrase !== action.searchPhrase) {
        return state;
      }

      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          loadingError: action.error,
        },
      };

    case RESET:
      return initialState;

    default:
      return state;
  }
}

const getAddressState = (globalState) => globalState.deliveryPoints.address;

export const getAddressSuggestState = (globalState) => getAddressState(globalState).suggest;

export const getAddressSearchState = (globalState) => getAddressState(globalState).search;

export const getAddressDetectState = (globalState) => getAddressState(globalState).detect;

export const getAddressSearchPhrase = (globalState) =>
  getAddressSearchState(globalState).searchPhrase;

export const isDetectLoading = (globalState) => getAddressDetectState(globalState).loading;

export function loadAddressSuggest(searchPhrase) {
  return {
    searchPhrase,
    types: [LOAD_SUGGEST, LOAD_SUGGEST_SUCCESS, LOAD_SUGGEST_FAIL],
    promise: (client, dispatch, getState) => {
      const body = {
        checkoutGroupId: getCheckoutGroupId(getState()),
        field: 'street1',
        fields: {
          street1: searchPhrase,
        },
        count: 5,
      };

      return client.api
        .post('/deliveryPoints/address/suggest', {body})
        .then(({body: {payload}}) => {
          const items = (payload.items || []).map((item) => {
            item.value = item.value || item.id; // eslint-disable-line no-param-reassign
            return item;
          });

          return {
            items,
          };
        });
    },
  };
}

export function searchAddress(searchPhrase) {
  return {
    searchPhrase,
    types: [SEARCH, SEARCH_SUCCESS, SEARCH_FAIL],
    promise: (client, dispatch, getState) => {
      const body = {
        checkoutGroupId: getCheckoutGroupId(getState()),
        query: searchPhrase,
      };

      return client.api
        .post('/deliveryPoints/address/search', {body})
        .then(({body: {payload}}) => payload);
    },
  };
}

export function loadAddressInfo(addressId) {
  return {
    types: [INFO, INFO_SUCCESS, INFO_FAIL],
    promise: (client, dispatch, getState) => {
      const body = {
        checkoutGroupId: getCheckoutGroupId(getState()),
        id: addressId,
      };

      return client.api
        .post('/deliveryPoints/address/suggest/info', {body})
        .then(({body: {payload}}) => payload);
    },
  };
}

export function detectAddress(checkoutGroupId) {
  const query = {checkoutGroupId};
  return {
    types: [DETECT, DETECT_SUCCESS, DETECT_FAIL],
    promise: (client) =>
      client.api
        .get('/deliveryPoints/address/detect', {query})
        .then(({body: {payload}}) => payload),
  };
}

export function resetAddress() {
  return {
    type: RESET,
  };
}
