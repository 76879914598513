import {
  getInitialState,
  handleFailure,
  handleRequest,
  handleSuccess,
} from 'store/utils/reducerCreators/simpleMapped';
import {createReducer} from 'typesafe-actions';

import {
  loadStoreReviews as loadStoreReviewsAction,
  LoadStoreReviewsRequest,
  LoadStoreReviewsResponse,
} from './actions';
import {getStoreReviewsId} from './utils';

const initialState = getInitialState<LoadStoreReviewsRequest, LoadStoreReviewsResponse>();

export const reducer = createReducer(initialState)
  .handleAction(loadStoreReviewsAction.request, (state, {payload}) =>
    handleRequest(
      state,
      getStoreReviewsId(payload.storeId, payload.filter, payload.pageToken),
      payload,
      true,
    ),
  )
  .handleAction(loadStoreReviewsAction.success, (state, {meta, payload}) => {
    const prevPayload = meta.pageToken
      ? Object.values(state).find((item) => item?.data?.nextPageToken === meta.pageToken)
      : undefined;
    const prevPageToken = prevPayload?.data?.pageToken;
    const nextPayload = {...payload, prevPageToken};

    return handleSuccess(
      state,
      getStoreReviewsId(meta.storeId, meta.filter, meta.pageToken),
      meta,
      nextPayload,
    );
  })
  .handleAction(loadStoreReviewsAction.failure, (state, {meta, payload}) =>
    handleFailure(
      state,
      getStoreReviewsId(meta.storeId, meta.filter, meta.pageToken),
      meta,
      payload,
    ),
  );
