/* eslint-disable global-require */
import {loadableReady} from '@loadable/component';
import {addGlobalEventProcessor, captureException} from '@sentry/core';
import config from 'config';
import type {ApiClient} from 'helpers/ApiClient';
import type {RequestEvent} from 'helpers/ApiClient/Analytics/types';
import {polyfillIntl} from 'helpers/intl';
import {loadLocale} from 'helpers/loadLocale';
import {globalLog} from 'helpers/log';
import {pwaInstallPrompt} from 'helpers/pwaInstallPrompt';
import {createPath, History} from 'history';
import React, {StrictMode} from 'react';
import {batch} from 'react-redux';
import {AnyAction} from 'redux';
import {loadRoutesInitialData} from 'routes';
import {InitialDataContext} from 'routes/InitialDataContext';
import type {RootState} from 'store/rootReducer';
import type {DeviceVars} from 'types/deviceVars';
import {RenderingType} from 'types/Rendering';
import {Expandable} from 'types/utility';
import {Store} from 'typesafe-actions';
import {nextTick, nextTickPromise} from 'utils/nextTick';
import {disableNextTickLoadable} from 'utils/nextTickLoadable';
import {trackViewportHeight, updateViewportHeight} from 'utils/trackViewportHeight';

import {reportNonFatalError} from './reportError';

declare const window: Window & {__initTimestamp?: unknown; React?: typeof React};

export type AppOptions = {
  element: HTMLElement;
  client: ApiClient;
  state?: RootState & {preferences?: {devicevars: DeviceVars}};
  eventsQueue?: Array<RequestEvent>;
  history: History;
};

// eslint-disable-next-line no-underscore-dangle
const initTimestamp = Number(window.__initTimestamp) || Date.now();

function sendPerformanceApplication(client: ApiClient) {
  const startDuration = Date.now() - initTimestamp;

  client.performance.traceRecord({
    name: 'performanceApplication',
    startTime: initTimestamp,
    duration: startDuration,
    options: {
      attributes: {
        renderingMode: client.device.getRenderingConfig()?.option || '',
        renderingId: client.device.getRenderingConfig()?.id || '',
      },
    },
  });

  client.analytics.sendEvent({
    type: 'performanceApplication',
    payload: {
      sinceStartMs: startDuration,
    },
  });
}

const DELAYED_ACTIONS_TIMEOUT = 5_000;

/* eslint-disable no-underscore-dangle */
function processDelayedActions(store: Store): Promise<void> {
  return new Promise((resolve) => {
    const complete = () => {
      window.__delayedActions = undefined;
      resolve();
    };

    const dispatchDelayedActions = (actions: unknown[]): void => {
      try {
        batch(() => {
          actions.filter(Boolean).forEach((action) => {
            store.dispatch(action as AnyAction);
          });
        });
      } catch (error) {
        globalLog.getLogger('MainInit').error(error);
      }

      complete();
    };

    if (Array.isArray(window.__delayedActions)) {
      const actions = window.__delayedActions;
      nextTick(() => dispatchDelayedActions(actions));
    } else if (document.readyState === 'complete') {
      complete();
    } else {
      window.__delayedActions = {
        push: (...actions: unknown[]) => dispatchDelayedActions(actions),
      };
      window.addEventListener('DOMContentLoaded', complete);
      setTimeout(complete, DELAYED_ACTIONS_TIMEOUT);
    }
  });
}
/* eslint-enable no-underscore-dangle */

export async function createApp(options: AppOptions): Promise<void> {
  const {client, history} = options;

  const {Providers} = await nextTickPromise(() => require('providers/Providers'));
  const {createStore} = await nextTickPromise(() => require('store/create'));
  const {matchRoute} = await nextTickPromise(() => require('routes'));
  const {Routes} = await nextTickPromise(() => require('routes/Routes'));
  // PageBase not needed here, but it used at almost all pages
  await nextTickPromise(() => require('containers/PageBase'));

  client.performance.collectWebVitals(matchRoute(client.scope, window.location.href).route?.name);

  updateViewportHeight();
  window.addEventListener('DOMContentLoaded', () => trackViewportHeight());

  pwaInstallPrompt(client);

  const {store} = await createStore(client, options.state);

  if (options.eventsQueue) {
    client.analytics.batchServerQueue(options.eventsQueue);
  }

  const ssr = client.device.getRenderingConfig()?.option === RenderingType.USER;

  const perfAppPromises = [
    loadLocale(client.device.getLanguage()),
    polyfillIntl(client.device.getLanguage(), globalLog),
  ] as const;

  Promise.all(perfAppPromises).finally(() => sendPerformanceApplication(client));

  async function loadInitialDataWithRedirects(): ReturnType<typeof loadRoutesInitialData> {
    const initialData = await loadRoutesInitialData({
      store,
      client,
      url: createPath(history.location),
      skipDataLoading: ssr,
      executeDelayed: true,
      history,
    });

    if (initialData.props.redirect) {
      const {link, historyState} = initialData.props.redirect;
      history.replace(link, historyState);

      return loadInitialDataWithRedirects();
    }

    return initialData;
  }

  const [initialData, localeData] = await Promise.all([
    nextTickPromise(() => loadInitialDataWithRedirects()),
    ...perfAppPromises,
  ]);

  addGlobalEventProcessor((evt) => {
    if (evt.exception) {
      reportNonFatalError(client);
    }
    return evt;
  });

  const component = (
    <InitialDataContext.Provider value={initialData}>
      <Providers client={client} store={store} localeData={localeData} history={history}>
        <StrictMode>
          <Routes client={client} />
        </StrictMode>
      </Providers>
    </InitialDataContext.Provider>
  );

  let ReactDOM: typeof import('react-dom/client');

  // React profiling for stage releases
  if (
    process.env.NODE_ENV === 'production' &&
    config.releaseStage !== 'prod' &&
    options.client.device.getDeviceVar('webReactProfiling')
  ) {
    // ReactDOM profiling import must be first
    const {default: ReactDOMProfiling} = await import('react-dom/profiling');
    ReactDOM = await nextTickPromise(() => require('react-dom/client'));
    // we have to patch ReactDOM since other components can use it to create portals
    Object.assign(ReactDOM, ReactDOMProfiling);
  } else {
    ReactDOM = await nextTickPromise(() => require('react-dom/client'));
  }

  if (process.env.NODE_ENV !== 'production') {
    // enable debugger
    window.React = React;

    if (
      !(options.element.firstElementChild?.attributes as Expandable | undefined)?.[
        'data-react-checksum'
      ]
    ) {
      globalLog
        .getLogger()
        .warn(
          'Server-side React render was discarded. Make sure that your initial render does not contain any client-side code.',
        );
    }

    // enable a11y checking
    if (process.env.A11Y_DEV_AXE === 'enabled') {
      // eslint-disable-next-line @typescript-eslint/no-var-requires,import/no-extraneous-dependencies
      const axe = require('@axe-core/react');
      axe(React, ReactDOM, 1000);
    }
  }

  if (ssr) {
    await loadableReady();
    await nextTickPromise(() =>
      ReactDOM.hydrateRoot(options.element, component, {
        onRecoverableError: (error) => captureException(error, {tags: {hydration: true}}),
      }),
    );
    disableNextTickLoadable();

    // load routes inital data again without data loading skip to fulfil store
    const data = await loadRoutesInitialData({
      store,
      client,
      history,
      url: createPath(history.location),
      splitTasks: true,
      executeDelayed: false,
    });

    await processDelayedActions(store);

    await data.props.executeDelayed?.();
  } else {
    ReactDOM.createRoot(options.element).render(component);
  }
}
