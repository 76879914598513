import {defineMessages} from 'react-intl';

export const messages = defineMessages({
  error: {
    description: 'Текст непредвиденной ошибки во время прохождения капчи',
    defaultMessage:
      'An unexpected error occurred, please try again or get in touch with our support team: {email}',
  },
  title: {
    description: '[title] Заголовок страницы ошибки для ввода капчи',
    defaultMessage: 'Please complete a verification check',
  },
  mainText: {
    description: 'Текст страницы ошибки для ввода капчи',
    defaultMessage:
      'We registered suspicious requests coming from your network. This is why we need to make sure that the requests were sent by you, and not by a robot. {link}',
  },
  mainTextLink: {
    description:
      'Ссылка для фразы "Мы зарегистрировали подозрительные запросы, исходящие из вашей сети. Поэтому мы должны убедиться, что запросы отправляете именно вы, а не робот." на странице ошибки для ввода капчи, по нажатию на которую открывается объяснение',
    defaultMessage: 'Why is this happening?',
  },
  accordionText: {
    description: 'Текст страницы ошибки для ввода капчи',
    defaultMessage: `<p> Please verify that you are not a robot in situations when our automated systems record requests from your network that violate our Terms of Use. Once these requests stop, we will stop showing you this page. Until then, you will need to be verified to use the {companyName} website. </p> <p> The source of suspicious requests may be malicious software, browser extensions, or scripts sending them without your knowledge. If you have shared Internet access, the problem may be with another computer from your network. Contact your system administrator. </p> <p> Verification can also occur if you enter multiple frequent queries, or use complex queries used by automated systems. </p> <p> If you can't pass the verification process, please email our support team {email} </p> <p> Your request code is: {requestId} </p>`,
  },
});
