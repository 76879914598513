import {discardableByUser} from 'helpers/discardable';
import {assignSet} from 'utils/object';

const LOAD = 'parcelTracking/LOAD';
const LOAD_SUCCESS = 'parcelTracking/LOAD_SUCCESS';
const LOAD_FAIL = 'parcelTracking/LOAD_FAIL';

const initialState = {
  dict: {},
  loaded: {},
  error: {},
  loading: {},
  meta: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        error: assignSet(state.error, action.id, null),
        loaded: assignSet(state.loaded, action.id, false),
        loading: assignSet(state.loading, action.id, true),
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        dict: assignSet(state.dict, action.id, action.result.parcel),
        loaded: assignSet(state.loaded, action.id, true),
        loading: assignSet(state.loading, action.id, false),
        meta: assignSet(state.meta, action.id, {language: action.result.language}),
      };
    case LOAD_FAIL:
      return {
        ...state,
        error: assignSet(state.error, action.id, action.error),
        loading: assignSet(state.loading, action.id, false),
      };
    default:
      return state;
  }
}

export default discardableByUser(reducer);

export function isParcelTrackingLoading(globalState, id) {
  const {loading} = globalState.parcelTracking;
  return !!loading[id];
}

export function isParcelTrackingLoaded(globalState, id) {
  const {loaded} = globalState.parcelTracking;
  return loaded[id];
}

export function getParcelTracking(globalState, id) {
  return isParcelTrackingLoaded(globalState, id) ? globalState.parcelTracking.dict[id] : null;
}

export function getParcelTrackingError(globalState, id) {
  return globalState.parcelTracking.error[id] || null;
}

export function loadParcelTracking(id) {
  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    id,
    promise: (client) =>
      client.api.get(`/parcels/${id}/tracking`).then(({language, body: {payload}}) => ({
        parcel: payload,
        language,
      })),
  };
}
