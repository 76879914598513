import {createRequestAction} from 'store/utils/requestActions';
import {ProductCollection} from 'types/ProductCollection';

export type LoadProductCollectionRequest = {
  productCollectionId: string;
  pageToken?: string;
};

export type LoadProductCollectionResponse = ProductCollection;

export const loadProductCollection = createRequestAction(
  '@productCollection/LOAD_REQUEST',
  '@productCollection/LOAD_RESPONSE',
  '@productCollection/LOAD_FAILURE',
)<LoadProductCollectionRequest, LoadProductCollectionResponse>();
