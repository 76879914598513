import {requestActionCreator} from 'store/utils/requestActions';
import {RewardWheelSource, RewardWheelState} from 'types/RewardWheel';
import {FuncAction} from 'typesafe-actions';

import {markRewardWheelAsSeenAction, MarkRewardWheelAsSeenResponse} from './actions';
import {activateRewardWheelAction, ActivateRewardWheelResponse} from './activation/actions';
import {
  getRewardWheelAvailabilityAction,
  GetRewardWheelAvailabilityResponse,
} from './availability/actions';
import {getRewardWheelAction, GetRewardWheelResponse} from './wheel/actions';
import {getRewardWheelRequest} from './wheel/selectors';

export const markRewardWheelAsSeen = (
  wheelId: string,
  wheelState: RewardWheelState,
): FuncAction<Promise<MarkRewardWheelAsSeenResponse>> =>
  requestActionCreator(markRewardWheelAsSeenAction, {state: wheelState}, (store, client) =>
    client.api.post(`/rewardWheel/${wheelId}/markAsSeen`, {
      body: {state: wheelState},
    }),
  );

type GetRewardWheelAvailabilityResponseBody = {
  payload: GetRewardWheelAvailabilityResponse;
};

export const getRewardWheelAvailability = (
  pageUrl: string,
): FuncAction<Promise<GetRewardWheelAvailabilityResponse>> =>
  requestActionCreator(getRewardWheelAvailabilityAction, {}, (store, client) =>
    client.api
      .get<GetRewardWheelAvailabilityResponseBody>(`/rewardWheel/availability`, {
        query: {pageUrl},
      })
      .then(({body: {payload}}) => payload),
  );

type ActivateRewardWheelResponseBody = {
  payload: ActivateRewardWheelResponse;
};

export const activateRewardWheel = (
  wheelId: string,
): FuncAction<Promise<ActivateRewardWheelResponse>> =>
  requestActionCreator(activateRewardWheelAction, {}, async (store, client) => {
    const source = getRewardWheelRequest(store.getState())?.source;

    return client.api
      .post<ActivateRewardWheelResponseBody>(`/rewardWheel/${wheelId}/activate`, {
        body: {source},
      })
      .then(({body: {payload}}) => {
        const {webRewardWheelRedesign} = client.device.getDeviceVars();

        if (__CLIENT__ && webRewardWheelRedesign?.enabled) {
          store.dispatch(getRewardWheelAvailability(document.location.pathname));
        }

        return payload;
      });
  });

type GetRewardWheelResponseBody = {
  payload: GetRewardWheelResponse;
};

export const getRewardWheel = (
  source?: RewardWheelSource,
): FuncAction<Promise<GetRewardWheelResponse>> =>
  requestActionCreator(getRewardWheelAction, {source}, (store, client) =>
    client.api
      .post<GetRewardWheelResponseBody>(`/rewardWheel/get`, {
        body: {source},
      })
      .then(({body: {payload}}) => payload),
  );
