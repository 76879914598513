import {createRequestAction} from 'store/utils/requestActions';
import {ProductLite} from 'types/ProductLite';

import {ContextList} from '../search/types';

type LoadSimilarsRequest = {
  productId?: string;
  options?: {pageToken?: string; count?: number};
};

type LoadSimilarsResponse = {
  nextPageToken?: string;
  items: ProductLite[];
  contexts?: ContextList[];
  language: string;
  currency: string;
};

const loadSimilars = createRequestAction(
  '@similars/LOAD_SIMILARS_REQUEST',
  '@similars/LOAD_SIMILARS_SUCCESS',
  '@similars/LOAD_SIMILARS_FAILURE',
)<LoadSimilarsRequest, LoadSimilarsResponse>();

export {loadSimilars, LoadSimilarsRequest, LoadSimilarsResponse};
